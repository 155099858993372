<div class="content-wrapper" id="mainContent">
    <!--Mobile Filter-->
    <ng-container *ngIf="!this.isDisabledFilter">
        <div id="filterFlyIn" class="filterFlyIn-mobile">
            <div class="header">
                <h2>Filter</h2>
                <div class="resetLink" (click)="toggleFilterBar()"><img class="close-icon"
                        src="/assets/icons/close_filter_mobile.svg" /></div>
            </div>
            <div id="filterFlyInWrapper" class="filterFlyIn-wrapper">
                <!--filterFacetDCatalog verisinden gelenlerin doldurdugu alan-->
                <ng-container *ngIf="facetList$ | async as facetList">
                    <div class="filter-inner">
                        <!--ColourShade dropdown -->
                        <ng-container *ngFor="let facets of facetList.attributeGroups">
                            <ng-container *ngIf="facetList.attributeGroups != undefined">
                                <ng-container *ngIf="facetList.attributeGroups[0].colourGroupList[0]">
                                    <section id="facetList-colourShade" class="facetList"
                                        [ngClass]="{ active: 'Colour Shade' == facetListToShow }">
                                        <div class="toggle" (click)="openFacetList('Colour Shade')">
                                            <img class="facetlist-icon active"
                                                src="assets/icons/chevron-right-filters.svg" style="display: none" />
                                            <h3 class="facetList-name">
                                                {{colorShadeTitle}}
                                                <div class="filterCount"
                                                    *ngIf="selectedFacetCountMap && selectedFacetCountMap[facets.attributeCode]">
                                                    {{selectedFacetCountMap[facets.attributeCode]}}
                                                </div>
                                            </h3>
                                            <img class="facetlist-icon" src="assets/icons/chevron-right-filters.svg" />
                                        </div>
                                    </section>
                                </ng-container>
                            </ng-container>

                        </ng-container>
                        <ng-container
                            *ngIf="facetList && facetList.doehlerCatalogConfigData && facetList.doehlerCatalogConfigData.filterFacetDCatalog != null">
                            <ng-container
                                *ngFor="let facets of  facetList.doehlerCatalogConfigData.filterFacetDCatalog">
                                <ng-container *ngIf="facets.values.length > 0">
                                    <ng-container
                                        *ngIf="facets.code !== 'RMW61ZF01' && facets.code !== 'RDW11ZF01' &&  facets.code !== 'quickFilters' && facets.code !== 'targetmarketlist'">
                                        <section id="facetList-facets" class="facetList"
                                            [ngClass]="{ active: facets.name == facetListToShow }">
                                            <div class="toggle" (click)="openFacetList(facets.name)">
                                                <img class="facetlist-icon active"
                                                    src="assets/icons/chevron-right-filters.svg"
                                                    style="display: none" />
                                                <h3 id="{{facets.name}}" class="facetList-name">{{facets.name}}
                                                    <div class="filterCount"
                                                        *ngIf="selectedFacetCountMap && selectedFacetCountMap[facets.code]">
                                                        {{selectedFacetCountMap[facets.code]}}
                                                    </div>
                                                </h3>
                                                <img class="facetlist-icon"
                                                    src="assets/icons/chevron-right-filters.svg" />
                                            </div>
                                        </section>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-container
                            *ngIf="facetList && facetList.doehlerCatalogConfigData && facetList.doehlerCatalogConfigData.specificationsRegulatoryInfoDCatalog != null && facetList.doehlerCatalogConfigData.specificationsRegulatoryInfoDCatalog.length > 0">
                            <ng-container
                                *ngIf="facetList.doehlerCatalogConfigData.specificationsRegulatoryInfoDCatalog.length > 0">
                                <section id="facetList-regulatoryInfo" class="facetList"
                                    [ngClass]="{ active: 'Regulatory Info' == facetListToShow }">
                                    <div class="toggle" (click)="openFacetList('Regulatory Info')">
                                        <img class="facetlist-icon active" src="assets/icons/chevron-right-filters.svg"
                                            style="display: none" />
                                        <h3 class="facetList-name">{{regulatoryInfoTitle}}
                                            <div class="filterCount"
                                                *ngIf="regulatoryInfoSelectedCountMap && regulatoryInfoSelectedCountMap['regulatoryInfoSelectedCount']">
                                                {{regulatoryInfoSelectedCountMap['regulatoryInfoSelectedCount']}}
                                            </div>
                                        </h3>
                                        <img class="facetlist-icon" src="assets/icons/chevron-right-filters.svg" />
                                    </div>
                                </section>
                            </ng-container>
                        </ng-container>
                        <ng-container
                            *ngIf="facetList && facetList.doehlerCatalogConfigData && facetList.doehlerCatalogConfigData.specificationsPhysicalChemicalCharacteristicsDCatalog != null && facetList.doehlerCatalogConfigData.specificationsPhysicalChemicalCharacteristicsDCatalog.length > 0">
                            <ng-container
                                *ngIf="facetList.doehlerCatalogConfigData.specificationsPhysicalChemicalCharacteristicsDCatalog.length > 0">
                                <section id="facetList-physicalChemical" class="facetList"
                                    [ngClass]="{ active: 'Pyhsical & Chemical Characteristics' == facetListToShow }">
                                    <div class="toggle" (click)="openFacetList('Pyhsical & Chemical Characteristics')">
                                        <img class="facetlist-icon active" src="assets/icons/chevron-right-filters.svg"
                                            style="display: none" />
                                        <h3 class="facetList-name">{{physicalChemicalCharacteristicsTitle}}
                                            <div class="filterCount"
                                                *ngIf="physicalChemicalCharSelectedCountMap && physicalChemicalCharSelectedCountMap['physicalChemicalCharSelectedCount']">
                                                {{physicalChemicalCharSelectedCountMap['physicalChemicalCharSelectedCount']}}
                                            </div>
                                        </h3>
                                        <img class="facetlist-icon" src="assets/icons/chevron-right-filters.svg" />
                                    </div>
                                </section>
                            </ng-container>
                        </ng-container>
                        <ng-container
                            *ngIf="facetList && facetList.doehlerCatalogConfigData && facetList.doehlerCatalogConfigData.filterFacetDCatalog != null">
                            <ng-container
                                *ngFor="let facets of  facetList.doehlerCatalogConfigData.filterFacetDCatalog">
                                <ng-container *ngIf="facets.values.length > 0">
                                    <ng-container *ngIf="facets.code === 'targetmarketlist'">
                                        <section id="facetList-facets" class="facetList"
                                            [ngClass]="{ active: facets.name == facetListToShow }">
                                            <div class="toggle" (click)="openFacetList(facets.name)">
                                                <img class="facetlist-icon active"
                                                    src="assets/icons/chevron-right-filters.svg"
                                                    style="display: none" />
                                                <h3 id="{{facets.name}}" class="facetList-name">{{facets.name}}
                                                    <div class="filterCount"
                                                        *ngIf="selectedFacetCountMap && selectedFacetCountMap[facets.code]">
                                                        {{selectedFacetCountMap[facets.code]}}
                                                    </div>
                                                </h3>
                                                <img class="facetlist-icon"
                                                    src="assets/icons/chevron-right-filters.svg" />
                                            </div>
                                        </section>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>

                    <div class="sticky-footer">
                        <ng-container *ngIf="activeFacetList$ | async as activeFacetList">
                            <ng-container *ngIf="activeFacetList.activeFacets.length == 0">
                                <button type="cancel" class="cancelBtn disabled">
                                    {{showResultButton}}
                                </button>
                            </ng-container>
                            <ng-container *ngIf="activeFacetList.activeFacets.length > 0">
                                <div class="reset-button-container">
                                    <img src="/assets/icons/reload.svg">
                                    <a class="footer-resetLink" (click)="resetAllFilter()">{{resetAllButtonMobile}}</a>
                                </div>
                                <div class="button-container">
                                    <button type="cancel" class="cancelBtn" (click)="toggleFilterClose()">
                                        {{showResultButton}}
                                    </button>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>

                </ng-container>
            </div>
            <div id="filterFlyInWrapperActive" class="filterFlyIn-wrapper-active">

                <ng-container *ngIf="facetList$ | async as facetList">
                    <!--ColourShade dropdown -->
                    <ng-container *ngIf="facetList && facetList.attributeGroups != null">
                        <section id="facetList-colourShade" class="facetList hidden"
                            [ngClass]="{ active: 'Colour Shade' == facetListToShow }">
                            <ng-container *ngFor="let facets of facetList.attributeGroups">
                                <div class="filter-inner">
                                    <div class="toggle" (click)="openFacetListActive('Colour Shade')">
                                        <img class="facetlist-icon active" src="assets/icons/chevron-right-filters.svg"
                                            style="display: none" />
                                        <h3 class="facetList-name">
                                            {{colorShadeTitle}}
                                            <div class="filterCount"
                                                *ngIf="selectedFacetCountMap && selectedFacetCountMap[facets.attributeCode]">
                                                {{selectedFacetCountMap[facets.attributeCode]}}
                                            </div>
                                        </h3>
                                        <img class="facetlist-icon" src="assets/icons/chevron-right-filters.svg" />
                                    </div>
                                    <ng-container *ngIf="facetList.attributeGroups != undefined">
                                        <div class="checkbox-container group">
                                            <ng-container *ngFor="let attributeGroup of facetList.attributeGroups">
                                                <ng-container *ngFor="let colorGroup of attributeGroup.colourGroupList">
                                                    <ng-container
                                                        *ngFor="let subColourGroup of colorGroup.subAttributeFacets">
                                                        <label class="sub-color-value value">
                                                            <input type="checkbox"
                                                                id="colourShade-{{subColourGroup.facetValueData.code}}"
                                                                name="colourShade">
                                                            <ng-container
                                                                *ngIf="subColourGroup.attributeValue == 'black' || subColourGroup.attributeValue == 'brown' || subColourGroup.attributeValue == 'orange'
                                                                            || subColourGroup.attributeValue == 'violet' || subColourGroup.attributeValue == 'blue' || subColourGroup.attributeValue == 'green'
                                                                            || subColourGroup.attributeValue == 'red' || subColourGroup.attributeValue == 'red-blue'">
                                                                <span class="checkmark color"
                                                                    [ngStyle]="setBackgroundColour(subColourGroup.attributeValue)"
                                                                    checked="checked" routerLink="./"
                                                                    [queryParams]="getGroupLinkParams(subColourGroup.queryValue)"
                                                                    [class.selected]="subColourGroup.facetValueData.selected"
                                                                    [cxFocus]="subColourGroup.facetValueData.code"
                                                                    (click)="getColourShadeChange(attributeGroup.attributeCode,colorGroup.mainAttributeFacet,colorGroup.mainFacetValueData.selected)"
                                                                    (keydown.space)="openLink($event)"></span>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="subColourGroup.attributeValue == 'yellow' || subColourGroup.attributeValue == 'yellow-orange' || subColourGroup.attributeValue == 'white'
                                                                            || subColourGroup.attributeValue == 'colorless' || subColourGroup.attributeValue == 'pink' || subColourGroup.attributeValue == 'glitter'">
                                                                <span class="checkmark color thick"
                                                                    [ngStyle]="setBackgroundColour(subColourGroup.attributeValue)"
                                                                    checked="checked" routerLink="./"
                                                                    [queryParams]="getGroupLinkParams(subColourGroup.queryValue)"
                                                                    [class.selected]="subColourGroup.facetValueData.selected"
                                                                    [cxFocus]="subColourGroup.facetValueData.code"
                                                                    (click)="getColourShadeChange(attributeGroup.attributeCode,colorGroup.mainAttributeFacet,colorGroup.mainFacetValueData.selected)"
                                                                    (keydown.space)="openLink($event)"></span>
                                                            </ng-container>
                                                            <span class="attribute-info" routerLink="./"
                                                                [queryParams]="getGroupLinkParams(subColourGroup.queryValue)"
                                                                [class.selected]="subColourGroup.facetValueData.selected"
                                                                [cxFocus]="subColourGroup.facetValueData.code"
                                                                (click)="getColourShadeChange(attributeGroup.attributeCode,colorGroup.mainAttributeFacet,colorGroup.mainFacetValueData.selected)"
                                                                (keydown.space)="openLink($event)">
                                                                <span class="label">{{
                                                                    subColourGroup.facetValueData.name }}</span>
                                                                <span class="count"> ({{
                                                                    subColourGroup.facetValueData.count }})</span>
                                                            </span>
                                                        </label>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </div>
                                <ng-container *ngIf="facetListToShow == 'Colour Shade'">
                                    <div class="sticky-footer">
                                        <ng-container *ngIf="activeFacetList$ | async as activeFacetList">
                                            <ng-container *ngIf="activeFacetList.activeFacets.length == 0">
                                                <button type="cancel" class="cancelBtn disabled">
                                                    {{showResultButton}}
                                                </button>
                                            </ng-container>
                                            <ng-container *ngIf="activeFacetList.activeFacets.length > 0">
                                                <div class="reset-button-container">
                                                    <img src="/assets/icons/reload.svg">
                                                    <a class="footer-resetLink"
                                                        (click)="resetCustomFacetFilter(facets.attributeCode)">{{resetButton}}</a>
                                                </div>
                                                <div class="button-container">
                                                    <button type="cancel" class="cancelBtn"
                                                        (click)="toggleFilterClose()">
                                                        {{showResultButton}}
                                                    </button>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </section>
                    </ng-container>
                    <!--filterFacetDCatalog-->
                    <ng-container
                        *ngIf="facetList && facetList.doehlerCatalogConfigData && facetList.doehlerCatalogConfigData.filterFacetDCatalog != null">
                        <ng-container *ngIf="facetList.doehlerCatalogConfigData.filterFacetDCatalog.length > 0">
                            <ng-container
                                *ngFor="let facets of  facetList.doehlerCatalogConfigData.filterFacetDCatalog">
                                <ng-container *ngIf="facets.code !== 'quickFilters'">
                                    <section id="facetList-{{facets.code}}" class="facetList hidden"
                                        [ngClass]="{ active: facets.name == facetListToShow }">
                                        <div class="filter-inner">
                                            <div class="toggle" (click)="openFacetListActive(facets.name)">
                                                <img class="facetlist-icon active"
                                                    src="assets/icons/chevron-right-filters.svg"
                                                    style="display: none" />
                                                <h3 id="{{facets.name}}" class="facetList-name"
                                                    *ngIf="facets.code !== 'applicationFitCategoryTree'">
                                                    {{facets.name}}
                                                    <div class="filterCount"
                                                        *ngIf="selectedFacetCountMap && selectedFacetCountMap[facets.code]">
                                                        {{selectedFacetCountMap[facets.code]}}
                                                    </div>
                                                </h3>
                                                <img class="facetlist-icon"
                                                    src="assets/icons/chevron-right-filters.svg" />
                                            </div>
                                            <ng-container
                                                *ngIf="facets.fancyFacetType !=undefined || facets.fancyFacetType !='NOTFANCY'">
                                                <ng-container *ngIf="facets.fancyFacetType =='RADIO'"><!--radio-->
                                                    <div class="radioButtonContainer">
                                                        <ng-container
                                                            *ngFor="let facetValues of  facets.values ;index as i">
                                                            <div *ngIf="i<5 || moreLessIndicator == facets.code+'close'"
                                                                class="row">
                                                                <label id="label-{{facets.code}}"
                                                                    class="value radio-container">
                                                                    <input type="radio"
                                                                        id="colourShade-{{facetValues.name}}"
                                                                        name="colourShade" [value]="facetValues.name">
                                                                    <span class="checkmark" checked="checked"
                                                                        style="background-color:#01203d;"
                                                                        routerLink="./"
                                                                        [queryParams]="getLinkParams(facetValues)"
                                                                        [class.selected]="facetValues.selected"
                                                                        [cxFocus]="facetValues.name"
                                                                        (click)="getTabChange(facets.code,facetValues.name,facetValues.selected)"
                                                                        (keydown.space)="openLink($event)"></span>
                                                                    <span class="attribute-info" routerLink="./"
                                                                        [queryParams]="getLinkParams(facetValues)"
                                                                        [class.selected]="facetValues.selected"
                                                                        [cxFocus]="facetValues.name"
                                                                        (click)="getTabChange(facets.code,facetValues.name,facetValues.selected)"
                                                                        (keydown.space)="openLink($event)">
                                                                        <span class="label ">{{ facetValues.name
                                                                            }}</span>
                                                                        <span class="count "> ({{ facetValues.count
                                                                            }})</span>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </ng-container>
                                                        <a href="javascript:void(0)" class="more-button"
                                                            id="button-{{facets.code}}"
                                                            (click)="clickMoreLess(facets.code)">
                                                            {{this.moreLessIndicator.includes(facets.code+"close") ?
                                                            "Show Less" : "Show More" }}
                                                        </a>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="facets.fancyFacetType =='SLIDER'"><!-- slider -->
                                                    <ng-container
                                                        *ngFor="let facetValues of  facets.values; let i = index">
                                                        <ng-container *ngIf="i == 0">
                                                            <div class="sliderContainer">
                                                                <span class="physicalChemical-subtitle">{{facets.name}}
                                                                </span>
                                                                <div class="filter-slider">
                                                                    <div class="filter-slider-content">
                                                                        <div class="slider-content">
                                                                            <h4 class="input-text">min {{facets.name}}
                                                                            </h4>
                                                                            <div class="min-value">
                                                                                <input id="sliderInput-{{facets.code}}"
                                                                                    class="slider-input" type="number"
                                                                                    min="{{decimalNumber(facets.minSliderValue)}}"
                                                                                    max="{{decimalNumber(facets.maxSliderValue)}}"
                                                                                    (focusout)="enterMinValues(facetList.currentQuery.query.value,$event,facets.minSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)"
                                                                                    (keydown.enter)="enterMinValues(facetList.currentQuery.query.value,$event,facets.minSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)"
                                                                                    placeholder="{{decimalNumber(facets.minSelectSliderValue)}}" />
                                                                            </div>
                                                                        </div>
                                                                        <div class="slider-content">
                                                                            <h4 class="input-text">max {{facets.name}}
                                                                            </h4>
                                                                            <div class="max-value">
                                                                                <input id="sliderInput-{{facets.code}}"
                                                                                    class="slider-input" type="number"
                                                                                    min="{{decimalNumber(facets.minSliderValue)}}"
                                                                                    max="{{decimalNumber(facets.maxSliderValue)}}"
                                                                                    placeholder="{{decimalNumber(facets.maxSelectSliderValue)}}"
                                                                                    (focusout)="enterMaxValues(facetList.currentQuery.query.value,$event,facets.maxSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)"
                                                                                    (keydown.enter)="enterMinValues(facetList.currentQuery.query.value,$event,facets.minSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <span id="minWarning-{{facets.minSliderValue}}"
                                                                        class="min-warning">The minimum value is
                                                                        {{facets.minSliderValue}}. We have automatically
                                                                        reset your input to the minimum value.</span>
                                                                    <span id="maxWarning-{{facets.maxSliderValue}}"
                                                                        class="max-warning">The maximum value is
                                                                        {{facets.maxSliderValue}}. We have automatically
                                                                        reset your input to the maximum value.</span>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="facets.name == facetListToShow">
                                                <ng-container *ngIf="facets.showSearchField == true">
                                                    <ng-container
                                                        *ngIf="facets.fancyFacetType ==undefined || facets.fancyFacetType =='NOTFANCY'"><!-- checkbox -->
                                                        <div class="searchable-property-group">
                                                            <input id="searchLabelMobile-{{facets.code}}"
                                                                (clickOutside)="onChangeMobileBackgroundOutside(facets.code)"
                                                                (click)="changeBackground(facets.code)"
                                                                class="field-container group facet-filters form-group  search-label"
                                                                type="text"
                                                                [attr.aria-label]="'category.searchFacetFilter' | cxTranslate"
                                                                (keyup)="onSearchPropertyEventMobile($event,facets.code)"
                                                                placeholder="{{ 'category.searchFacetFilter' | cxTranslate: { facet: facets.name } }}" />
                                                            <ng-container *ngIf="facetList.breadcrumbs.length > 0">
                                                                <div class="applied-filter-row">
                                                                    <ng-container
                                                                        *ngFor="let breadcrumb of facetList.breadcrumbs">
                                                                        <ng-container
                                                                            *ngIf="breadcrumb.facetName === facetListToShow">
                                                                            <ng-container
                                                                                *ngIf="activeFacetList$ | async as activeFacetList">
                                                                                <div class="appliedFilter">
                                                                                    <label class="applied-filter-button"
                                                                                        routerLink="./"
                                                                                        [queryParams]="getLinkParamsActiveFacet(breadcrumb)"
                                                                                        [cxFocus]="getFocusKey(activeFacetList, breadcrumb)">
                                                                                        <div class="appliedFilterItem">
                                                                                            <span>
                                                                                                <ng-container
                                                                                                    *ngIf="breadcrumb.facetValueName.indexOf(' TO ') > -1">
                                                                                                    {{breadcrumb.facetValueName.replace('TO',
                                                                                                    '&mdash;')}}
                                                                                                </ng-container>
                                                                                                <ng-container
                                                                                                    *ngIf="breadcrumb.facetValueName.indexOf(' TO ') == -1">
                                                                                                    {{breadcrumb.facetValueName}}
                                                                                                </ng-container>
                                                                                            </span>
                                                                                            <div class="resetLink">
                                                                                            </div>
                                                                                        </div>
                                                                                    </label>
                                                                                </div>
                                                                            </ng-container>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </div>
                                                            </ng-container>
                                                            <div class="checkbox-container group facet-list-long"
                                                                id="checkBoxProperty-{{facets.code}}">
                                                                <ng-container *ngFor="let facetValues of facets.values">
                                                                    <label class="facetValue hidden"
                                                                        id="label-{{facets.code}}">
                                                                        <input type="checkbox" id="{{facetValues.name}}"
                                                                            class="d-none">
                                                                        <span
                                                                            class="checkmark filters facetValue hidden"
                                                                            routerLink="./"
                                                                            [queryParams]="getLinkParams(facetValues)"
                                                                            [class.selected]="facetValues.selected"
                                                                            [cxFocus]="facetValues.name"
                                                                            (click)="getTabChange(facets.code,facetValues.name,facetValues.selected)"
                                                                            (keydown.space)="openLink($event)"></span>
                                                                        <span class="attribute-info facetValue hidden"
                                                                            routerLink="./"
                                                                            [queryParams]="getLinkParams(facetValues)"
                                                                            [class.selected]="facetValues.selected"
                                                                            [cxFocus]="facetValues.name"
                                                                            (click)="getTabChange(facets.code,facetValues.name,facetValues.selected)"
                                                                            (keydown.space)="openLink($event)">
                                                                            <span class="facetValueCheckmark">{{
                                                                                facetValues.name }}</span>
                                                                            <span class="count "> ({{ facetValues.count
                                                                                }})</span>
                                                                        </span>
                                                                    </label>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngFor="let paginatedFacetValue of paginatedFacetValues(facets)">
                                                                    <label class="value" id="label-{{facets.code}}">
                                                                        <input type="checkbox"
                                                                            id="{{paginatedFacetValue.name}}"
                                                                            class="d-none">
                                                                        <span class="checkmark filters paginationValue"
                                                                            routerLink="./"
                                                                            [queryParams]="getLinkParams(paginatedFacetValue)"
                                                                            [class.selected]="paginatedFacetValue.selected"
                                                                            [cxFocus]="paginatedFacetValue.name"
                                                                            (click)="getTabChange(facets.code,paginatedFacetValue.name,paginatedFacetValue.selected)"
                                                                            (keydown.space)="openLink($event)"></span>
                                                                        <span class="attribute-info paginationValue"
                                                                            routerLink="./"
                                                                            [queryParams]="getLinkParams(paginatedFacetValue)"
                                                                            [class.selected]="paginatedFacetValue.selected"
                                                                            [cxFocus]="paginatedFacetValue.name"
                                                                            (click)="getTabChange(facets.code,paginatedFacetValue.name,paginatedFacetValue.selected)"
                                                                            (keydown.space)="openLink($event)">
                                                                            <span class="paginationValueCheckmark">{{
                                                                                paginatedFacetValue.name }}</span>
                                                                            <span class="count "> ({{
                                                                                paginatedFacetValue.count }})</span>
                                                                        </span>
                                                                    </label>
                                                                </ng-container>
                                                                <!-- Add pagination buttons for each facet's values -->

                                                                <ng-container id="sortItems" *ngIf="facetList$ | async as facetList">
                                                                    <div class="slp-pagination col-auto d-flex justify-content-center py-3"
                                                                         aria-label="product search pagination">
                                                                        <ng-container *ngIf="facetList.pagination.totalPages >= 2">
                                                                            <div class="cx-pagination">
                                                                                <cx-pagination id="paginationScroll2" class="slp-cx-pagination"
                                                                                               [pagination]="facetList.pagination" queryParam="currentPage" [defaultPage]="0"
                                                                                               (click)="onScroll()"></cx-pagination>
                                                                            </div>
                                                                        </ng-container>
                                                                    </div>
                                                                </ng-container>

                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-container *ngIf="facets.showSearchField != true">
                                                    <ng-container
                                                        *ngIf="facets.fancyFacetType ==undefined || facets.fancyFacetType =='NOTFANCY'"><!--checkbox -->
                                                        <div id="checkboxGroup-{{facets.code}}"
                                                            class="checkbox-container group">
                                                            <ng-container *ngFor="let facetValues of facets.values">
                                                                <label class="value" id="label-{{facets.code}}">
                                                                    <input type="checkbox" id="{{facetValues.name}}">
                                                                    <span class="checkmark filters" checked="checked"
                                                                        routerLink="./"
                                                                        [queryParams]="getLinkParams(facetValues)"
                                                                        [class.selected]="facetValues.selected"
                                                                        [cxFocus]="facetValues.name"
                                                                        (click)="getTabChange(facets.code,facetValues.name,facetValues.selected)"
                                                                        (keydown.space)="openLink($event)"></span>
                                                                    <span class="attribute-info" routerLink="./"
                                                                        [queryParams]="getLinkParams(facetValues)"
                                                                        [class.selected]="facetValues.selected"
                                                                        [cxFocus]="facetValues.name"
                                                                        (click)="getTabChange(facets.code,facetValues.name,facetValues.selected)"
                                                                        (keydown.space)="openLink($event)">
                                                                        <span class="label ">{{ facetValues.name
                                                                            }}</span>
                                                                        <span class="count "> ({{ facetValues.count
                                                                            }})</span>
                                                                    </span>
                                                                </label>
                                                            </ng-container>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>

                                        </div>
                                        <ng-container *ngIf="facetListToShow === facets.name">
                                            <div class="sticky-footer">
                                                <ng-container *ngIf="activeFacetList$ | async as activeFacetList">
                                                    <ng-container *ngIf="activeFacetList.activeFacets.length == 0">
                                                        <button type="cancel" class="cancelBtn disabled">
                                                            {{showResultButton}}
                                                        </button>
                                                    </ng-container>
                                                    <ng-container *ngIf="activeFacetList.activeFacets.length > 0">
                                                        <div class="reset-button-container">
                                                            <img src="/assets/icons/reload.svg">
                                                            <a class="footer-resetLink"
                                                                (click)="resetCustomFacetFilter(facets.code)">{{resetButton}}</a>
                                                        </div>
                                                        <div class="button-container">
                                                            <button type="cancel" class="cancelBtn"
                                                                (click)="toggleFilterClose()">
                                                                {{showResultButton}}
                                                            </button>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                    </section>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <!--specificationsRegulatoryInfoDCatalog-->
                    <ng-container
                        *ngIf="facetList && facetList.doehlerCatalogConfigData && facetList.doehlerCatalogConfigData.specificationsRegulatoryInfoDCatalog != null && facetList.doehlerCatalogConfigData.specificationsRegulatoryInfoDCatalog.length > 0">
                        <ng-container
                            *ngIf="facetList.doehlerCatalogConfigData.specificationsRegulatoryInfoDCatalog.length > 0">
                            <section class="facetList-regulatoryInfo" class="facetList hidden"
                                [ngClass]="{ active: 'Regulatory Info' == facetListToShow }">
                                <div class="filter-inner">
                                    <div class="toggle" (click)="openFacetListActive('Regulatory Info')">
                                        <img class="facetlist-icon active" src="assets/icons/chevron-right-filters.svg"
                                            style="display: none" />
                                        <h3 class="facetList-name">
                                            {{regulatoryInfoTitle}}
                                            <div class="filterCount"
                                                *ngIf="regulatoryInfoSelectedCountMap && regulatoryInfoSelectedCountMap['regulatoryInfoSelectedCount']">
                                                {{regulatoryInfoSelectedCountMap['regulatoryInfoSelectedCount']}}
                                            </div>
                                        </h3>
                                        <img class="facetlist-icon" src="assets/icons/chevron-right-filters.svg" />
                                    </div>
                                    <ng-container *ngIf="facetList.breadcrumbs.length > 0">
                                        <div class="applied-filter-row">
                                            <ng-container *ngFor="let breadcrumb of facetList.breadcrumbs">
                                                <ng-container *ngIf="facetList$ | async as facetList">
                                                    <ng-container
                                                        *ngFor="let facets of facetList.doehlerCatalogConfigData.specificationsRegulatoryInfoDCatalog">
                                                        <ng-container *ngIf="facets.code === breadcrumb.facetCode">
                                                            <ng-container
                                                                *ngIf="activeFacetList$ | async as activeFacetList">
                                                                <div class="appliedFilter">
                                                                    <label class="applied-filter-button" routerLink="./"
                                                                        [queryParams]="getLinkParamsActiveFacet(breadcrumb)"
                                                                        [cxFocus]="getFocusKey(activeFacetList, breadcrumb)">
                                                                        <div class="appliedFilterItem">
                                                                            <span>
                                                                                <ng-container
                                                                                    *ngIf="breadcrumb.facetValueName.indexOf(' TO ') > -1">
                                                                                    {{breadcrumb.facetValueName.replace('TO',
                                                                                    '&mdash;')}}
                                                                                </ng-container>
                                                                                <ng-container
                                                                                    *ngIf="breadcrumb.facetValueName.indexOf(' TO ') == -1">
                                                                                    {{breadcrumb.facetValueName}}
                                                                                </ng-container>
                                                                            </span>
                                                                            <div class="resetLink"></div>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="facetListToShow === 'Regulatory Info'">
                                        <div class="regulatory-filter"
                                            [ngClass]="{facetHeight: facetList.breadcrumbs.length > 0}">
                                            <ng-container
                                                *ngFor="let facets of  facetList.doehlerCatalogConfigData.specificationsRegulatoryInfoDCatalog; index as i ">
                                                <ng-container *ngIf="facets.values.length > 0">
                                                    <ng-container
                                                        *ngIf="facets.fancyFacetType !=undefined || facets.fancyFacetType !='NOTFANCY'">
                                                        <span class="regulatory-subtitle"
                                                            *ngIf="facets.code !== 'applicationFitCategoryTree'">{{facets.name}}
                                                            <div class="filterCount"
                                                                *ngIf="selectedFacetCountMap && selectedFacetCountMap[facets.code]">
                                                                {{selectedFacetCountMap[facets.code]}}
                                                            </div>
                                                        </span>
                                                        <ng-container
                                                            *ngIf="facets.fancyFacetType =='RADIO'"><!--radio-->
                                                            <ng-container
                                                                *ngFor="let facetValues of  facets.values| slice: 0:4">
                                                                <div class="radioButtonContainer regulatory-info-group">
                                                                    <label class="value radio-container"
                                                                        id="label-{{facets.code}}">
                                                                        <input type="radio"
                                                                            id="colourShade-{{facetValues.name}}"
                                                                            name="colourShade"
                                                                            [value]="facetValues.name">
                                                                        <span class="checkmark" checked="checked"
                                                                            style="background-color:#01203d;"
                                                                            routerLink="./"
                                                                            [queryParams]="getLinkParams(facetValues)"
                                                                            [class.selected]="facetValues.selected"
                                                                            [cxFocus]="facetValues.name"
                                                                            (click)="getTabChange(facets.code,facetValues.name,facetValues.selected)"
                                                                            (keydown.space)="openLink($event)"></span>
                                                                        <span class="attribute-info" routerLink="./"
                                                                            [queryParams]="getLinkParams(facetValues)"
                                                                            [class.selected]="facetValues.selected"
                                                                            [cxFocus]="facetValues.name"
                                                                            (click)="getTabChange(facets.code,facetValues.name,facetValues.selected)"
                                                                            (keydown.space)="openLink($event)">
                                                                            <span class="label ">{{ facetValues.name
                                                                                }}</span>
                                                                            <span class="count "> ({{ facetValues.count
                                                                                }})</span>
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="facets.fancyFacetType =='SLIDER'"><!-- slider -->
                                                            <ng-container
                                                                *ngFor="let facetValues of facets.values; let i = index">
                                                                <ng-container *ngIf="i == 0">
                                                                    <div class="sliderContainer">
                                                                        <div class="filter-slider">
                                                                            <div class="filter-slider-content">
                                                                                <div class="slider-content">
                                                                                    <h4 class="input-text">min
                                                                                        {{facets.name}}</h4>
                                                                                    <div class="min-value">
                                                                                        <input
                                                                                            id="physicalSliderInput-{{facets.code}}"
                                                                                            class="slider-input"
                                                                                            type="number"
                                                                                            min="{{decimalNumber(facets.minSliderValue)}}"
                                                                                            max="{{decimalNumber(facets.maxSliderValue)}}"
                                                                                            (focusout)="enterMinValues(facetList.currentQuery.query.value,$event,facets.minSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)"
                                                                                            (keydown.enter)="enterMinValues(facetList.currentQuery.query.value,$event,facets.minSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)"
                                                                                            placeholder="{{decimalNumber(facets.minSelectSliderValue)}}" />
                                                                                    </div>
                                                                                </div>
                                                                                <div class="slider-content">
                                                                                    <h4 class="input-text">max
                                                                                        {{facets.name}}</h4>
                                                                                    <div class="max-value">
                                                                                        <input
                                                                                            id="physicalSliderInput-{{facets.code}}"
                                                                                            class="slider-input"
                                                                                            type="number"
                                                                                            min="{{decimalNumber(facets.minSliderValue)}}"
                                                                                            max="{{decimalNumber(facets.maxSliderValue)}}"
                                                                                            placeholder="{{decimalNumber(facets.maxSelectSliderValue)}}"
                                                                                            (focusout)="enterMaxValues(facetList.currentQuery.query.value,$event,facets.maxSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)"
                                                                                            (keydown.enter)="enterMinValues(facetList.currentQuery.query.value,$event,facets.minSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <span
                                                                                id="minWarning-{{facets.minSliderValue}}"
                                                                                class="min-warning">The minimum value is
                                                                                {{facets.minSliderValue}}. We have
                                                                                automatically reset your input to the
                                                                                minimum value.</span>
                                                                            <span
                                                                                id="maxWarning-{{facets.maxSliderValue}}"
                                                                                class="max-warning">The maximum value is
                                                                                {{facets.maxSliderValue}}. We have
                                                                                automatically reset your input to the
                                                                                maximum value.</span>
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="facets.fancyFacetType ==undefined || facets.fancyFacetType =='NOTFANCY'"><!--checkbox-->
                                                        <div class="checkbox-container group regulatory-info-group">
                                                            <ng-container *ngFor="let facetValues of facets.values">
                                                                <label class="value" id="label-{{facets.code}}">
                                                                    <input type="checkbox" id="{{facetValues.name}}">
                                                                    <span class="checkmark filters" checked="checked"
                                                                        routerLink="./"
                                                                        [queryParams]="getLinkParams(facetValues)"
                                                                        [class.selected]="facetValues.selected"
                                                                        [cxFocus]="facetValues.name"
                                                                        (click)="getTabChange(facets.code,facetValues.name,facetValues.selected)"
                                                                        (keydown.space)="openLink($event)"></span>
                                                                    <span class="attribute-info" routerLink="./"
                                                                        [queryParams]="getLinkParams(facetValues)"
                                                                        [class.selected]="facetValues.selected"
                                                                        [cxFocus]="facetValues.name"
                                                                        (click)="getTabChange(facets.code,facetValues.name,facetValues.selected)"
                                                                        (keydown.space)="openLink($event)">
                                                                        <span class="label">{{facetValues.name}}</span>
                                                                        <span class="count"> ({{ facetValues.count
                                                                            }})</span>
                                                                    </span>
                                                                </label>
                                                            </ng-container>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </ng-container>

                                </div>
                                <ng-container *ngIf="facetListToShow === 'Regulatory Info'">
                                    <div class="sticky-footer">
                                        <ng-container *ngIf="activeFacetList$ | async as activeFacetList">
                                            <ng-container *ngIf="activeFacetList.activeFacets.length == 0">
                                                <button type="cancel" class="cancelBtn disabled">
                                                    {{showResultButton}}
                                                </button>
                                            </ng-container>
                                            <ng-container *ngIf="activeFacetList.activeFacets.length > 0">
                                                <div class="reset-button-container">
                                                    <img src="/assets/icons/reload.svg">
                                                    <a class="footer-resetLink"
                                                        (click)="resetCustomFacetFilter('regulatoryInfo')">{{resetButton}}</a>
                                                </div>
                                                <div class="button-container">
                                                    <button type="cancel" class="cancelBtn"
                                                        (click)="toggleFilterClose()">
                                                        {{showResultButton}}
                                                    </button>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </section>
                        </ng-container>
                    </ng-container>
                    <!--specificationsPhysicalChemicalCharacteristicsDCatalog-->
                    <ng-container
                        *ngIf="facetList && facetList.doehlerCatalogConfigData && facetList.doehlerCatalogConfigData.specificationsPhysicalChemicalCharacteristicsDCatalog != null && 
                        facetList.doehlerCatalogConfigData.specificationsPhysicalChemicalCharacteristicsDCatalog.length > 0">
                        <ng-container
                            *ngIf="facetList.doehlerCatalogConfigData.specificationsPhysicalChemicalCharacteristicsDCatalog.length > 0">
                            <section class="facetList-physicalChemical" class="facetList hidden"
                                [ngClass]="{ active: 'Pyhsical & Chemical Characteristics' == facetListToShow }">
                                <div class="filter-inner">
                                    <div class="toggle"
                                        (click)="openFacetListActive('Pyhsical & Chemical Characteristics')">
                                        <img class="facetlist-icon active" src="assets/icons/chevron-right-filters.svg"
                                            style="display: none" />
                                        <h3 class="facetList-name">
                                            {{physicalChemicalCharacteristicsTitle}}
                                            <div class="filterCount"
                                                *ngIf="physicalChemicalCharSelectedCountMap && physicalChemicalCharSelectedCountMap['physicalChemicalCharSelectedCount']">
                                                {{physicalChemicalCharSelectedCountMap['physicalChemicalCharSelectedCount']}}
                                            </div>
                                        </h3>
                                        <img class="facetlist-icon" src="assets/icons/chevron-right-filters.svg" />
                                    </div>
                                    <ng-container *ngIf="facetList.breadcrumbs.length > 0">
                                        <div class="applied-filter-row">
                                            <ng-container *ngFor="let breadcrumb of facetList.breadcrumbs">
                                                <ng-container *ngIf="facetList$ | async as facetList">
                                                    <ng-container
                                                        *ngFor="let facets of facetList.doehlerCatalogConfigData.specificationsPhysicalChemicalCharacteristicsDCatalog">
                                                        <ng-container *ngIf="facets.code === breadcrumb.facetCode">
                                                            <ng-container
                                                                *ngIf="activeFacetList$ | async as activeFacetList">
                                                                <div class="appliedFilter">
                                                                    <label class="applied-filter-button" routerLink="./"
                                                                        [queryParams]="getLinkParamsActiveFacet(breadcrumb)"
                                                                        [cxFocus]="getFocusKey(activeFacetList, breadcrumb)">
                                                                        <div class="appliedFilterItem">
                                                                            <span>
                                                                                <ng-container
                                                                                    *ngIf="breadcrumb.facetValueName.indexOf(' TO ') > -1">
                                                                                    {{breadcrumb.facetValueName.replace('TO',
                                                                                    '&mdash;')}}
                                                                                </ng-container>
                                                                                <ng-container
                                                                                    *ngIf="breadcrumb.facetValueName.indexOf(' TO ') == -1">
                                                                                    {{breadcrumb.facetValueName}}
                                                                                </ng-container>
                                                                            </span>
                                                                            <div class="resetLink"></div>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="facetListToShow === 'Pyhsical & Chemical Characteristics'">
                                        <div class="characteristics-filter"
                                            [ngClass]="{facetHeight: facetList.breadcrumbs.length > 0}">
                                            <ng-container
                                                *ngFor="let facets of  facetList.doehlerCatalogConfigData.specificationsPhysicalChemicalCharacteristicsDCatalog">
                                                <ng-container *ngIf="facets.values.length > 0">
                                                    <ng-container
                                                        *ngIf="facets.fancyFacetType !=undefined || facets.fancyFacetType !='NOTFANCY'">
                                                        <span class="charactericstics-subtitle"
                                                            *ngIf="facets.code !== 'applicationFitCategoryTree'">
                                                            {{facets.name}}
                                                            <div class="filterCount"
                                                                *ngIf="selectedFacetCountMap && selectedFacetCountMap[facets.code]">
                                                                {{selectedFacetCountMap[facets.code]}}
                                                            </div>
                                                        </span>
                                                        <ng-container
                                                            *ngIf="facets.fancyFacetType =='RADIO'"><!--radio-->
                                                            <div class="radioButtonContainer physical-chemical-group">
                                                                <ng-container
                                                                    *ngFor="let facetValues of  facets.values;index as i">
                                                                    <label class="value radio-container"
                                                                        id="label-{{facets.code}}">
                                                                        <input type="radio"
                                                                            id="colourShade-{{facetValues.name}}"
                                                                            name="colourShade"
                                                                            [value]="facetValues.name">
                                                                        <span class="checkmark" checked="checked"
                                                                            style="background-color:#01203d;"
                                                                            routerLink="./"
                                                                            [queryParams]="getLinkParams(facetValues)"
                                                                            [class.selected]="facetValues.selected"
                                                                            [cxFocus]="facetValues.name"
                                                                            (click)="getTabChange(facets.code,facetValues.name,facetValues.selected)"
                                                                            (keydown.space)="openLink($event)"></span>
                                                                        <span class="attribute-info" routerLink="./"
                                                                            [queryParams]="getLinkParams(facetValues)"
                                                                            [class.selected]="facetValues.selected"
                                                                            [cxFocus]="facetValues.name"
                                                                            (click)="getTabChange(facets.code,facetValues.name,facetValues.selected)"
                                                                            (keydown.space)="openLink($event)">
                                                                            <span class="label ">{{ facetValues.name
                                                                                }}</span>
                                                                            <span class="count "> ({{ facetValues.count
                                                                                }})</span>
                                                                        </span>
                                                                    </label>
                                                                </ng-container>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="facets.fancyFacetType =='SLIDER'"><!-- slider -->
                                                            <ng-container
                                                                *ngFor="let facetValues of  facets.values; let i = index">
                                                                <ng-container *ngIf="i == 0">
                                                                    <div class="sliderContainer">
                                                                        <div class="filter-slider">
                                                                            <div class="filter-slider-content">
                                                                                <div class="slider-content">
                                                                                    <h4 class="input-text">min
                                                                                        {{facets.name}}</h4>
                                                                                    <div class="min-value">
                                                                                        <input
                                                                                            id="physicalSliderInput-{{facets.code}}"
                                                                                            class="slider-input"
                                                                                            type="number"
                                                                                            (focusout)="enterMinValues(facetList.currentQuery.query.value,$event,facets.minSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)"
                                                                                            (keydown.enter)="enterMinValues(facetList.currentQuery.query.value,$event,facets.minSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)"
                                                                                            placeholder="{{decimalNumber(facets.minSelectSliderValue)}}" />
                                                                                    </div>
                                                                                </div>
                                                                                <div class="slider-content">
                                                                                    <h4 class="input-text">max
                                                                                        {{facets.name}}</h4>
                                                                                    <div class="max-value">
                                                                                        <input
                                                                                            id="physicalSliderInput-{{facets.code}}"
                                                                                            class="slider-input"
                                                                                            type="number"
                                                                                            placeholder="{{decimalNumber(facets.maxSelectSliderValue)}}"
                                                                                            (focusout)="enterMaxValues(facetList.currentQuery.query.value,$event,facets.maxSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)"
                                                                                            (keydown.enter)="enterMinValues(facetList.currentQuery.query.value,$event,facets.minSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <span
                                                                                id="minWarning-{{facets.minSliderValue}}"
                                                                                class="min-warning">The minimum value is
                                                                                {{facets.minSliderValue}}. We have
                                                                                automatically reset your input to the
                                                                                minimum value.</span>
                                                                            <span
                                                                                id="maxWarning-{{facets.maxSliderValue}}"
                                                                                class="max-warning">The maximum value is
                                                                                {{facets.maxSliderValue}}. We have
                                                                                automatically reset your input to the
                                                                                maximum value.</span>
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="facets.fancyFacetType ==undefined || facets.fancyFacetType =='NOTFANCY'">
                                                        <ng-container
                                                            *ngIf="facets.name !== 'applicationFitCategoryTree'">
                                                            <ng-container *ngIf="facets.showSearchField == true">
                                                                <ng-container
                                                                    *ngIf="facets.fancyFacetType ==undefined || facets.fancyFacetType =='NOTFANCY'"><!--checkbox-->
                                                                    <div class="searchable-property-group">
                                                                        <input id="searchLabelMobile-{{facets.code}}"
                                                                            (clickOutside)="onChangeMobileBackgroundOutside(facets.code)"
                                                                            (click)="changeBackground(facets.code)"
                                                                            class="field-container group facet-filters form-group search-label"
                                                                            [attr.aria-label]="'category.searchFacetFilter' | cxTranslate"
                                                                            type="text"
                                                                            (keyup)=onSearchPropertyPhysicalChemicalMobileEvent($event,facets.code)
                                                                            placeholder="{{ 'category.searchFacetFilter' | cxTranslate: { facet: facets.name } }}" />

                                                                        <div class="checkbox-container group facet-list-long physical-chemical-group"
                                                                            id="checkBoxProperty-{{facets.code}}">
                                                                            <ng-container
                                                                                *ngFor="let facetValues of  facets.values;index as i">
                                                                                <ng-container
                                                                                    *ngIf="i<5 || moreLessIndicator == facets.code+'close'">
                                                                                    <label class="value"
                                                                                        id="label-{{facets.code}}">
                                                                                        <input type="checkbox"
                                                                                            id="{{facetValues.name}}"
                                                                                            class="d-none">
                                                                                        <span class="checkmark filters"
                                                                                            checked="checked"
                                                                                            routerLink="./"
                                                                                            [queryParams]="getLinkParams(facetValues)"
                                                                                            [class.selected]="facetValues.selected"
                                                                                            [cxFocus]="facetValues.name"
                                                                                            (click)="getTabChange(facets.code,facetValues.name,facetValues.selected)"
                                                                                            (keydown.space)="openLink($event)"></span>
                                                                                        <span class="attribute-info"
                                                                                            routerLink="./"
                                                                                            [queryParams]="getLinkParams(facetValues)"
                                                                                            [class.selected]="facetValues.selected"
                                                                                            [cxFocus]="facetValues.name"
                                                                                            (click)="getTabChange(facets.code,facetValues.name,facetValues.selected)"
                                                                                            (keydown.space)="openLink($event)">
                                                                                            <span class="label ">{{
                                                                                                facetValues.name
                                                                                                }}</span>
                                                                                            <span class="count "> ({{
                                                                                                facetValues.count
                                                                                                }})</span>
                                                                                        </span>
                                                                                    </label>
                                                                                </ng-container>
                                                                            </ng-container>
                                                                            <div class="show-attributes"
                                                                                *ngIf="facets.values.length > 5">
                                                                                <a href="javascript:void(0)"
                                                                                    class="more-button"
                                                                                    id="button-{{facets.code}}"
                                                                                    (click)="clickMoreLess(facets.code)">
                                                                                    {{this.moreLessIndicator.includes(facets.code+"close")
                                                                                    ? "Show Less" : "Show More" }}
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                            <ng-container *ngIf="facets.showSearchField != true">
                                                                <ng-container
                                                                    *ngIf="facets.fancyFacetType ==undefined || facets.fancyFacetType =='NOTFANCY'"><!--checkbox-->
                                                                    <div
                                                                        class="checkbox-container group physical-chemical-group">
                                                                        <ng-container
                                                                            *ngFor="let facetValues of   facets.values">
                                                                            <label class="value"
                                                                                id="label-{{facets.code}}">
                                                                                <input type="checkbox"
                                                                                    id="{{facetValues.name}}">
                                                                                <span class="checkmark filters"
                                                                                    checked="checked" routerLink="./"
                                                                                    [queryParams]="getLinkParams(facetValues)"
                                                                                    [class.selected]="facetValues.selected"
                                                                                    [cxFocus]="facetValues.name"
                                                                                    (click)="getTabChange(facets.code,facetValues.name,facetValues.selected)"
                                                                                    (keydown.space)="openLink($event)"></span>
                                                                                <span class="attribute-info"
                                                                                    routerLink="./"
                                                                                    [queryParams]="getLinkParams(facetValues)"
                                                                                    [class.selected]="facetValues.selected"
                                                                                    [cxFocus]="facetValues.name"
                                                                                    (click)="getTabChange(facets.code,facetValues.name,facetValues.selected)"
                                                                                    (keydown.space)="openLink($event)">
                                                                                    <span class="label ">{{
                                                                                        facetValues.name }}</span>
                                                                                    <span class="count "> ({{
                                                                                        facetValues.count }})</span>
                                                                                </span>
                                                                            </label>
                                                                        </ng-container>
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </ng-container>

                                </div>
                                <ng-container *ngIf="facetListToShow === 'Pyhsical & Chemical Characteristics'">
                                    <div class="sticky-footer">
                                        <ng-container *ngIf="activeFacetList$ | async as activeFacetList">
                                            <ng-container *ngIf="activeFacetList.activeFacets.length == 0">
                                                <button type="cancel" class="cancelBtn disabled">
                                                    {{showResultButton}}
                                                </button>
                                            </ng-container>
                                            <ng-container *ngIf="activeFacetList.activeFacets.length > 0">
                                                <div class="reset-button-container">
                                                    <img src="/assets/icons/reload.svg">
                                                    <a class="footer-resetLink"
                                                        (click)="resetAllFilter()">{{resetButton}}</a>
                                                </div>
                                                <div class="button-container">
                                                    <button type="cancel" class="cancelBtn"
                                                        (click)="toggleFilterClose()">
                                                        {{showResultButton}}
                                                    </button>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </section>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </ng-container>
    <!--Products and Filter desktop-->
    <div class="inner">
        <div class="breadcrumbs mobile">
            <ul>
                <ng-container *ngIf="!this.baseUrlMachine.includes('dev') && !this.baseUrlMachine.includes('test')">
                    <li><a href="https://d-portal.doehler.com/" target="_parent">Home</a><span>&nbsp;>&nbsp;</span></li>
                    <li><a href="https://d-portal.doehler.com/product-catalogue/start"
                            target="_parent">Portfolio</a><span>&nbsp;>&nbsp;</span></li>
                </ng-container>
                <ng-container *ngIf="this.baseUrlMachine.includes('test')">
                    <li><a href="https://d-portal-test.doehler.com/" target="_parent">Home</a><span>&nbsp;>&nbsp;</span>
                    </li>
                    <li><a href="https://d-portal-test.doehler.com/product-catalogue/start"
                            target="_parent">Portfolio</a><span>&nbsp;>&nbsp;</span></li>
                </ng-container>
                <ng-container *ngIf="this.baseUrlMachine.includes('dev')">
                    <li><a href="https://d-portal-dev.doehler.com/" target="_parent">Home</a><span>&nbsp;>&nbsp;</span>
                    </li>
                    <li><a href="https://d-portal-dev.doehler.com/product-catalogue/start"
                            target="_parent">Portfolio</a><span>&nbsp;>&nbsp;</span></li>
                </ng-container>
                <ng-container *ngIf="facetList$ | async as facetList">
                    <li>{{facetList.pageName}}</li>
                </ng-container>
            </ul>
        </div>
        <section class="product-filter" id="productFilter">
            <ng-container *ngIf="facetList$ | async as facetList">
                <ng-container *ngIf="facetList.categoriesMediaList != undefined">
                    <ng-container *ngFor="let categoryMedia of facetList.categoriesMediaList">
                        <ng-container *ngIf="getDeviceTypeR() === categoryMedia.mediaFormat">
                            <div class="teaser key-visual" [ngStyle]="getBackofficeImage(categoryMedia.mediaPath)">
                                <div class="teaser-content">
                                    <div class="inner">
                                        <div class="wrapper">
                                            <h1>{{facetList.pageName}}</h1>
                                            <div class="buttonRow desktop">
                                                <div class="application-fit-filter">
                                                    <button class="applicationFit-button outline"
                                                        [disabled]="this.isDisabled"
                                                        (click)="toggleApplicationFitFilter()">
                                                        <i class="application-fit"></i>
                                                        {{applicationFitButton}}
                                                        <div class="selected-filter"
                                                            *ngIf="this.applicationFitCount > 0">
                                                            {{this.applicationFitCount}}
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="facetList.categoriesMediaList == undefined">
                    <div class="teaser">
                        <div class="teaser-content">
                            <div class="inner">
                                <div class="wrapper">
                                    <h1>{{facetList.pageName}}</h1>
                                    <div class="buttonRow desktop">
                                        <div class="application-fit-filter">
                                            <button class="applicationFit-button outline" [disabled]="this.isDisabled"
                                                (click)="toggleApplicationFitFilter()">
                                                <i class="application-fit"></i>
                                                {{applicationFitButton}}
                                                <div class="selected-filter" *ngIf="this.applicationFitCount > 0">
                                                    {{this.applicationFitCount}}
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <div class="filterRow">
                <div class="inner">
                    <button class="filterButton" (click)="toggleFilterBar()" [disabled]="this.isDisabledFilter">
                        <ng-container *ngIf="!this.isDisabledFilter"><img src="/assets/icons/filter.svg"
                                alt=""></ng-container>
                        <ng-container *ngIf="this.isDisabledFilter"><img src="/assets/icons/filter-disabled.svg"
                                alt=""></ng-container>
                        <span id="filterButtonTitle">{{filterButton}}</span>
                    </button>
                    <div class="category-tabs">
                        <div class="category-tabs-button-row">
                            <ng-container *ngIf="facetList$ | async as facetList">
                                <ng-container
                                    *ngIf="facetList && facetList.doehlerCatalogConfigData && facetList.doehlerCatalogConfigData.filterFacetDCatalog != null">
                                    <ng-container
                                        *ngFor="let facets of facetList.doehlerCatalogConfigData.filterFacetDCatalog">
                                        <ng-container *ngIf="facets.values.length > 0">
                                            <ng-container *ngIf="facets.code === 'quickFilters'">
                                                <ng-container *ngFor="let facetValues  of facets.values">
                                                    <ng-container *ngIf="facetValues.count > 0">
                                                        <div #facetValue routerLink="./" class="tabs-button"
                                                            id="{{facetValues.name}}"
                                                            [queryParams]="getLinkParams(facetValues)"
                                                            [class.selected]="facetValues.selected"
                                                            [cxFocus]="facetValues.name"
                                                            (click)="getCategoryTabChange(facets.code,facetValues.name,facetValues.selected)"
                                                            (keydown.space)="openLink($event)">
                                                            <span class="facet-name">{{facetValues.name}}&nbsp;</span>
                                                            <span class="facet-count">({{facetValues.count}})</span>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="facetValues.count == 0">
                                                        <div class="tabs-button disabled">
                                                            <span class="facet-name">{{facetValues.name}}&nbsp;</span>
                                                            <span class="facet-count">({{facetValues.count}})</span>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div id="filterFlyInDesktop" class="filterFlyIn-desktop">
                    <div class="filterFlyIn-wrapper inner">
                        <div class="facets-section">
                            <ng-container *ngIf="!this.isDisabledFilter">
                                <ng-container *ngIf="facetList$ | async as facetList">
                                    <!--ColourShade dropdown -->
                                    <ng-container *ngFor="let facets of facetList.attributeGroups">
                                        <ng-container *ngIf="facetList.attributeGroups != undefined">
                                            <section class="colourShadeSection facetList"
                                                *ngIf="facetList.attributeGroups[0].colourGroupList[0]">
                                                <div id="facetFilters-{{facets.attributeCode}}" class="facetFilters"
                                                    [ngClass]="{ active: facets.attributeCode === facetListDropdown }">
                                                    <button id="{{facets.attributeCode}}" class="facetFilters-button"
                                                        type="button" (click)="openDropdown(facets.attributeCode)"
                                                        (clickOutside)="onClickedOutside($event,facets.attributeCode)"
                                                        [exclude]="'.facetFilters-dropdown'"
                                                        [ngClass]="{ active: facets.attributeCode === facetListDropdown }">
                                                        <span class="text-container">
                                                            <span class="facetFilters-placeholder">
                                                                {{colorShadeTitle}}
                                                                <div class="filterCount"
                                                                    *ngIf="selectedFacetCountMap && selectedFacetCountMap[facets.attributeCode]">
                                                                    {{selectedFacetCountMap[facets.attributeCode]}}
                                                                </div>
                                                            </span>
                                                            <img class="button-down-icon"
                                                                src="/assets/icons/chevron-down-filters.svg" />
                                                        </span>
                                                    </button>
                                                </div>
                                                <ul class="facetFilters-dropdown"
                                                    id="facetFiltersList-{{facets.attributeCode}}"
                                                    [ngClass]="{ active: facets.attributeCode === facetListDropdown }">
                                                    <div class="facetListFilters facet-filters">
                                                        <div class="checkbox-container group">
                                                            <ng-container
                                                                *ngFor="let attributeGroup of facetList.attributeGroups">
                                                                <ng-container
                                                                    *ngFor="let colorGroup of attributeGroup.colourGroupList">
                                                                    <ng-container
                                                                        *ngFor="let subColourGroup of colorGroup.subAttributeFacets">
                                                                        <label #facetValue routerLink="./"
                                                                            [queryParams]="getGroupLinkParams(subColourGroup.queryValue)"
                                                                            class="sub-color-value value"
                                                                            [class.selected]="subColourGroup.facetValueData.selected"
                                                                            [cxFocus]="subColourGroup.facetValueData.code"
                                                                            (click)="getColourShadeChange(attributeGroup.attributeCode,colorGroup.mainAttributeFacet,colorGroup.mainFacetValueData.selected)"
                                                                            (keydown.space)="openLink($event)">
                                                                            <ng-container
                                                                                *ngIf="subColourGroup.facetValueData.selected == true">
                                                                                <input type="checkbox"
                                                                                    id="colourShade-{{subColourGroup.facetValueData.code}}"
                                                                                    name="colourShade">
                                                                                <ng-container
                                                                                    *ngIf="subColourGroup.attributeValue == 'black' || subColourGroup.attributeValue == 'brown' || subColourGroup.attributeValue == 'orange'
                                                                                || subColourGroup.attributeValue == 'violet' || subColourGroup.attributeValue == 'blue' || subColourGroup.attributeValue == 'green'
                                                                                || subColourGroup.attributeValue == 'red' || subColourGroup.attributeValue == 'red-blue'">
                                                                                    <span class="checkmark color"
                                                                                        [ngStyle]="setBackgroundColour(subColourGroup.attributeValue)"
                                                                                        checked="checked"></span>
                                                                                </ng-container>
                                                                                <ng-container
                                                                                    *ngIf="subColourGroup.attributeValue == 'yellow' || subColourGroup.attributeValue == 'yellow-orange' || subColourGroup.attributeValue == 'white'
                                                                                || subColourGroup.attributeValue == 'colorless' || subColourGroup.attributeValue == 'pink' || subColourGroup.attributeValue == 'glitter'">
                                                                                    <span class="checkmark color thick"
                                                                                        [ngStyle]="setBackgroundColour(subColourGroup.attributeValue)"
                                                                                        checked="checked"></span>
                                                                                </ng-container>
                                                                                <span class="attribute-info">
                                                                                    <span class="label">{{
                                                                                        subColourGroup.facetValueData.name
                                                                                        }}</span>
                                                                                    <span class="count"> ({{
                                                                                        subColourGroup.facetValueData.count
                                                                                        }})</span>
                                                                                </span>
                                                                            </ng-container>
                                                                            <ng-container
                                                                                *ngIf="subColourGroup.facetValueData.selected != true">
                                                                                <input type="checkbox"
                                                                                    id="colourShade-{{subColourGroup.facetValueData.code}}"
                                                                                    name="colourShade">
                                                                                <ng-container
                                                                                    *ngIf="subColourGroup.attributeValue == 'black' || subColourGroup.attributeValue == 'brown' || subColourGroup.attributeValue == 'orange'
                                                                                || subColourGroup.attributeValue == 'violet' || subColourGroup.attributeValue == 'blue' || subColourGroup.attributeValue == 'green'
                                                                                || subColourGroup.attributeValue == 'yellow' || subColourGroup.attributeValue == 'yellow-orange' || subColourGroup.attributeValue == 'white'
                                                                                || subColourGroup.attributeValue == 'colorless' || subColourGroup.attributeValue == 'pink' || subColourGroup.attributeValue == 'glitter'
                                                                                || subColourGroup.attributeValue == 'red' || subColourGroup.attributeValue == 'red-blue'">
                                                                                    <span class="checkmark"
                                                                                        [ngStyle]="setBackgroundColour(subColourGroup.attributeValue)"
                                                                                        checked=""></span>
                                                                                </ng-container>
                                                                                <span class="attribute-info">
                                                                                    <span class="label">{{
                                                                                        subColourGroup.facetValueData.name
                                                                                        }}</span>
                                                                                    <span class="count"> ({{
                                                                                        subColourGroup.facetValueData.count
                                                                                        }})</span>
                                                                                </span>
                                                                            </ng-container>
                                                                        </label>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                    <div class="sticky-footer">
                                                        <ng-container
                                                            *ngIf="activeFacetList$ | async as activeFacetList">
                                                            <ng-container
                                                                *ngIf="activeFacetList.activeFacets.length == 0">
                                                                <div class="reset-button-container disabled">
                                                                    <a class="footer-resetLink disabled"></a>
                                                                </div>
                                                                <button type="cancel" class="cancelBtn"
                                                                    (click)="toggleFilterClose()">
                                                                    {{cancelButton}}
                                                                </button>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="activeFacetList.activeFacets.length > 0">
                                                                <div class="reset-button-container">
                                                                    <img src="/assets/icons/reload.svg">
                                                                    <a class="footer-resetLink"
                                                                        (click)="resetCustomFacetFilter(facets.attributeCode)">{{resetButton}}</a>
                                                                </div>
                                                                <div class="button-container">
                                                                    <button type="cancel" class="cancelBtn"
                                                                        (click)="toggleFilterClose()">
                                                                        {{cancelButton}}
                                                                    </button>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                    </div>
                                                </ul>
                                            </section>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="facetList && facetList.doehlerCatalogConfigData && facetList.doehlerCatalogConfigData.filterFacetDCatalog != null">
                                        <ng-container
                                            *ngFor="let facets of  facetList.doehlerCatalogConfigData.filterFacetDCatalog">
                                            <ng-container *ngIf="facets.values.length > 0">
                                                <ng-container
                                                    *ngIf="facets.code !== 'RMW61ZF01' && facets.code !== 'RDW11ZF01' && facets.code !== 'quickFilters' && facets.code !== 'targetmarketlist'">
                                                    <section class="facetList">
                                                        <div id="facetFilters-{{facets.code}}" class="facetFilters"
                                                            [ngClass]="{ open: facets.code === facetListDropdown }">
                                                            <button id="{{facets.code}}" class="facetFilters-button"
                                                                [exclude]="'.facetFilters-dropdown'" type="button"
                                                                (clickOutside)="onClickedOutside($event,facets.code)"
                                                                (click)="openDropdown(facets.code)"
                                                                [ngClass]="{ active: facets.code === facetListDropdown}">
                                                                <span class="text-container">
                                                                    <span
                                                                        class="facetFilters-placeholder">{{facets.name}}
                                                                        <div class="filterCount"
                                                                            *ngIf="selectedFacetCountMap && selectedFacetCountMap[facets.code]">
                                                                            {{selectedFacetCountMap[facets.code]}}
                                                                        </div>
                                                                    </span>
                                                                    <img class="button-down-icon"
                                                                        src="/assets/icons/chevron-down-filters.svg" />
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <ul class="facetFilters-dropdown"
                                                            id="facetFiltersList-{{facets.code}}"
                                                            [ngClass]="{ active: facets.code === facetListDropdown}">
                                                            <div class="facetListFilters facet-filters">
                                                                <ng-container
                                                                    *ngIf="facets.fancyFacetType !=undefined || facets.fancyFacetType !='NOTFANCY'">
                                                                    <ng-container
                                                                        *ngIf="facets.fancyFacetType =='RADIO'"><!--radio-->
                                                                        <div class="radioButtonContainer">
                                                                            <ng-container
                                                                                *ngFor="let facetValues of  facets.values ;index as i">
                                                                                <div *ngIf="i<5 || moreLessIndicator == facets.code+'close'"
                                                                                    class="row">
                                                                                    <label #facetValue routerLink="./"
                                                                                        [queryParams]="getLinkParams(facetValues)"
                                                                                        class="value radio-container"
                                                                                        [class.selected]="facetValues.selected"
                                                                                        [cxFocus]="facetValues.name"
                                                                                        (click)="getTabChange(facets.code,facetValues.name,facetValues.selected)"
                                                                                        (keydown.space)="openLink($event)">
                                                                                        <!--secili olan attribute degeri secili olarak gosterilmeli-->
                                                                                        <ng-container
                                                                                            *ngIf="facetValues.selected == true">
                                                                                            <input type="radio"
                                                                                                id="colourShade-{{facetValues.name}}"
                                                                                                name="colourShade"
                                                                                                [value]="facetValues.name">
                                                                                            <span class="checkmark"
                                                                                                checked="checked"
                                                                                                style="background-color:#01203d;"></span>
                                                                                        </ng-container>
                                                                                        <ng-container
                                                                                            *ngIf="facetValues.selected == false">
                                                                                            <input type="radio"
                                                                                                id="colourShade-{{facetValues.name}}"
                                                                                                name="colourShade"
                                                                                                [value]="facetValues.name">
                                                                                            <span
                                                                                                class="checkmark"></span>
                                                                                        </ng-container>
                                                                                        <span class="attribute-info">
                                                                                            <span class="label">{{
                                                                                                facetValues.name
                                                                                                }}</span>
                                                                                            <span class="count"> ({{
                                                                                                facetValues.count
                                                                                                }})</span>
                                                                                        </span>
                                                                                    </label>
                                                                                </div>
                                                                            </ng-container>
                                                                            <div class="show-attributes"
                                                                                *ngIf="facets.values.length > 10">
                                                                                <a href="javascript:void(0)"
                                                                                    id="button-{{facets.code}}"
                                                                                    class="show-button"
                                                                                    (click)="clickMoreLess(facets.code)">
                                                                                    {{this.moreLessIndicator.includes(facets.code+"close")
                                                                                    ? "Show Less" : "Show More" }}
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="facets.fancyFacetType =='SLIDER'"><!-- slider -->
                                                                    <ng-container
                                                                        *ngFor="let facetValues of  facets.values; let i = index">
                                                                        <ng-container *ngIf="i == 0">
                                                                            <div id="sliderContainer-{{facets.code}}"
                                                                                class="sliderContainer warning active">
                                                                                <span
                                                                                    class="physicalChemical-subtitle">{{facets.name}}
                                                                                </span>
                                                                                <div class="filter-slider">
                                                                                    <div class="filter-slider-content">
                                                                                        <div class="slider-content">
                                                                                            <h4 class="input-text">min
                                                                                                {{facets.name}}</h4>
                                                                                            <div class="min-value">
                                                                                                <input
                                                                                                    id="physicalSliderInput-{{facets.code}}"
                                                                                                    class="slider-input"
                                                                                                    type="text"
                                                                                                    (focusout)="enterMinValues(facetList.currentQuery.query.value,$event,facets.minSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)"
                                                                                                    (keydown.enter)="enterMinValues(facetList.currentQuery.query.value,$event,facets.minSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)"
                                                                                                    placeholder="{{decimalNumber(facets.minSelectSliderValue)}}" />
                                                                                                <div
                                                                                                    class="button-container">
                                                                                                    <button
                                                                                                        routerLink="./"
                                                                                                        [queryParams]="getMinMinLinkParams(facetValues,facets.minSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)"
                                                                                                        class="decrease-button"
                                                                                                        id="range-{{facets.code}}"
                                                                                                        [class.selected]="facetValues.selected"
                                                                                                        [cxFocus]="facetValues.name"
                                                                                                        (keydown.space)="openLink($event)"
                                                                                                        (click)="updateMinValue(facets.minSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)">
                                                                                                        <img
                                                                                                            src="/assets/icons/add_minus_single_grey.svg" />
                                                                                                    </button>
                                                                                                    <button #facetValue
                                                                                                        routerLink="./"
                                                                                                        [queryParams]="getMinMaxLinkParams(facetValues,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)"
                                                                                                        class="increase-button"
                                                                                                        id="range-{{facets.code}}"
                                                                                                        [class.selected]="facetValues.selected"
                                                                                                        [cxFocus]="facetValues.name"
                                                                                                        (keydown.space)="openLink($event)">
                                                                                                        <img
                                                                                                            src="/assets/icons/add_plus_single_grey.svg" />
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="slider-content">
                                                                                            <h4 class="input-text">max
                                                                                                {{facets.name}}</h4>
                                                                                            <div class="max-value">
                                                                                                <input
                                                                                                    id="sliderInput-{{facets.code}}"
                                                                                                    class="slider-input"
                                                                                                    type="text"
                                                                                                    min="{{decimalNumber(facets.minSliderValue)}}"
                                                                                                    max="{{decimalNumber(facets.maxSliderValue)}}"
                                                                                                    placeholder="{{decimalNumber(facets.maxSelectSliderValue)}}"
                                                                                                    (focusout)="enterMaxValues(facetList.currentQuery.query.value,$event,facets.maxSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)"
                                                                                                    (keydown.enter)="enterMinValues(facetList.currentQuery.query.value,$event,facets.minSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)" />
                                                                                                <div
                                                                                                    class="button-container">
                                                                                                    <button
                                                                                                        routerLink="./"
                                                                                                        [queryParams]="getMaxMinLinkParams(facetValues,facets.maxSelectSliderValue,facets.minSelectSliderValue,facets.code)"
                                                                                                        class="decrease-button"
                                                                                                        id="range-{{facets.code}}"
                                                                                                        [class.selected]="facetValues.selected"
                                                                                                        [cxFocus]="facetValues.name"
                                                                                                        (keydown.space)="openLink($event)">
                                                                                                        <img
                                                                                                            src="/assets/icons/add_minus_single_grey.svg" />
                                                                                                    </button>
                                                                                                    <button
                                                                                                        routerLink="./"
                                                                                                        [queryParams]="getMaxMaxLinkParams(facetValues,facets.maxSliderValue,facets.maxSelectSliderValue,facets.minSelectSliderValue,facets.code)"
                                                                                                        class="increase-button"
                                                                                                        id="range-{{facets.code}}"
                                                                                                        [class.selected]="facetValues.selected"
                                                                                                        [cxFocus]="facetValues.name"
                                                                                                        (keydown.space)="openLink($event)"
                                                                                                        (click)="updateMaxValue(facets.maxSliderValue,facets.maxSelectSliderValue,facets.minSelectSliderValue,facets.code)">
                                                                                                        <img
                                                                                                            src="/assets/icons/add_plus_single_grey.svg" />
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <span
                                                                                        id="minWarning-{{facets.minSliderValue}}"
                                                                                        class="min-warning">The minimum
                                                                                        value is
                                                                                        {{facets.minSliderValue}}. We
                                                                                        have automatically reset your
                                                                                        input to the minimum
                                                                                        value.</span>
                                                                                    <span
                                                                                        id="maxWarning-{{facets.maxSliderValue}}"
                                                                                        class="max-warning">The maximum
                                                                                        value is
                                                                                        {{facets.maxSliderValue}}. We
                                                                                        have automatically reset your
                                                                                        input to the maximum
                                                                                        value.</span>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </ng-container>
                                                                <ng-container *ngIf="facets.showSearchField == true">
                                                                    <ng-container
                                                                        *ngIf="facets.fancyFacetType ==undefined || facets.fancyFacetType =='NOTFANCY'"><!--checkbox-->
                                                                        <input id="searchLabel-{{facets.code}}"
                                                                            class="field-container group facet-filters form-group search-label"
                                                                            (clickOutside)="onChangeBackgroundOutside(facets.code)"
                                                                            (click)="changeBackground(facets.code)"
                                                                            type="text"
                                                                            [attr.aria-label]="'category.searchFacetFilter' | cxTranslate"
                                                                            (keyup)=onSearchPropertyEvent($event,facets.code)
                                                                            placeholder="{{ 'category.searchFacetFilter' | cxTranslate: { facet: facets.name } }}" />
                                                                        <div class="checkbox-container group facet-list-long"
                                                                            id="checkBoxProperty-{{facets.code}}">
                                                                            <ng-container
                                                                                *ngFor="let facetValues of facets.values">
                                                                                <label #facetValue routerLink="./"
                                                                                    [queryParams]="getLinkParams(facetValues)"
                                                                                    class="facetValue hidden"
                                                                                    [class.selected]="facetValues.selected"
                                                                                    [cxFocus]="facetValues.name"
                                                                                    (click)="getTabChange(facets.code,facetValues.name,facetValues.selected)"
                                                                                    (keydown.space)="openLink($event)">
                                                                                    <!-- secili olan attribute degeri secili olarak gosterilmeli -->
                                                                                    <ng-container
                                                                                        *ngIf="facetValues.selected == true">
                                                                                        <input type="checkbox"
                                                                                            id="{{facetValues.name}}"
                                                                                            class="d-none">
                                                                                        <span class="checkmark filters"
                                                                                            checked="checked"></span>
                                                                                    </ng-container>
                                                                                    <ng-container
                                                                                        *ngIf="facetValues.selected == false">
                                                                                        <input type="checkbox "
                                                                                            id="{{facetValues.name}}"
                                                                                            class="d-none">
                                                                                        <span class="checkmark"></span>
                                                                                    </ng-container>
                                                                                    <span class="attribute-info">
                                                                                        <span
                                                                                            class="facetValueCheckmark">{{
                                                                                            facetValues.name }}</span>
                                                                                        <span class="count"> ({{
                                                                                            facetValues.count }})</span>
                                                                                    </span>
                                                                                </label>
                                                                            </ng-container>
                                                                            <ng-container
                                                                                *ngFor="let paginatedFacetValue of paginatedFacetValues(facets)">
                                                                                <label #facetValue routerLink="./"
                                                                                    [queryParams]="getLinkParams(paginatedFacetValue)"
                                                                                    class="paginationValue"
                                                                                    [class.selected]="paginatedFacetValue.selected"
                                                                                    [cxFocus]="paginatedFacetValue.name"
                                                                                    (click)="getTabChange(facets.code,paginatedFacetValue.name,paginatedFacetValue.selected)"
                                                                                    (keydown.space)="openLink($event)">
                                                                                    <!-- secili olan attribute degeri secili olarak gosterilmeli -->
                                                                                    <ng-container
                                                                                        *ngIf="paginatedFacetValue.selected == true">
                                                                                        <input type="checkbox"
                                                                                            id="{{paginatedFacetValue.name}}"
                                                                                            class="d-none">
                                                                                        <span class="checkmark filters"
                                                                                            checked="checked"></span>
                                                                                    </ng-container>
                                                                                    <ng-container
                                                                                        *ngIf="paginatedFacetValue.selected == false">
                                                                                        <input type="checkbox "
                                                                                            id="{{paginatedFacetValue.name}}"
                                                                                            class="d-none">
                                                                                        <span class="checkmark"></span>
                                                                                    </ng-container>
                                                                                    <span class="attribute-info">
                                                                                        <span
                                                                                            class="paginationValueCheckmark">{{
                                                                                            paginatedFacetValue.name
                                                                                            }}</span>
                                                                                        <span class="count"> ({{
                                                                                            paginatedFacetValue.count
                                                                                            }})</span>
                                                                                    </span>
                                                                                </label>
                                                                            </ng-container>
                                                                            <!-- Add pagination buttons for each facet's values -->
                                                                            <ng-container
                                                                                *ngIf="facets.values.length > 10">
                                                                                <div id="dropdownPaginationButtons-{{ facets.code }}" class="dropdown-pagination">
                                                                                    <button
                                                                                      id="prevButton-{{ facets.code }}"
                                                                                      class="pagination-button"
                                                                                      [disabled]="currentFacetPage[facets.code]?.page === 1"
                                                                                      (click)="goToPage(facets, currentFacetPage[facets.code]?.page - 1)">
                                                                                        <<
                                                                                    </button>
                                                                                    <ng-container *ngFor="let page of paginatedFacetPages(facets); let i = index">
                                                                                        <button
                                                                                          id="paginationButton-{{ facets.code }}-{{ page }}"
                                                                                          class="pagination-button"
                                                                                          [ngClass]="{'selected' : page === currentFacetPage[facets.code]?.page}"
                                                                                          (click)="goToPage(facets, page)">
                                                                                            <span class="pagination-button-number">{{ page }}</span>
                                                                                        </button>
                                                                                    </ng-container>
                                                                                    <button
                                                                                      id="nextButton-{{ facets.code }}"
                                                                                      class="pagination-button"
                                                                                      [disabled]="currentFacetPage[facets.code]?.page === facetPages(facets).length"
                                                                                      (click)="goToPage(facets, currentFacetPage[facets.code]?.page + 1)">
                                                                                        >>
                                                                                    </button>
                                                                                </div>
                                                                            </ng-container>
                                                                        </div>
                                                                    </ng-container>
                                                                </ng-container>
                                                                <ng-container *ngIf="facets.showSearchField != true">
                                                                    <ng-container
                                                                        *ngIf="facets.fancyFacetType ==undefined || facets.fancyFacetType =='NOTFANCY'"><!--checkbox-->
                                                                        <div class="checkbox-container group">
                                                                            <ng-container
                                                                                *ngFor="let facetValues of facets.values">
                                                                                <label #facetValue routerLink="./"
                                                                                    [queryParams]="getLinkParams(facetValues)"
                                                                                    class="value"
                                                                                    [class.selected]="facetValues.selected"
                                                                                    [cxFocus]="facetValues.name"
                                                                                    (click)="getTabChange(facets.code,facetValues.name,facetValues.selected)"
                                                                                    (keydown.space)="openLink($event)">
                                                                                    <ng-container
                                                                                        *ngIf="facetValues.selected == true">
                                                                                        <input type="checkbox"
                                                                                            id="{{facetValues.name}}">
                                                                                        <span class="checkmark filters"
                                                                                            checked="checked"></span>
                                                                                    </ng-container>
                                                                                    <ng-container
                                                                                        *ngIf="facetValues.selected == false">
                                                                                        <input type="checkbox"
                                                                                            id="{{facetValues.name}}">
                                                                                        <span class="checkmark"></span>
                                                                                    </ng-container>
                                                                                    <span class="attribute-info">
                                                                                        <span class="label">{{
                                                                                            facetValues.name }}</span>
                                                                                        <span class="count"> ({{
                                                                                            facetValues.count }})</span>
                                                                                    </span>
                                                                                </label>
                                                                            </ng-container>
                                                                        </div>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </div>
                                                            <div class="sticky-footer">
                                                                <ng-container
                                                                    *ngIf="activeFacetList$ | async as activeFacetList">
                                                                    <ng-container
                                                                        *ngIf="activeFacetList.activeFacets.length == 0">
                                                                        <div class="reset-button-container disabled">
                                                                            <a class="footer-resetLink disabled"></a>
                                                                        </div>
                                                                        <button type="cancel" class="cancelBtn"
                                                                            (click)="closeDropdown(facets.code)">
                                                                            {{cancelButton}}
                                                                        </button>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="activeFacetList.activeFacets.length > 0">
                                                                        <div class="reset-button-container">
                                                                            <img src="/assets/icons/reload.svg">
                                                                            <a class="footer-resetLink"
                                                                                (click)="resetCustomFacetFilter(facets.code)">{{resetButton}}</a>
                                                                        </div>
                                                                        <div class="button-container">
                                                                            <button type="cancel" class="cancelBtn"
                                                                                (click)="closeDropdown(facets.code)">
                                                                                {{cancelButton}}
                                                                            </button>
                                                                        </div>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </div>
                                                        </ul>
                                                    </section>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                    <!--specificationsRegulatoryInfoDCatalog verisinden gelenlerin doldurdugu alan-->
                                    <ng-container
                                        *ngIf="facetList && facetList.doehlerCatalogConfigData && facetList.doehlerCatalogConfigData.specificationsRegulatoryInfoDCatalog != null && facetList.doehlerCatalogConfigData.specificationsRegulatoryInfoDCatalog.length > 0">
                                        <section class="regulatory-section facetList">
                                            <div id="facetFilters-regulatoryInfo" class="facetFilters">
                                                <button id="regulatoryInfo" class="facetFilters-button"
                                                    [exclude]="'.facetFilters-dropdown'" type="button"
                                                    (clickOutside)="onClickedOutside($event,'regulatoryInfo')"
                                                    (click)="openDropdown('regulatoryInfo')">
                                                    <span class="text-container">
                                                        <span class="facetFilters-placeholder">{{regulatoryInfoTitle}}
                                                            <div class="filterCount"
                                                                *ngIf="regulatoryInfoSelectedCountMap && regulatoryInfoSelectedCountMap['regulatoryInfoSelectedCount']">
                                                                {{regulatoryInfoSelectedCountMap['regulatoryInfoSelectedCount']}}
                                                            </div>
                                                        </span>
                                                        <img class="button-down-icon"
                                                            src="/assets/icons/chevron-down-filters.svg" />
                                                    </span>
                                                </button>
                                            </div>
                                            <ul class="facetFilters-dropdown" id="facetFiltersList-regulatoryInfo">
                                                <div class="regulatory-filter facet-filters">
                                                    <ng-container
                                                        *ngFor="let facets of  facetList.doehlerCatalogConfigData.specificationsRegulatoryInfoDCatalog; index as i ">
                                                        <ng-container
                                                            *ngIf="facets.fancyFacetType !=undefined || facets.fancyFacetType !='NOTFANCY'">
                                                            <ng-container *ngIf="facets.values.length > 0">
                                                                <span class="regulatory-subtitle">{{facets.name}}
                                                                    <div class="filterCount"
                                                                        *ngIf="selectedFacetCountMap && selectedFacetCountMap[facets.code]">
                                                                        {{selectedFacetCountMap[facets.code]}}
                                                                    </div>
                                                                </span>
                                                                <ng-container
                                                                    *ngIf="facets.fancyFacetType =='RADIO'"><!--radio-->
                                                                    <div
                                                                        class="radioButtonContainer regulatory-info-group">
                                                                        <ng-container
                                                                            *ngFor="let facetValues of  facets.values| slice: 0:4">
                                                                            <label #facetValue routerLink="./"
                                                                                [queryParams]="getLinkParams(facetValues)"
                                                                                class="value radio-container"
                                                                                [class.selected]="facetValues.selected"
                                                                                [cxFocus]="facetValues.name"
                                                                                (click)="getTabChange(facets.code,facetValues.name,facetValues.selected)"
                                                                                (keydown.space)="openLink($event)">
                                                                                <ng-container
                                                                                    *ngIf="facetValues.selected == true">
                                                                                    <input type="radio"
                                                                                        id="colourShade-{{facetValues.name}}"
                                                                                        name="colourShade"
                                                                                        [value]="facetValues.name">
                                                                                    <span class="checkmark"
                                                                                        checked="checked"
                                                                                        style="background-color:#01203d;"></span>
                                                                                </ng-container>
                                                                                <ng-container
                                                                                    *ngIf="facetValues.selected == false">
                                                                                    <input type="radio"
                                                                                        id="colourShade-{{facetValues.name}}"
                                                                                        name="colourShade"
                                                                                        [value]="facetValues.name">
                                                                                    <span class="checkmark"></span>
                                                                                </ng-container>
                                                                                <span class="attribute-info">
                                                                                    <span
                                                                                        class="label">{{facetValues.name}}</span>
                                                                                    <span class="count"> ({{
                                                                                        facetValues.count }})</span>
                                                                                </span>
                                                                            </label>
                                                                        </ng-container>
                                                                    </div>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="facets.fancyFacetType =='SLIDER'"><!-- slider -->
                                                                    <ng-container
                                                                        *ngFor="let facetValues of  facets.values; let i = index">
                                                                        <ng-container *ngIf="i == 0">
                                                                            <div id="sliderContainer-{{facets.code}}"
                                                                                class="sliderContainer warning active">
                                                                                <div class="filter-slider">
                                                                                    <div class="filter-slider-content">
                                                                                        <div class="slider-content">
                                                                                            <h4 class="input-text">min
                                                                                                {{facets.name}}</h4>
                                                                                            <div class="min-value">
                                                                                                <input
                                                                                                    id="physicalSliderInput-{{facets.code}}"
                                                                                                    class="slider-input"
                                                                                                    type="text"
                                                                                                    (focusout)="enterMinValues(facetList.currentQuery.query.value,$event,facets.minSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)"
                                                                                                    (keydown.enter)="enterMinValues(facetList.currentQuery.query.value,$event,facets.minSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)"
                                                                                                    placeholder="{{decimalNumber(facets.minSelectSliderValue)}}" />
                                                                                                <div
                                                                                                    class="button-container">
                                                                                                    <button
                                                                                                        routerLink="./"
                                                                                                        [queryParams]="getMinMinLinkParams(facetValues,facets.minSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)"
                                                                                                        class="decrease-button"
                                                                                                        id="range-{{facets.code}}"
                                                                                                        [class.selected]="facetValues.selected"
                                                                                                        [cxFocus]="facetValues.name"
                                                                                                        (keydown.space)="openLink($event)"
                                                                                                        (click)="updateMinValue(facets.minSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)">
                                                                                                        <img
                                                                                                            src="/assets/icons/add_minus_single_grey.svg" />
                                                                                                    </button>
                                                                                                    <button #facetValue
                                                                                                        routerLink="./"
                                                                                                        [queryParams]="getMinMaxLinkParams(facetValues,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)"
                                                                                                        class="increase-button"
                                                                                                        id="range-{{facets.code}}"
                                                                                                        [class.selected]="facetValues.selected"
                                                                                                        [cxFocus]="facetValues.name"
                                                                                                        (keydown.space)="openLink($event)">
                                                                                                        <img
                                                                                                            src="/assets/icons/add_plus_single_grey.svg" />
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="slider-content">
                                                                                            <h4 class="input-text">max
                                                                                                {{facets.name}}</h4>
                                                                                            <div class="max-value">
                                                                                                <input
                                                                                                    id="sliderInput-{{facets.code}}"
                                                                                                    class="slider-input"
                                                                                                    type="text"
                                                                                                    min="{{decimalNumber(facets.minSliderValue)}}"
                                                                                                    max="{{decimalNumber(facets.maxSliderValue)}}"
                                                                                                    placeholder="{{decimalNumber(facets.maxSelectSliderValue)}}"
                                                                                                    (focusout)="enterMaxValues(facetList.currentQuery.query.value,$event,facets.maxSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)"
                                                                                                    (keydown.enter)="enterMinValues(facetList.currentQuery.query.value,$event,facets.minSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)" />
                                                                                                <div
                                                                                                    class="button-container">
                                                                                                    <button
                                                                                                        routerLink="./"
                                                                                                        [queryParams]="getMaxMinLinkParams(facetValues,facets.maxSelectSliderValue,facets.minSelectSliderValue,facets.code)"
                                                                                                        class="decrease-button"
                                                                                                        id="range-{{facets.code}}"
                                                                                                        [class.selected]="facetValues.selected"
                                                                                                        [cxFocus]="facetValues.name"
                                                                                                        (keydown.space)="openLink($event)">
                                                                                                        <img
                                                                                                            src="/assets/icons/add_minus_single_grey.svg" />
                                                                                                    </button>
                                                                                                    <button
                                                                                                        routerLink="./"
                                                                                                        [queryParams]="getMaxMaxLinkParams(facetValues,facets.maxSliderValue,facets.maxSelectSliderValue,facets.minSelectSliderValue,facets.code)"
                                                                                                        class="increase-button"
                                                                                                        id="range-{{facets.code}}"
                                                                                                        [class.selected]="facetValues.selected"
                                                                                                        [cxFocus]="facetValues.name"
                                                                                                        (keydown.space)="openLink($event)"
                                                                                                        (click)="updateMaxValue(facets.maxSliderValue,facets.maxSelectSliderValue,facets.minSelectSliderValue,facets.code)">
                                                                                                        <img
                                                                                                            src="/assets/icons/add_plus_single_grey.svg" />
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <span
                                                                                        id="minWarning-{{facets.minSliderValue}}"
                                                                                        class="min-warning">The minimum
                                                                                        value is
                                                                                        {{facets.minSliderValue}}. We
                                                                                        have automatically reset your
                                                                                        input to the minimum
                                                                                        value.</span>
                                                                                    <span
                                                                                        id="maxWarning-{{facets.maxSliderValue}}"
                                                                                        class="max-warning">The maximum
                                                                                        value is
                                                                                        {{facets.maxSliderValue}}. We
                                                                                        have automatically reset your
                                                                                        input to the maximum
                                                                                        value.</span>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="facets.fancyFacetType ==undefined || facets.fancyFacetType =='NOTFANCY'"><!--checkbox-->
                                                            <div class="checkbox-container group regulatory-info-group">
                                                                <ng-container *ngFor="let facetValues of facets.values">
                                                                    <label #facetValue routerLink="./"
                                                                        [queryParams]="getLinkParams(facetValues)"
                                                                        class="value"
                                                                        [class.selected]="facetValues.selected"
                                                                        [cxFocus]="facetValues.name"
                                                                        (click)="getTabChange(facets.code,facetValues.name,facetValues.selected)"
                                                                        (keydown.space)="openLink($event)">
                                                                        <!--secili olan attribute degeri secili olarak gosterilmeli-->
                                                                        <ng-container
                                                                            *ngIf="facetValues.selected == true">
                                                                            <input type="checkbox"
                                                                                id="{{facetValues.name}}">
                                                                            <span class="checkmark filters"
                                                                                checked="checked"></span>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="facetValues.selected == false">
                                                                            <input type="checkbox"
                                                                                id="{{facetValues.name}}">
                                                                            <span class="checkmark"></span>
                                                                        </ng-container>
                                                                        <span class="attribute-info">
                                                                            <span
                                                                                class="label">{{facetValues.name}}</span>
                                                                            <span class="count"> ({{ facetValues.count
                                                                                }})</span>
                                                                        </span>
                                                                    </label>
                                                                </ng-container>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                                <div class="sticky-footer">
                                                    <ng-container *ngIf="activeFacetList$ | async as activeFacetList">
                                                        <ng-container *ngIf="activeFacetList.activeFacets.length == 0">
                                                            <div class="reset-button-container disabled">
                                                                <a class="footer-resetLink disabled"></a>
                                                            </div>
                                                            <button type="cancel" class="cancelBtn"
                                                                (click)="closeDropdown('regulatoryInfo')">
                                                                {{cancelButton}}
                                                            </button>
                                                        </ng-container>
                                                        <ng-container *ngIf="activeFacetList.activeFacets.length > 0">
                                                            <div class="reset-button-container">
                                                                <img src="/assets/icons/reload.svg">
                                                                <a class="footer-resetLink"
                                                                    (click)="resetCustomFacetFilter('regulatoryInfo')">{{resetButton}}</a>
                                                            </div>
                                                            <div class="button-container">
                                                                <button type="cancel" class="cancelBtn"
                                                                    (click)="closeDropdown('regulatoryInfo')">
                                                                    {{cancelButton}}
                                                                </button>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                            </ul>
                                        </section>
                                    </ng-container>
                                    <!--specificationsPhysicalChemicalCharacteristicsDCatalog verisinden gelenlerin doldurdugu alan-->
                                    <ng-container
                                        *ngIf="facetList && facetList.doehlerCatalogConfigData && facetList.doehlerCatalogConfigData.specificationsPhysicalChemicalCharacteristicsDCatalog != null && facetList.doehlerCatalogConfigData.specificationsPhysicalChemicalCharacteristicsDCatalog.length > 0">
                                        <section class="characteristics-section facetList">
                                            <div id="facetFilters-physicalChemical" class="facetFilters">
                                                <button id="physicalChemical" class="facetFilters-button"
                                                    [exclude]="'.facetFilters-dropdown'" type="button"
                                                    (clickOutside)="onClickedOutside($event,'physicalChemical')"
                                                    (click)="openDropdown('physicalChemical')">
                                                    <span class="text-container">
                                                        <span
                                                            class="facetFilters-placeholder">{{physicalChemicalCharacteristicsTitle}}
                                                            <div class="filterCount"
                                                                *ngIf="physicalChemicalCharSelectedCountMap && physicalChemicalCharSelectedCountMap['physicalChemicalCharSelectedCount']">
                                                                {{physicalChemicalCharSelectedCountMap['physicalChemicalCharSelectedCount']}}
                                                            </div>
                                                        </span>
                                                        <img class="button-down-icon"
                                                            src="/assets/icons/chevron-down-filters.svg" />
                                                    </span>
                                                </button>
                                            </div>
                                            <ul class="facetFilters-dropdown" id="facetFiltersList-physicalChemical">
                                                <div class="physicalChemical-filter facet-filters">
                                                    <ng-container
                                                        *ngFor="let facets of  facetList.doehlerCatalogConfigData.specificationsPhysicalChemicalCharacteristicsDCatalog">
                                                        <ng-container
                                                            *ngIf="facets.fancyFacetType !=undefined || facets.fancyFacetType !='NOTFANCY'">
                                                            <ng-container *ngIf="facets.values.length > 0">
                                                                <span class="physicalChemical-subtitle"
                                                                    *ngIf="facets.code !== 'applicationFitCategoryTree'">{{facets.name}}
                                                                    <div class="filterCount"
                                                                        *ngIf="selectedFacetCountMap && selectedFacetCountMap[facets.code]">
                                                                        {{selectedFacetCountMap[facets.code]}}
                                                                    </div>
                                                                </span>
                                                                <ng-container
                                                                    *ngIf="facets.fancyFacetType =='RADIO'"><!--radio-->
                                                                    <div
                                                                        class="radioButtonContainer physical-chemical-group">
                                                                        <ng-container
                                                                            *ngFor="let facetValues of  facets.values;index as i">
                                                                            <label #facetValue routerLink="./"
                                                                                [queryParams]="getLinkParams(facetValues)"
                                                                                class="value radio-container"
                                                                                [class.selected]="facetValues.selected"
                                                                                [cxFocus]="facetValues.name"
                                                                                (click)="getTabChange(facets.code,facetValues.name,facetValues.selected)"
                                                                                (keydown.space)="openLink($event)">
                                                                                <!--secili olan attribute degeri secili olarak gosterilmeli-->
                                                                                <ng-container
                                                                                    *ngIf="facetValues.selected == true">
                                                                                    <input type="radio"
                                                                                        id="colourShade-{{facetValues.name}}"
                                                                                        name="colourShade"
                                                                                        [value]="facetValues.name">
                                                                                    <span class="checkmark"
                                                                                        checked="checked"
                                                                                        style="background-color:#01203d;"></span>
                                                                                </ng-container>
                                                                                <ng-container
                                                                                    *ngIf="facetValues.selected == false">
                                                                                    <input type="radio"
                                                                                        id="colourShade-{{facetValues.name}}"
                                                                                        name="colourShade"
                                                                                        [value]="facetValues.name">
                                                                                    <span class="checkmark"></span>
                                                                                </ng-container>
                                                                                <span class="attribute-info">
                                                                                    <span class="label">{{
                                                                                        facetValues.name }}</span>
                                                                                    <span class="count"> ({{
                                                                                        facetValues.count }})</span>
                                                                                </span>
                                                                            </label>
                                                                        </ng-container>
                                                                    </div>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="facets.fancyFacetType =='SLIDER'"><!-- slider -->
                                                                    <ng-container
                                                                        *ngFor="let facetValues of  facets.values; let i = index">
                                                                        <ng-container *ngIf="i == 0">
                                                                            <div id="sliderContainer-{{facets.code}}"
                                                                                class="sliderContainer warning active">
                                                                                <div class="filter-slider">
                                                                                    <div class="filter-slider-content">
                                                                                        <div class="slider-content">
                                                                                            <h4 class="input-text">min
                                                                                                {{facets.name}}</h4>
                                                                                            <div class="min-value">
                                                                                                <input
                                                                                                    id="physicalSliderInput-{{facets.code}}"
                                                                                                    class="slider-input"
                                                                                                    type="text"
                                                                                                    (focusout)="enterMinValues(facetList.currentQuery.query.value,$event,facets.minSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)"
                                                                                                    (keydown.enter)="enterMinValues(facetList.currentQuery.query.value,$event,facets.minSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)"
                                                                                                    placeholder="{{decimalNumber(facets.minSelectSliderValue)}}" />
                                                                                                <div
                                                                                                    class="button-container">
                                                                                                    <button
                                                                                                        routerLink="./"
                                                                                                        [queryParams]="getMinMinLinkParams(facetValues,facets.minSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)"
                                                                                                        class="decrease-button"
                                                                                                        id="physicalRange-{{facets.minSliderValue}}"
                                                                                                        [class.selected]="facetValues.selected"
                                                                                                        [cxFocus]="facetValues.name"
                                                                                                        (keydown.space)="openLink($event)"
                                                                                                        (click)="updateMinValue(facets.minSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)">
                                                                                                        <img
                                                                                                            src="/assets/icons/add_minus_single_grey.svg" />
                                                                                                    </button>
                                                                                                    <button
                                                                                                        routerLink="./"
                                                                                                        [queryParams]="getMinMaxLinkParams(facetValues,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)"
                                                                                                        class="increase-button"
                                                                                                        id="physicalRange-{{facets.code}}"
                                                                                                        [class.selected]="facetValues.selected"
                                                                                                        [cxFocus]="facetValues.name"
                                                                                                        (keydown.space)="openLink($event)">
                                                                                                        <img
                                                                                                            src="/assets/icons/add_plus_single_grey.svg" />
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="slider-content">
                                                                                            <h4 class="input-text">max
                                                                                                {{facets.name}}</h4>
                                                                                            <div class="max-value">
                                                                                                <input
                                                                                                    id="sliderInput-{{facets.code}}"
                                                                                                    class="slider-input"
                                                                                                    type="text"
                                                                                                    min="{{decimalNumber(facets.minSliderValue)}}"
                                                                                                    max="{{decimalNumber(facets.maxSliderValue)}}"
                                                                                                    placeholder="{{decimalNumber(facets.maxSelectSliderValue)}}"
                                                                                                    (focusout)="enterMaxValues(facetList.currentQuery.query.value,$event,facets.maxSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)"
                                                                                                    (keydown.enter)="enterMinValues(facetList.currentQuery.query.value,$event,facets.minSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)" />
                                                                                                <div
                                                                                                    class="button-container">
                                                                                                    <button
                                                                                                        routerLink="./"
                                                                                                        [queryParams]="getMaxMinLinkParams(facetValues,facets.maxSelectSliderValue,facets.minSelectSliderValue,facets.code)"
                                                                                                        class="decrease-button"
                                                                                                        id="physicalRange-{{facets.code}}"
                                                                                                        [class.selected]="facetValues.selected"
                                                                                                        [cxFocus]="facetValues.name"
                                                                                                        (keydown.space)="openLink($event)">
                                                                                                        <img
                                                                                                            src="/assets/icons/add_minus_single_grey.svg" />
                                                                                                    </button>
                                                                                                    <button
                                                                                                        routerLink="./"
                                                                                                        [queryParams]="getMaxMaxLinkParams(facetValues,facets.maxSliderValue,facets.maxSelectSliderValue,facets.minSelectSliderValue,facets.code)"
                                                                                                        class="increase-button"
                                                                                                        id="physicalRange-{{facets.maxSliderValue}}"
                                                                                                        [class.selected]="facetValues.selected"
                                                                                                        [cxFocus]="facetValues.name"
                                                                                                        (keydown.space)="openLink($event)"
                                                                                                        (click)="updateMaxValue(facets.maxSliderValue,facets.maxSelectSliderValue,facets.minSelectSliderValue,facets.code)">
                                                                                                        <img
                                                                                                            src="/assets/icons/add_plus_single_grey.svg" />
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <span id="minWarning"
                                                                                        class="min-warning">The minimum
                                                                                        value is
                                                                                        {{facets.minSliderValue}}. We
                                                                                        have automatically reset your
                                                                                        input to the minimum
                                                                                        value.</span>
                                                                                    <span id="maxWarning"
                                                                                        class="max-warning">The maximum
                                                                                        value is
                                                                                        {{facets.maxSliderValue}}. We
                                                                                        have automatically reset your
                                                                                        input to the maximum
                                                                                        value.</span>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="facets.fancyFacetType ==undefined || facets.fancyFacetType =='NOTFANCY'">
                                                                    <ng-container
                                                                        *ngIf="facets.name !== 'applicationFitCategoryTree'">
                                                                        <ng-container
                                                                            *ngIf="facets.showSearchField == true">
                                                                            <input id="searchLabel-{{facets.code}}"
                                                                                class="field-container group facet-filters form-group  search-label"
                                                                                (clickOutside)="onChangeBackgroundOutside(facets.code)"
                                                                                (click)="changeBackground(facets.code)"
                                                                                [attr.aria-label]="'category.searchFacetFilter' | cxTranslate"
                                                                                type="text"
                                                                                (keyup)=onSearchPropertyPhysicalChemicalEvent($event,facets.code)
                                                                                placeholder="{{ 'category.searchFacetFilter' | cxTranslate: { facet: facets.name } }}" />
                                                                            <div class="checkbox-container group facet-list-long physical-chemical-group"
                                                                                id="checkBoxProperty-{{facets.code}}">
                                                                                <ng-container
                                                                                    *ngFor="let facetValues of  facets.values;index as i">
                                                                                    <div id="facetCheckbox-{{facets.code}}"
                                                                                        class="checkbox"
                                                                                        [ngClass]="{show: i<10 || moreLessIndicator == facets.code+'close'}">
                                                                                        <!--facet filterdaki attributeler burada doluyor
                                                                                        Secime göre url ekleme islemi labe icindeki fonk. tarafından yapılıyor.-->
                                                                                        <label #facetValue
                                                                                            routerLink="./"
                                                                                            [queryParams]="getLinkParams(facetValues)"
                                                                                            class="value"
                                                                                            [class.selected]="facetValues.selected"
                                                                                            [cxFocus]="facetValues.name"
                                                                                            (click)="getTabChange(facets.code,facetValues.name,facetValues.selected)"
                                                                                            (keydown.space)="openLink($event)">
                                                                                            <ng-container
                                                                                                *ngIf="facetValues.selected == true">
                                                                                                <input type="checkbox"
                                                                                                    id="{{facetValues.name}}"
                                                                                                    class="d-none">
                                                                                                <span
                                                                                                    class="checkmark filters"
                                                                                                    checked="checked"></span>
                                                                                            </ng-container>
                                                                                            <ng-container
                                                                                                *ngIf="facetValues.selected == false">
                                                                                                <input type="checkbox "
                                                                                                    id="{{facetValues.name}}"
                                                                                                    class="d-none">
                                                                                                <span
                                                                                                    class="checkmark "></span>
                                                                                            </ng-container>
                                                                                            <span
                                                                                                class="attribute-info">
                                                                                                <span class="label ">{{
                                                                                                    facetValues.name
                                                                                                    }}</span>
                                                                                                <span class="count ">
                                                                                                    ({{
                                                                                                    facetValues.count
                                                                                                    }})</span>
                                                                                            </span>
                                                                                        </label>
                                                                                    </div>
                                                                                </ng-container>
                                                                                <div class="show-attributes"
                                                                                    *ngIf="facets.values.length > 10 || moreLessIndicator == facets.code+'close'">
                                                                                    <a href="javascript:void(0)"
                                                                                        id="button-{{facets.code}}"
                                                                                        class="show-button desktop"
                                                                                        (click)="clickMoreLessDesktop(facets.code)">
                                                                                        {{this.moreLessIndicator.includes(facets.code+"close")
                                                                                        ? "Show Less" : "Show More" }}
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="facets.showSearchField != true">
                                                                            <div
                                                                                class="checkbox-container group physical-chemical-group">
                                                                                <ng-container
                                                                                    *ngFor="let facetValues of   facets.values">
                                                                                    <label #facetValue routerLink="./"
                                                                                        [queryParams]="getLinkParams(facetValues)"
                                                                                        class="value"
                                                                                        [class.selected]="facetValues.selected"
                                                                                        [cxFocus]="facetValues.name"
                                                                                        (click)="getTabChange(facets.code,facetValues.name,facetValues.selected)"
                                                                                        (keydown.space)="openLink($event)">
                                                                                        <!--secili olan attribute degeri secili olarak gosterilmeli-->
                                                                                        <ng-container
                                                                                            *ngIf="facetValues.selected == true">
                                                                                            <input type="checkbox"
                                                                                                id="{{facetValues.name}}">
                                                                                            <span
                                                                                                class="checkmark filters"
                                                                                                checked="checked"></span>
                                                                                        </ng-container>
                                                                                        <ng-container
                                                                                            *ngIf="facetValues.selected == false">
                                                                                            <input type="checkbox"
                                                                                                id="{{facetValues.name}}">
                                                                                            <span
                                                                                                class="checkmark"></span>
                                                                                        </ng-container>
                                                                                        <span class="attribute-info">
                                                                                            <span class="label">{{
                                                                                                facetValues.name
                                                                                                }}</span>
                                                                                            <span class="count"> ({{
                                                                                                facetValues.count
                                                                                                }})</span>
                                                                                        </span>
                                                                                    </label>
                                                                                </ng-container>
                                                                            </div>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-container>

                                                    </ng-container>

                                                </div>
                                                <div class="sticky-footer">
                                                    <ng-container *ngIf="activeFacetList$ | async as activeFacetList">
                                                        <ng-container *ngIf="activeFacetList.activeFacets.length == 0">
                                                            <div class="reset-button-container disabled">
                                                                <a class="footer-resetLink disabled"></a>
                                                            </div>
                                                            <button type="cancel" class="cancelBtn"
                                                                (click)="closeDropdown('physicalChemical')">
                                                                {{cancelButton}}
                                                            </button>
                                                        </ng-container>
                                                        <ng-container *ngIf="activeFacetList.activeFacets.length > 0">
                                                            <div class="reset-button-container">
                                                                <img src="/assets/icons/reload.svg">
                                                                <a class="footer-resetLink"
                                                                    (click)="resetCustomFacetFilter('physicalChemical')">{{resetButton}}</a>
                                                            </div>
                                                            <div class="button-container">
                                                                <button type="cancel" class="cancelBtn"
                                                                    (click)="closeDropdown('physicalChemical')">
                                                                    {{cancelButton}}
                                                                </button>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                            </ul>
                                        </section>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="facetList && facetList.doehlerCatalogConfigData && facetList.doehlerCatalogConfigData.filterFacetDCatalog != null">
                                        <ng-container
                                            *ngFor="let facets of  facetList.doehlerCatalogConfigData.filterFacetDCatalog">
                                            <ng-container *ngIf="facets.values.length > 0">
                                                <ng-container *ngIf="facets.code === 'targetmarketlist'">
                                                    <section class="facetList">
                                                        <div id="facetFilters-{{facets.code}}" class="facetFilters"
                                                            [ngClass]="{ open: facets.code === facetListDropdown }">
                                                            <button id="{{facets.code}}" class="facetFilters-button"
                                                                [exclude]="'.facetFilters-dropdown'" type="button"
                                                                (clickOutside)="onClickedOutside($event,facets.code)"
                                                                (click)="openDropdown(facets.code)"
                                                                [ngClass]="{ active: facets.code === facetListDropdown }">
                                                                <span class="text-container">
                                                                    <span
                                                                        class="facetFilters-placeholder">{{facets.name}}
                                                                        <div class="filterCount"
                                                                            *ngIf="selectedFacetCountMap && selectedFacetCountMap[facets.code]">
                                                                            {{selectedFacetCountMap[facets.code]}}
                                                                        </div>
                                                                    </span>
                                                                    <img class="button-down-icon"
                                                                        src="/assets/icons/chevron-down-filters.svg" />
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <ul class="facetFilters-dropdown"
                                                            id="facetFiltersList-{{facets.code}}"
                                                            [ngClass]="{ active: facets.code === facetListDropdown }">
                                                            <div class="facetListFilters facet-filters">
                                                                <ng-container
                                                                    *ngIf="facets.fancyFacetType !=undefined || facets.fancyFacetType !='NOTFANCY'">
                                                                    <ng-container
                                                                        *ngIf="facets.fancyFacetType =='RADIO'"><!--radio-->
                                                                        <div class="radioButtonContainer">
                                                                            <ng-container
                                                                                *ngFor="let facetValues of  facets.values ;index as i">
                                                                                <div *ngIf="i<5 || moreLessIndicator == facets.code+'close'"
                                                                                    class="row">
                                                                                    <label #facetValue routerLink="./"
                                                                                        [queryParams]="getLinkParams(facetValues)"
                                                                                        class="value radio-container"
                                                                                        [class.selected]="facetValues.selected"
                                                                                        [cxFocus]="facetValues.name"
                                                                                        (click)="getTabChange(facets.code,facetValues.name,facetValues.selected)"
                                                                                        (keydown.space)="openLink($event)">
                                                                                        <!--secili olan attribute degeri secili olarak gosterilmeli-->
                                                                                        <ng-container
                                                                                            *ngIf="facetValues.selected == true">
                                                                                            <input type="radio"
                                                                                                id="colourShade-{{facetValues.name}}"
                                                                                                name="colourShade"
                                                                                                [value]="facetValues.name">
                                                                                            <span class="checkmark"
                                                                                                checked="checked"
                                                                                                style="background-color:#01203d;"></span>
                                                                                        </ng-container>
                                                                                        <ng-container
                                                                                            *ngIf="facetValues.selected == false">
                                                                                            <input type="radio"
                                                                                                id="colourShade-{{facetValues.name}}"
                                                                                                name="colourShade"
                                                                                                [value]="facetValues.name">
                                                                                            <span
                                                                                                class="checkmark"></span>
                                                                                        </ng-container>
                                                                                        <span class="attribute-info">
                                                                                            <span class="label">{{
                                                                                                facetValues.name
                                                                                                }}</span>
                                                                                            <span class="count"> ({{
                                                                                                facetValues.count
                                                                                                }})</span>
                                                                                        </span>
                                                                                    </label>
                                                                                </div>
                                                                            </ng-container>
                                                                            <div class="show-attributes"
                                                                                *ngIf="facets.values.length > 10">
                                                                                <a href="javascript:void(0)"
                                                                                    id="button-{{facets.code}}"
                                                                                    class="show-button desktop"
                                                                                    (click)="clickMoreLessDesktop(facets.code)">
                                                                                    {{this.moreLessIndicator.includes(facets.code+"close")
                                                                                    ? "Show Less" : "Show More" }}
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="facets.fancyFacetType =='SLIDER'"><!-- slider -->
                                                                    <ng-container
                                                                        *ngFor="let facetValues of  facets.values; let i = index">
                                                                        <ng-container *ngIf="i == 0">
                                                                            <div id="sliderContainer-{{facets.code}}"
                                                                                class="sliderContainer warning active">
                                                                                <span
                                                                                    class="physicalChemical-subtitle">{{facets.name}}
                                                                                </span>
                                                                                <div class="filter-slider">
                                                                                    <div class="filter-slider-content">
                                                                                        <div class="slider-content">
                                                                                            <h4 class="input-text">min
                                                                                                {{facets.name}}</h4>
                                                                                            <div class="min-value">
                                                                                                <input
                                                                                                    id="physicalSliderInput-{{facets.code}}"
                                                                                                    class="slider-input"
                                                                                                    type="text"
                                                                                                    (focusout)="enterMinValues(facetList.currentQuery.query.value,$event,facets.minSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)"
                                                                                                    (keydown.enter)="enterMinValues(facetList.currentQuery.query.value,$event,facets.minSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)"
                                                                                                    placeholder="{{decimalNumber(facets.minSelectSliderValue)}}" />
                                                                                                <div
                                                                                                    class="button-container">
                                                                                                    <button
                                                                                                        routerLink="./"
                                                                                                        [queryParams]="getMinMinLinkParams(facetValues,facets.minSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)"
                                                                                                        class="decrease-button"
                                                                                                        id="range-{{facets.code}}"
                                                                                                        [class.selected]="facetValues.selected"
                                                                                                        [cxFocus]="facetValues.name"
                                                                                                        (keydown.space)="openLink($event)"
                                                                                                        (click)="updateMinValue(facets.minSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)">
                                                                                                        <img
                                                                                                            src="/assets/icons/add_minus_single_grey.svg" />
                                                                                                    </button>
                                                                                                    <button #facetValue
                                                                                                        routerLink="./"
                                                                                                        [queryParams]="getMinMaxLinkParams(facetValues,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)"
                                                                                                        class="increase-button"
                                                                                                        id="range-{{facets.code}}"
                                                                                                        [class.selected]="facetValues.selected"
                                                                                                        [cxFocus]="facetValues.name"
                                                                                                        (keydown.space)="openLink($event)">
                                                                                                        <img
                                                                                                            src="/assets/icons/add_plus_single_grey.svg" />
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="slider-content">
                                                                                            <h4 class="input-text">max
                                                                                                {{facets.name}}</h4>
                                                                                            <div class="max-value">
                                                                                                <input
                                                                                                    id="sliderInput-{{facets.code}}"
                                                                                                    class="slider-input"
                                                                                                    type="text"
                                                                                                    min="{{decimalNumber(facets.minSliderValue)}}"
                                                                                                    max="{{decimalNumber(facets.maxSliderValue)}}"
                                                                                                    placeholder="{{decimalNumber(facets.maxSelectSliderValue)}}"
                                                                                                    (focusout)="enterMaxValues(facetList.currentQuery.query.value,$event,facets.maxSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)"
                                                                                                    (keydown.enter)="enterMinValues(facetList.currentQuery.query.value,$event,facets.minSliderValue,facets.minSelectSliderValue,facets.maxSelectSliderValue,facets.code)" />
                                                                                                <div
                                                                                                    class="button-container">
                                                                                                    <button
                                                                                                        routerLink="./"
                                                                                                        [queryParams]="getMaxMinLinkParams(facetValues,facets.maxSelectSliderValue,facets.minSelectSliderValue,facets.code)"
                                                                                                        class="decrease-button"
                                                                                                        id="range-{{facets.code}}"
                                                                                                        [class.selected]="facetValues.selected"
                                                                                                        [cxFocus]="facetValues.name"
                                                                                                        (keydown.space)="openLink($event)">
                                                                                                        <img
                                                                                                            src="/assets/icons/add_minus_single_grey.svg" />
                                                                                                    </button>
                                                                                                    <button
                                                                                                        routerLink="./"
                                                                                                        [queryParams]="getMaxMaxLinkParams(facetValues,facets.maxSliderValue,facets.maxSelectSliderValue,facets.minSelectSliderValue,facets.code)"
                                                                                                        class="increase-button"
                                                                                                        id="range-{{facets.code}}"
                                                                                                        [class.selected]="facetValues.selected"
                                                                                                        [cxFocus]="facetValues.name"
                                                                                                        (keydown.space)="openLink($event)"
                                                                                                        (click)="updateMaxValue(facets.maxSliderValue,facets.maxSelectSliderValue,facets.minSelectSliderValue,facets.code)">
                                                                                                        <img
                                                                                                            src="/assets/icons/add_plus_single_grey.svg" />
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <span
                                                                                        id="minWarning-{{facets.minSliderValue}}"
                                                                                        class="min-warning">The minimum
                                                                                        value is
                                                                                        {{facets.minSliderValue}}. We
                                                                                        have automatically reset your
                                                                                        input to the minimum
                                                                                        value.</span>
                                                                                    <span
                                                                                        id="maxWarning-{{facets.maxSliderValue}}"
                                                                                        class="max-warning">The maximum
                                                                                        value is
                                                                                        {{facets.maxSliderValue}}. We
                                                                                        have automatically reset your
                                                                                        input to the maximum
                                                                                        value.</span>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="facets.fancyFacetType ==undefined || facets.fancyFacetType =='NOTFANCY'"><!--checkbox-->
                                                                    <div class="checkbox-container group">
                                                                        <ng-container
                                                                            *ngFor="let facetValues of facets.values">
                                                                            <label #facetValue routerLink="./"
                                                                                [queryParams]="getLinkParams(facetValues)"
                                                                                class="value"
                                                                                [class.selected]="facetValues.selected"
                                                                                [cxFocus]="facetValues.name"
                                                                                (click)="getTabChange(facets.code,facetValues.name,facetValues.selected)"
                                                                                (keydown.space)="openLink($event)">
                                                                                <ng-container
                                                                                    *ngIf="facetValues.selected == true">
                                                                                    <input type="checkbox"
                                                                                        id="{{facetValues.name}}">
                                                                                    <span class="checkmark filters"
                                                                                        checked="checked"></span>
                                                                                </ng-container>
                                                                                <ng-container
                                                                                    *ngIf="facetValues.selected == false">
                                                                                    <input type="checkbox"
                                                                                        id="{{facetValues.name}}">
                                                                                    <span class="checkmark"></span>
                                                                                </ng-container>
                                                                                <span class="attribute-info">
                                                                                    <span class="label">{{
                                                                                        facetValues.name }}</span>
                                                                                    <span class="count"> ({{
                                                                                        facetValues.count }})</span>
                                                                                </span>
                                                                            </label>
                                                                        </ng-container>
                                                                    </div>
                                                                </ng-container>

                                                            </div>
                                                            <div class="sticky-footer">
                                                                <ng-container
                                                                    *ngIf="activeFacetList$ | async as activeFacetList">
                                                                    <ng-container
                                                                        *ngIf="activeFacetList.activeFacets.length == 0">
                                                                        <div class="reset-button-container disabled">
                                                                            <a class="footer-resetLink disabled"></a>
                                                                        </div>
                                                                        <button type="cancel" class="cancelBtn"
                                                                            (click)="closeDropdown(facets.code)">
                                                                            {{cancelButton}}
                                                                        </button>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="activeFacetList.activeFacets.length > 0">
                                                                        <div class="reset-button-container">
                                                                            <img src="/assets/icons/reload.svg">
                                                                            <a class="footer-resetLink"
                                                                                (click)="resetCustomFacetFilter(facets.code)">{{resetButton}}</a>
                                                                        </div>
                                                                        <div class="button-container">
                                                                            <button type="cancel" class="cancelBtn"
                                                                                (click)="closeDropdown(facets.code)">
                                                                                {{cancelButton}}
                                                                            </button>
                                                                        </div>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </div>
                                                        </ul>
                                                    </section>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div id="resetButton" class="button-container">
                            <img src="/assets/icons/reload.svg">
                            <a class="footer-resetLink" (click)="resetAllFilter()">{{resetAllButton}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="inner">
                <div class="seperation-section">
                    <ng-container *ngIf="facetList$ | async as facetList">
                        <ng-container *ngIf="activeFacetList$ | async as activeFacetList">
                            <div class="tabs-content">
                                <div class="content-area">
                                    <ng-container *ngIf="productList$ | async as productList">
                                        <ng-container
                                            *ngIf="productList.products.length == 0 || productList.facets.length == 0  ">
                                            <div class="no-result">
                                                <div class="no-result-items">
                                                    <div class="no-items-content">
                                                        <img src="assets/icons/quick-briefing.svg"
                                                            class="quick-briefing-icon">
                                                        <h1>We are sorry,</h1>
                                                        <p>There are no results according to your filters. Please
                                                            reset/change your filters or contact us and we will find a
                                                            solution for your needs.</p>
                                                        <button class="reset-start-over"
                                                            (click)="resetAndStartOver()">Reset and start over</button>
                                                        <div class="no-items-buttons">
                                                            <button class="create-new-projects"
                                                                (click)="toggleStartANewProject()">Start a new
                                                                project</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="productList.products.length > 0 &&  productList.facets.length > 0 ">
                                            <div class="tabs-content outer">
                                                <div class="total-number">
                                                    <span class="items-number">Items:</span>
                                                    <span>{{facetList.pagination.totalResults}}</span>
                                                </div>
                                                <!--Sorting alanı-->
                                                <div class="sortDropdown">
                                                    <h4>Sort by:</h4>
                                                    <div class="select-container">
                                                        <ng-container id="sortItems"
                                                            *ngIf="facetList$ | async as facetList">
                                                            <cx-sorting [sortOptions]="facetList.sorts"
                                                                (sortListEvent)="sortList($event)"
                                                                [selectedOption]="facetList.pagination.sort">
                                                            </cx-sorting>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <ng-container *ngFor="let products of  productList.products">
                                                    <ng-container *ngIf="products && products.doehlerCatalogConfigData">
                                                        <ng-container
                                                            *ngIf="products.doehlerCatalogConfigData.filterFacetDCatalog && products.doehlerCatalogConfigData.filterFacetDCatalog.length > 0
                                                     && products.doehlerCatalogConfigData.filterFacetDCatalog[0] != undefined">
                                                            <ng-container *ngIf="colorName == undefined">
                                                                <ng-container
                                                                    *ngIf="products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.code != 'RMW61ZF01'">
                                                                    <div id="itemBox-{{products.code}}"
                                                                        class="item-box">
                                                                        <div class="border" style="display: none"></div>
                                                                        <div class="divide-content">
                                                                            <div class="details">
                                                                                <div class="detail-content">
                                                                                    <div class="product-id">
                                                                                        {{products.code}}</div>
                                                                                    <ng-container
                                                                                        *ngIf="products.isFavourite == false">
                                                                                        <button class="addToFav fav"
                                                                                            id="addToFav-{{products.code}}"
                                                                                            type="button"
                                                                                            (click)="changeFavourite(products.code,products.name)">
                                                                                            <div class="favorite-img">
                                                                                            </div>
                                                                                        </button>
                                                                                    </ng-container>
                                                                                    <ng-container
                                                                                        *ngIf="products.isFavourite == true">
                                                                                        <button
                                                                                            class="addToFav addedToFavorite"
                                                                                            id="addToFav-{{products.code}}"
                                                                                            type="button"
                                                                                            (click)="changeFavourite(products.code,products.name)">
                                                                                            <div class="favorite-img">
                                                                                            </div>
                                                                                        </button>
                                                                                    </ng-container>
                                                                                </div>
                                                                            </div>
                                                                            <a [href]="getCustomProductDetailUrl(products.code,products.name)"
                                                                                style="text-decoration: none;">
                                                                                <h3>{{products.name}}</h3>
                                                                                <div class="attribute-list">
                                                                                    <ul>
                                                                                        <ng-container
                                                                                            *ngFor="let productHighlightDCatalog of products.doehlerCatalogConfigData.productHighlightDCatalog; let i=index">
                                                                                            <li class="attribute-name"
                                                                                                *ngIf="i<4">
                                                                                                <div
                                                                                                    class="attribute-category-details">
                                                                                                    {{productHighlightDCatalog.featureData.name}}
                                                                                                </div>
                                                                                                <div
                                                                                                    class="attribute-details">
                                                                                                    <ng-container
                                                                                                        *ngFor="let featureValues of productHighlightDCatalog.featureData.featureValues">
                                                                                                        <ng-container
                                                                                                            *ngIf="featureValues.value.indexOf(' to ') > -1">
                                                                                                            <span>{{featureValues.value.replace('to',
                                                                                                                '&mdash;').replace('from',
                                                                                                                '')}}</span>
                                                                                                        </ng-container>
                                                                                                        <ng-container
                                                                                                            *ngIf="featureValues.value.indexOf(' to ') == -1">
                                                                                                            <span>{{featureValues.value}}</span>
                                                                                                        </ng-container>
                                                                                                    </ng-container>
                                                                                                </div>
                                                                                            </li>
                                                                                        </ng-container>
                                                                                    </ul>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.code == 'RMW61ZF01'">
                                                                    <ng-container
                                                                        *ngIf="products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'red-blue' &&
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'red' &&
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'pink' &&
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'yellow-orange' &&
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'yellow' &&
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'blue' &&
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'green' &&
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'black' &&
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'brown' &&
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'white' &&
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'violet' &&
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'orange'">
                                                                        <div id="itemBox-{{products.code}}"
                                                                            class="item-box">
                                                                            <div class="border" style="display: none">
                                                                            </div>
                                                                            <div class="divide-content">
                                                                                <div class="details">
                                                                                    <div class="detail-content">
                                                                                        <div class="product-id">
                                                                                            {{products.code}}</div>
                                                                                        <ng-container
                                                                                            *ngIf="products.isFavourite == false">
                                                                                            <button class="addToFav fav"
                                                                                                id="addToFav-{{products.code}}"
                                                                                                type="button"
                                                                                                (click)="changeFavourite(products.code,products.name)">
                                                                                                <div
                                                                                                    class="favorite-img">
                                                                                                </div>
                                                                                            </button>
                                                                                        </ng-container>
                                                                                        <ng-container
                                                                                            *ngIf="products.isFavourite == true">
                                                                                            <button
                                                                                                class="addToFav addedToFavorite"
                                                                                                id="addToFav-{{products.code}}"
                                                                                                type="button"
                                                                                                (click)="changeFavourite(products.code,products.name)">
                                                                                                <div
                                                                                                    class="favorite-img">
                                                                                                </div>
                                                                                            </button>
                                                                                        </ng-container>
                                                                                    </div>
                                                                                </div>
                                                                                <a [href]="getCustomProductDetailUrl(products.code,products.name)"
                                                                                    style="text-decoration: none;">
                                                                                    <h3>{{products.name}}</h3>
                                                                                    <div class="attribute-list">
                                                                                        <ul>
                                                                                            <ng-container
                                                                                                *ngFor="let productHighlightDCatalog of products.doehlerCatalogConfigData.productHighlightDCatalog; let i=index">
                                                                                                <li class="attribute-name"
                                                                                                    *ngIf="i<4">
                                                                                                    <div
                                                                                                        class="attribute-category-details">
                                                                                                        {{productHighlightDCatalog.featureData.name}}
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="attribute-details">
                                                                                                        <ng-container
                                                                                                            *ngFor="let featureValues of productHighlightDCatalog.featureData.featureValues">
                                                                                                            <ng-container
                                                                                                                *ngIf="featureValues.value.indexOf(' to ') > -1">
                                                                                                                <span>{{featureValues.value.replace('to',
                                                                                                                    '&mdash;').replace('from',
                                                                                                                    '')}}</span>
                                                                                                            </ng-container>
                                                                                                            <ng-container
                                                                                                                *ngIf="featureValues.value.indexOf(' to ') == -1">
                                                                                                                <span>{{featureValues.value}}</span>
                                                                                                            </ng-container>
                                                                                                        </ng-container>
                                                                                                    </div>
                                                                                                </li>
                                                                                            </ng-container>
                                                                                        </ul>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'red-blue' ||
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'red' ||
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'pink' ||
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'yellow-orange' ||
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'yellow' ||
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'blue' ||
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'green' ||
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'black' ||
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'brown' ||
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'white' ||
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'violet' ||
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'orange'">
                                                                        <div id="itemBox-{{products.code}}"
                                                                            class="item-box">
                                                                            <div class="border"
                                                                                [ngStyle]="setBackgroundColour(products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value)">
                                                                            </div>
                                                                            <div class="divide-content">
                                                                                <div class="details">
                                                                                    <div class="detail-content">
                                                                                        <div class="product-id">
                                                                                            {{products.code}}</div>
                                                                                        <ng-container
                                                                                            *ngIf="products.isFavourite == false">
                                                                                            <button class="addToFav fav"
                                                                                                id="addToFav-{{products.code}}"
                                                                                                type="button"
                                                                                                (click)="changeFavourite(products.code,products.name)">
                                                                                                <div
                                                                                                    class="favorite-img">
                                                                                                </div>
                                                                                            </button>
                                                                                        </ng-container>
                                                                                        <ng-container
                                                                                            *ngIf="products.isFavourite == true">
                                                                                            <button
                                                                                                class="addToFav addedToFavorite"
                                                                                                id="addToFav-{{products.code}}"
                                                                                                type="button"
                                                                                                (click)="changeFavourite(products.code,products.name)">
                                                                                                <div
                                                                                                    class="favorite-img">
                                                                                                </div>
                                                                                            </button>
                                                                                        </ng-container>
                                                                                    </div>
                                                                                </div>
                                                                                <a [href]="getCustomProductDetailUrl(products.code,products.name)"
                                                                                    style="text-decoration: none;">
                                                                                    <h3>{{products.name}}</h3>
                                                                                    <div class="attribute-list">
                                                                                        <ul>
                                                                                            <ng-container
                                                                                                *ngFor="let productHighlightDCatalog of products.doehlerCatalogConfigData.productHighlightDCatalog; let i=index">
                                                                                                <li class="attribute-name"
                                                                                                    *ngIf="i<4">
                                                                                                    <div
                                                                                                        class="attribute-category-details">
                                                                                                        {{productHighlightDCatalog.featureData.name}}
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="attribute-details">
                                                                                                        <ng-container
                                                                                                            *ngFor="let featureValues of productHighlightDCatalog.featureData.featureValues">
                                                                                                            <ng-container
                                                                                                                *ngIf="featureValues.value.indexOf(' to ') > -1">
                                                                                                                <span>{{featureValues.value.replace('to',
                                                                                                                    '&mdash;').replace('from',
                                                                                                                    '')}}</span>
                                                                                                            </ng-container>
                                                                                                            <ng-container
                                                                                                                *ngIf="featureValues.value.indexOf(' to ') == -1">
                                                                                                                <span>{{featureValues.value}}</span>
                                                                                                            </ng-container>
                                                                                                        </ng-container>
                                                                                                    </div>
                                                                                                </li>
                                                                                            </ng-container>
                                                                                        </ul>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </ng-container>
                                                            <ng-container *ngIf="colorName != undefined">
                                                                <ng-container
                                                                    *ngIf="activeFacetList.activeFacets.length == 0">
                                                                    <ng-container
                                                                        *ngIf="products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.code != 'RMW61ZF01'">
                                                                        <div id="itemBox-{{products.code}}"
                                                                            class="item-box">
                                                                            <div class="border" style="display: none">
                                                                            </div>
                                                                            <div class="divide-content">
                                                                                <div class="details">
                                                                                    <div class="detail-content">
                                                                                        <div class="product-id">
                                                                                            {{products.code}}</div>
                                                                                        <ng-container
                                                                                            *ngIf="products.isFavourite == false">
                                                                                            <button class="addToFav fav"
                                                                                                id="addToFav-{{products.code}}"
                                                                                                type="button"
                                                                                                (click)="changeFavourite(products.code,products.name)">
                                                                                                <div
                                                                                                    class="favorite-img">
                                                                                                </div>
                                                                                            </button>
                                                                                        </ng-container>
                                                                                        <ng-container
                                                                                            *ngIf="products.isFavourite == true">
                                                                                            <button
                                                                                                class="addToFav addedToFavorite"
                                                                                                id="addToFav-{{products.code}}"
                                                                                                type="button"
                                                                                                (click)="changeFavourite(products.code,products.name)">
                                                                                                <div
                                                                                                    class="favorite-img">
                                                                                                </div>
                                                                                            </button>
                                                                                        </ng-container>
                                                                                    </div>
                                                                                </div>
                                                                                <a [href]="getCustomProductDetailUrl(products.code,products.name)"
                                                                                    style="text-decoration: none;">
                                                                                    <h3>{{products.name}}</h3>
                                                                                    <div class="attribute-list">
                                                                                        <ul>
                                                                                            <ng-container
                                                                                                *ngFor="let productHighlightDCatalog of products.doehlerCatalogConfigData.productHighlightDCatalog; let i=index">
                                                                                                <li class="attribute-name"
                                                                                                    *ngIf="i<4">
                                                                                                    <div
                                                                                                        class="attribute-category-details">
                                                                                                        {{productHighlightDCatalog.featureData.name}}
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="attribute-details">
                                                                                                        <ng-container
                                                                                                            *ngFor="let featureValues of productHighlightDCatalog.featureData.featureValues">
                                                                                                            <ng-container
                                                                                                                *ngIf="featureValues.value.indexOf(' to ') > -1">
                                                                                                                <span>{{featureValues.value.replace('to',
                                                                                                                    '&mdash;').replace('from',
                                                                                                                    '')}}</span>
                                                                                                            </ng-container>
                                                                                                            <ng-container
                                                                                                                *ngIf="featureValues.value.indexOf(' to ') == -1">
                                                                                                                <span>{{featureValues.value}}</span>
                                                                                                            </ng-container>
                                                                                                        </ng-container>
                                                                                                    </div>
                                                                                                </li>
                                                                                            </ng-container>
                                                                                        </ul>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.code == 'RMW61ZF01'">
                                                                        <ng-container
                                                                            *ngIf="products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'red-blue' &&
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'red' &&
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'pink' &&
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'yellow-orange' &&
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'yellow' &&
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'blue' &&
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'green' &&
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'black' &&
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'brown' &&
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'white' &&
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'violet' &&
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'orange'">
                                                                            <div id="itemBox-{{products.code}}"
                                                                                class="item-box">
                                                                                <div class="border"
                                                                                    style="display: none"></div>
                                                                                <div class="divide-content">
                                                                                    <div class="details">
                                                                                        <div class="detail-content">
                                                                                            <div class="product-id">
                                                                                                {{products.code}}</div>
                                                                                            <ng-container
                                                                                                *ngIf="products.isFavourite == false">
                                                                                                <button
                                                                                                    class="addToFav fav"
                                                                                                    id="addToFav-{{products.code}}"
                                                                                                    type="button"
                                                                                                    (click)="changeFavourite(products.code,products.name)">
                                                                                                    <div
                                                                                                        class="favorite-img">
                                                                                                    </div>
                                                                                                </button>
                                                                                            </ng-container>
                                                                                            <ng-container
                                                                                                *ngIf="products.isFavourite == true">
                                                                                                <button
                                                                                                    class="addToFav addedToFavorite"
                                                                                                    id="addToFav-{{products.code}}"
                                                                                                    type="button"
                                                                                                    (click)="changeFavourite(products.code,products.name)">
                                                                                                    <div
                                                                                                        class="favorite-img">
                                                                                                    </div>
                                                                                                </button>
                                                                                            </ng-container>
                                                                                        </div>
                                                                                    </div>
                                                                                    <a [href]="getCustomProductDetailUrl(products.code,products.name)"
                                                                                        style="text-decoration: none;">
                                                                                        <h3>{{products.name}}</h3>
                                                                                        <div class="attribute-list">
                                                                                            <ul>
                                                                                                <ng-container
                                                                                                    *ngFor="let productHighlightDCatalog of products.doehlerCatalogConfigData.productHighlightDCatalog; let i=index">
                                                                                                    <li class="attribute-name"
                                                                                                        *ngIf="i<4">
                                                                                                        <div
                                                                                                            class="attribute-category-details">
                                                                                                            {{productHighlightDCatalog.featureData.name}}
                                                                                                        </div>
                                                                                                        <div
                                                                                                            class="attribute-details">
                                                                                                            <ng-container
                                                                                                                *ngFor="let featureValues of productHighlightDCatalog.featureData.featureValues">
                                                                                                                <ng-container
                                                                                                                    *ngIf="featureValues.value.indexOf(' to ') > -1">
                                                                                                                    <span>{{featureValues.value.replace('to',
                                                                                                                        '&mdash;').replace('from',
                                                                                                                        '')}}</span>
                                                                                                                </ng-container>
                                                                                                                <ng-container
                                                                                                                    *ngIf="featureValues.value.indexOf(' to ') == -1">
                                                                                                                    <span>{{featureValues.value}}</span>
                                                                                                                </ng-container>
                                                                                                            </ng-container>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                </ng-container>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'red-blue' ||
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'red' ||
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'pink' ||
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'yellow-orange' ||
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'yellow' ||
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'blue' ||
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'green' ||
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'black' ||
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'brown' ||
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'white' ||
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'violet' ||
                                                                    products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'orange'">
                                                                            <div id="itemBox-{{products.code}}"
                                                                                class="item-box">
                                                                                <div class="border"
                                                                                    [ngStyle]="setBackgroundColour(products.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value)">
                                                                                </div>
                                                                                <div class="divide-content">
                                                                                    <div class="details">
                                                                                        <div class="detail-content">
                                                                                            <div class="product-id">
                                                                                                {{products.code}}</div>
                                                                                            <ng-container
                                                                                                *ngIf="products.isFavourite == false">
                                                                                                <button
                                                                                                    class="addToFav fav"
                                                                                                    id="addToFav-{{products.code}}"
                                                                                                    type="button"
                                                                                                    (click)="changeFavourite(products.code,products.name)">
                                                                                                    <div
                                                                                                        class="favorite-img">
                                                                                                    </div>
                                                                                                </button>
                                                                                            </ng-container>
                                                                                            <ng-container
                                                                                                *ngIf="products.isFavourite == true">
                                                                                                <button
                                                                                                    class="addToFav addedToFavorite"
                                                                                                    id="addToFav-{{products.code}}"
                                                                                                    type="button"
                                                                                                    (click)="changeFavourite(products.code,products.name)">
                                                                                                    <div
                                                                                                        class="favorite-img">
                                                                                                    </div>
                                                                                                </button>
                                                                                            </ng-container>
                                                                                        </div>
                                                                                    </div>
                                                                                    <a [href]="getCustomProductDetailUrl(products.code,products.name)"
                                                                                        style="text-decoration: none;">
                                                                                        <h3>{{products.name}}</h3>
                                                                                        <div class="attribute-list">
                                                                                            <ul>
                                                                                                <ng-container
                                                                                                    *ngFor="let productHighlightDCatalog of products.doehlerCatalogConfigData.productHighlightDCatalog; let i=index">
                                                                                                    <li class="attribute-name"
                                                                                                        *ngIf="i<4">
                                                                                                        <div
                                                                                                            class="attribute-category-details">
                                                                                                            {{productHighlightDCatalog.featureData.name}}
                                                                                                        </div>
                                                                                                        <div
                                                                                                            class="attribute-details">
                                                                                                            <ng-container
                                                                                                                *ngFor="let featureValues of productHighlightDCatalog.featureData.featureValues">
                                                                                                                <ng-container
                                                                                                                    *ngIf="featureValues.value.indexOf(' to ') > -1">
                                                                                                                    <span>{{featureValues.value.replace('to',
                                                                                                                        '&mdash;').replace('from',
                                                                                                                        '')}}</span>
                                                                                                                </ng-container>
                                                                                                                <ng-container
                                                                                                                    *ngIf="featureValues.value.indexOf(' to ') == -1">
                                                                                                                    <span>{{featureValues.value}}</span>
                                                                                                                </ng-container>
                                                                                                            </ng-container>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                </ng-container>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="activeFacetList.activeFacets.length != 0">
                                                                    <div id="itemBox-{{products.code}}" class="item-box"
                                                                        style="border-left: .625rem solid {{colorName}};">
                                                                        <div class="details">
                                                                            <div class="detail-content">
                                                                                <div class="product-id">
                                                                                    {{products.code}}</div>
                                                                                <ng-container
                                                                                    *ngIf="products.isFavourite == false">
                                                                                    <button class="addToFav fav"
                                                                                        id="addToFav-{{products.code}}"
                                                                                        type="button"
                                                                                        (click)="changeFavourite(products.code,products.name)">
                                                                                        <div class="favorite-img"></div>
                                                                                    </button>
                                                                                </ng-container>
                                                                                <ng-container
                                                                                    *ngIf="products.isFavourite == true">
                                                                                    <button
                                                                                        class="addToFav addedToFavorite"
                                                                                        id="addToFav-{{products.code}}"
                                                                                        type="button"
                                                                                        (click)="changeFavourite(products.code,products.name)">
                                                                                        <div class="favorite-img"></div>
                                                                                    </button>
                                                                                </ng-container>
                                                                            </div>
                                                                        </div>
                                                                        <a [href]="getCustomProductDetailUrl(products.code,products.name)"
                                                                            style="text-decoration: none;">
                                                                            <h3>{{products.name}}</h3>
                                                                            <div class="attribute-list">
                                                                                <ul>
                                                                                    <ng-container
                                                                                        *ngFor="let productHighlightDCatalog of products.doehlerCatalogConfigData.productHighlightDCatalog; let i=index">
                                                                                        <li class="attribute-name"
                                                                                            *ngIf="i<4">
                                                                                            <div
                                                                                                class="attribute-category-details">
                                                                                                {{productHighlightDCatalog.featureData.name}}
                                                                                            </div>
                                                                                            <div
                                                                                                class="attribute-details">
                                                                                                <ng-container
                                                                                                    *ngFor="let featureValues of productHighlightDCatalog.featureData.featureValues">
                                                                                                    <ng-container
                                                                                                        *ngIf="featureValues.value.indexOf(' to ') > -1">
                                                                                                        <span>{{featureValues.value.replace('to',
                                                                                                            '&mdash;').replace('from',
                                                                                                            '')}}</span>
                                                                                                    </ng-container>

                                                                                                    <ng-container
                                                                                                        *ngIf="featureValues.value.indexOf(' to ') == -1">
                                                                                                        <span>{{featureValues.value}}</span>
                                                                                                    </ng-container>
                                                                                                </ng-container>
                                                                                            </div>
                                                                                        </li>
                                                                                    </ng-container>
                                                                                </ul>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="products.doehlerCatalogConfigData.filterFacetDCatalog && products.doehlerCatalogConfigData.filterFacetDCatalog.length == 0">
                                                            <div id="itemBox-{{products.code}}" class="item-box">
                                                                <div class="border" style="display: none"></div>
                                                                <div class="divide-content">
                                                                    <div class="details">
                                                                        <div class="detail-content">
                                                                            <div class="product-id">{{products.code}}
                                                                            </div>
                                                                            <ng-container
                                                                                *ngIf="products.isFavourite == false">
                                                                                <button class="addToFav fav"
                                                                                    id="addToFav-{{products.code}}"
                                                                                    type="button"
                                                                                    (click)="changeFavourite(products.code,products.name)">
                                                                                    <div class="favorite-img"></div>
                                                                                </button>
                                                                            </ng-container>
                                                                            <ng-container
                                                                                *ngIf="products.isFavourite == true">
                                                                                <button class="addToFav addedToFavorite"
                                                                                    id="addToFav-{{products.code}}"
                                                                                    type="button"
                                                                                    (click)="changeFavourite(products.code,products.name)">
                                                                                    <div class="favorite-img"></div>
                                                                                </button>
                                                                            </ng-container>
                                                                        </div>
                                                                    </div>
                                                                    <a [href]="getCustomProductDetailUrl(products.code,products.name)"
                                                                        style="text-decoration: none;">
                                                                        <h3>{{products.name}}</h3>
                                                                        <div class="attribute-list">
                                                                            <ul>
                                                                                <ng-container
                                                                                    *ngFor="let productHighlightDCatalog of products.doehlerCatalogConfigData.productHighlightDCatalog; let i=index">
                                                                                    <li class="attribute-name"
                                                                                        *ngIf="i<4">
                                                                                        <div
                                                                                            class="attribute-category-details">
                                                                                            {{productHighlightDCatalog.featureData.name}}
                                                                                        </div>
                                                                                        <div class="attribute-details">
                                                                                            <ng-container
                                                                                                *ngFor="let featureValues of productHighlightDCatalog.featureData.featureValues">
                                                                                                <ng-container
                                                                                                    *ngIf="featureValues.value.indexOf(' to ') > -1">
                                                                                                    <span>{{featureValues.value.replace('to',
                                                                                                        '&mdash;').replace('from',
                                                                                                        '')}}</span>
                                                                                                </ng-container>
                                                                                                <ng-container
                                                                                                    *ngIf="featureValues.value.indexOf(' to ') == -1">
                                                                                                    <span>{{featureValues.value}}</span>
                                                                                                </ng-container>
                                                                                            </ng-container>
                                                                                        </div>
                                                                                    </li>
                                                                                </ng-container>
                                                                            </ul>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <!--Pagination alanı-->
                    <ng-container id="sortItems" *ngIf="facetList$ | async as facetList">
                        <div class="slp-pagination col-auto d-flex justify-content-center py-3"
                            aria-label="product search pagination">
                            <ng-container *ngIf="facetList.pagination.totalPages >= 2">
                                <div class="cx-pagination">
                                    <cx-pagination id="paginationScroll" class="slp-cx-pagination"
                                        [pagination]="facetList.pagination" queryParam="currentPage" [defaultPage]="0"
                                        (click)="onScroll()"></cx-pagination>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </div>
        </section>
    </div>

</div>
<jw-modal id="addToFavorite" class="wideModal">
    <div class="jw-modal-close-icon">
        <button type="cancel" class="close-favorite" (click)="closeModal('addToFavorite')"></button>
    </div>
    <div class="jw-modal-inner" id="plpModal">
        <h3 class="move-to-collection">Add to collection</h3>
        <p class="please-select-the-collection">Add the product to a collection to organize your favourite products.</p>
        <form [formGroup]="saveToCollection" id="saveWishlistForm">
            <div id="plpAddToCollection">
                <ng-container *ngIf="doehlerWishListList$ | async as wishlists">
                    <ng-container *ngIf="wishlists.values.length == 0">
                        <div class="form-group">
                            <label class="radio-inline">
                                <input class="form-control" type="radio" name="wishlistCodePost"
                                    value="General Collection" [checked]="" />
                                <div class="collection-info">
                                    <span class="collection">General Collection</span>
                                    <div class="last-update">
                                        <div class="last-changed">Last change:
                                            <span>0</span>
                                        </div>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="wishlists.values.length > 0">
                        <ng-container *ngFor="let item of wishlists.values">
                            <div class="form-group">
                                <label class="radio-inline">
                                    <input class="form-control wishlist-class" type="radio" name="wishlistCodePost"
                                        value="{{item.pk}}" [checked]="item.name === 'General Collection'" />
                                    <div class="collection-info">
                                        <span class="collection">{{item.name}}</span>
                                        <div class="last-update">
                                            <div class="last-changed">Last change:
                                                <span>{{item.modifiedDate}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
            <div class="buttonRow favoriteButtons-desktop">
                <button type="button" class="create" (click)="createFavorite('requestCreateFavorite')">
                    <div class="create-img"></div><span class="button-name">Create a collection</span>
                </button>
            </div>
            <div class="buttonRow desktop-favoriteButtons">
                <div class="button-container">
                    <button type="cancel" class="cancel" (click)="closeModal('addToFavorite')">{{cancelButton}}</button>
                    <button (click)="favoriteSubmitForm()" class="submitButton">{{addButton}}</button>
                </div>
            </div>
            <div class="buttonRow mobile-favoriteButtons">
                <div class="button-container">
                    <button (click)="favoriteSubmitForm()" class="submitButton">{{addButton}}</button>
                    <button type="cancel" class="cancel" (click)="closeModal('addToFavorite')">{{cancelButton}}</button>
                </div>
            </div>
        </form>
    </div>
</jw-modal>
<jw-modal id="requestCreateFavorite">
    <div class="jw-modal-close-icon">
        <button type="cancel" class="close-favorite" (click)="closeModal('requestCreateFavorite')"></button>
    </div>
    <div class="jw-modal-inner" id="plpCreateModal">
        <h3>Create a collection</h3>
        <div id="plpCreateCollection">
            <form id="createWishlistForm" autocomplete="off" [formGroup]="createCollectionForm">
                <div class="form-group">
                    <h4>Collection Name</h4>
                    <input type="text" id="saveWishlistName" name="wishlistNamePost" placeholder="New collection name"
                        maxlength="20" class="form-control">
                </div>
                <div class="buttonRow desktop-favoriteButtons">
                    <label id="createErrorDesktop" class="wishlist-label" for="saveWishlistName">
                        <div class="item-image"></div>
                        <p>This collection name already exists.</p>
                    </label>
                    <div class="button-container">
                        <button type="cancel" class="cancel"
                            (click)="closeModal('requestCreateFavorite')">{{cancelButton}}</button>
                        <button (click)="submitCreateForm()" class="submitButton">Save</button>
                    </div>
                </div>
                <div class="buttonRow mobile-favoriteButtons">
                    <label id="createErrorMobile" class="wishlist-label" for="saveWishlistName">
                        <div class="item-image"></div>
                        <p>This collection name already exists.</p>
                    </label>
                    <div class="button-container">
                        <button (click)="submitCreateForm()" class="submitButton">Save</button>
                        <button type="cancel" class="cancel"
                            (click)="closeModal('requestCreateFavorite')">{{cancelButton}}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</jw-modal>
<jw-modal id="requestToAddFavoriteList">
    <div class="jw-modal-close-icon">
        <button type="cancel" class="close-favorite" (click)="closeModal('requestToAddFavoriteList')"></button>
    </div>
    <div class="jw-modal-inner" id="plp-createFavorite">
        <div class="form-content second-modal">
            <div class="alert-wrapper">
                <div class="alert-container">
                    <div class="alert-header">
                        <div class="alert-icon success"></div>
                        <h3>Added to My Favorite Products</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>

<jw-modal id="removeFromFavorite" class="wideModal">
    <div class="jw-modal-close-icon">
        <button type="cancel" class="close-favorite" (click)="closeModal('removeFromFavorite')"></button>
    </div>
    <div class="jw-modal-inner" id="plpModal">
        <h3>Remove From collection</h3>
        <div id="plpRemoveFromFavorite">
            <form [formGroup]="removeFromCollection" id="removeFromWishlistForm">
                <p class="please-select-the-collection">Are you sure you want to remove
                    <span>{{favouriteProductName}}</span> from your list?
                </p>
                <ng-container *ngIf="doehlerWishListList$ | async as wishlists">
                    <ng-container *ngIf="wishlists.values.length == 0">
                        <div class="form-group" style="display: none;">
                            <label class="radio-inline">
                                <input class="form-control" type="radio" name="wishlistCodePost"
                                    value="General Collection" [checked]="" />
                                <span class="collection">General Collection</span>
                            </label>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="wishlists.values.length > 0">
                        <ng-container *ngFor="let item of wishlists.values">
                            <div class="form-group" style="display: none;">
                                <label class="radio-inline">
                                    <input class="form-control wishlist-class" type="radio" name="wishlistCodePost"
                                        value="{{item.pk}}" [checked]="" />
                                    <span class="collection">{{item.name}}</span>
                                    <div class="last-update">
                                        <div class="last-changed">Last change:
                                            <span>{{item.modifiedDate}}</span>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <div class="buttonRow desktop-favoriteButtons">
                    <div class="button-container">
                        <button type="cancel" class="cancel" (click)="closeModal('removeFromFavorite')">Cancel</button>
                        <button (click)="removeFavorite('removeFavorite')" class="submitButton">Apply</button>
                    </div>
                </div>
                <div class="buttonRow mobile-favoriteButtons">
                    <div class="button-container">
                        <button (click)="removeFavorite('removeFavorite')" class="submitButton">Apply</button>
                        <button type="cancel" class="cancel" (click)="closeModal('removeFromFavorite')">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</jw-modal>
<jw-modal id="removeFavorite">
    <div class="jw-modal-close-icon">
        <button type="cancel" class="close-favorite" (click)="closeModal('removeFavorite')"></button>
    </div>
    <div class="jw-modal-inner" id="plp-createFavorite">
        <div class="form-content second-modal">
            <div class="alert-wrapper">
                <div class="alert-container">
                    <div class="alert-header">
                        <div class="alert-icon success"></div>
                        <h3>Removed from My Favorite Products</h3>
                    </div>
                </div>
            </div>
            <form [formGroup]="removeFromCollection" id="removeFromWishlistForm" style="display: none;">
                <div id="plpAddToCollection">
                    <ng-container *ngIf="doehlerWishListList$ | async as wishlists">
                        <ng-container *ngIf="wishlists.values.length == 0">
                            <div class="form-group">
                                <label class="radio-inline">
                                    <input class="form-control" type="radio" name="wishlistCodePost"
                                        value="General Collection" [checked]="" />
                                    <span class="collection">General Collection</span>
                                </label>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="wishlists.values.length > 0">
                            <ng-container *ngFor="let item of wishlists.values">
                                <div class="form-group">
                                    <label class="radio-inline">
                                        <input class="form-control wishlist-class" type="radio" name="wishlistCodePost"
                                            value="{{item.pk}}" [checked]="" />
                                        <span class="collection">{{item.name}}</span>
                                        <div class="last-update">
                                            <div class="last-changed">Last change:
                                                <span>{{item.modifiedDate}}</span>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </form>
        </div>
    </div>
</jw-modal>