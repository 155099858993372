import { formatNumber } from "@angular/common";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  AfterViewInit, Component,
  ElementRef,
  EventEmitter,
  HostBinding, Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faAngleDoubleRight, faArrowLeft, faFilter, faHandHoldingHeart, faHeart, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  AuthService,
  Breadcrumb, EntitiesModel, Facet,
  FacetValue,
  OccConfig, OccEndpointsService, ProductSearchService, RoutingService, UserIdService
} from '@spartacus/core';
import {
  FacetCollapseState, FacetComponent,
  FacetGroupCollapsedState,
  FacetList, FocusConfig,
  ICON_TYPE,
  ViewConfig
} from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { DoehlerWishListSearchConfig } from 'src/app/app.module';
import { DoehlerWishListData, DoehlerWishListService } from 'src/app/doehler-custom-wishlist';
import { DoehlerFunctionUtil } from "src/app/doehler-util/doehler-function-util";
import { ModalService } from 'src/app/modal/modal.service';
// @ts-ignore
import { environment } from 'src/environments/environment';
import { PRODUCTDATA } from '../../doehler-searchPage/static-data/product-data';
import { CustomProductListComponentService } from './CustomProductListComponentService';
import { DoehlerFacetService } from "./DoehlerFacetService";

import {
  DoehlerFacetList,
  DoehlerProductSearchPage
} from './doehler-product-search.model';
import { SearchCriteria } from './product-list.model';
/*import BetterScroll from 'better-scroll';
import BScroll from '@better-scroll/core';*/
@Component({
  selector: 'custom-category-list',
  templateUrl: './custom-category-list.component.html',
  styleUrls: ['./custom-category-list.component.scss']
})
export class CustomCategoryListComponent implements OnInit, AfterViewInit,OnDestroy {
  moreLessIndicator = '';
  protected readonly RELEVANCE_ALLCATEGORIES = ':relevance:allCategories:';
  constructor(
      private fb: UntypedFormBuilder,
      private router: ActivatedRoute,
      private modalService: ModalService,
      protected productListComponentService: CustomProductListComponentService,
      protected facetService: DoehlerFacetService,
      protected productSearchService: ProductSearchService,
      protected elementRef: ElementRef<HTMLElement>,
      public scrollConfig: ViewConfig,// pagination scroll için
      private config: OccConfig,
      protected userIdService: UserIdService,
      protected renderer: Renderer2,
      protected authService:AuthService,
      protected occEndpoints: OccEndpointsService,
      protected doehlerWishListService: DoehlerWishListService,
      protected http: HttpClient,
      protected routing: RoutingService
      ){
      this.checkUserAuth();
  }
  
  private checkUserAuth() {
    const url = new URL(window.location.href);
    localStorage.setItem('next-page-url', url.toString());
    //if (!url.hostname.includes('localhost')) {
    this.userIdService.getUserId().pipe(take(1)).subscribe((userId) => {
      try {
        const exp = localStorage.getItem("spartacus-exp");
        var expnumb: number = +exp;
        if (userId.includes('anonymous') || Date.now() >= expnumb * 1000) {

          localStorage.removeItem('spartacus-accountID');
          localStorage.removeItem('spartacus-customerID');
          localStorage.removeItem('spartacus-exp');
          this.authService.loginWithRedirect();
        } else if (localStorage.getItem("spartacus⚿⚿auth").includes('userId')) {
          localStorage.setItem('userId', userId);
        }
      } catch (ex) {
        console.log("Error " + ex);
      }
    }).unsubscribe();
  }
  /**
   * Indicates that the facet navigation is rendered in dialog.
   */
  @Input()
  set isDialog(value: boolean) {
    this._isDialog = value;
    if (value) {
      this.renderer.addClass(document.body, 'modal-open');
    }
  }
  get isDialog(): boolean {
    return this._isDialog;
  }
  @Input()
  set facet(value: Facet) {
    this._facet = value;
    this.isMultiSelect = !!value.multiSelect;
    this.state$ = this.facetService.getState(value);
  }
  @Input() closeIcon = ICON_TYPE.CLOSE;
  env = environment;
  faFilter = faFilter;
  faAngleDoubleRight = faAngleDoubleRight;
  faTimes = faTimes;
  faArrowLeft = faArrowLeft;
  faHeart = faHeart;
  faHandHoldingHeart = faHandHoldingHeart;
  showCategories: boolean;
  showSolutions: boolean;
  saveToCollection: UntypedFormGroup;
  createCollectionForm: UntypedFormGroup;
  removeFromCollection: UntypedFormGroup;
  productData = PRODUCTDATA;
  groupTabToShow = '';
  tasteTabToShow = '';
  attributeName= '';
  sliderValue= '';
  facetFilterTabChange ='';
  baseUrlMachine = '';
  protected PAGE_SIZE = 100;
  // loadingApplicationFit: boolean = false;
  doehlerWishListList$: Observable<EntitiesModel<DoehlerWishListData>>;
  // Secili olan facet verilerini getiriyor
  activeFacetList$: Observable<DoehlerFacetList> = this.facetService.facetList$;
  productList$: Observable<DoehlerProductSearchPage> = this.productListComponentService.model$;
  // facet bilgilerini getirine /search apisini tetikliyor.
  facetList$: Observable<DoehlerProductSearchPage> = this.productListComponentService.model$;
  state$: Observable<FacetCollapseState>;
  protected _facet: Facet;
  const // @ts-ignore
  isInfiniteScroll: boolean;// pagination scroll için
  colorName: string;
  attributeColor: string
  iconTypes = ICON_TYPE;
  isDisabled: boolean;
  isDisabledFilter: boolean;
  applicationFitUrl = '';
  selectedFilters='';
  applicationFitCount=0;
  selectedFilterCount=0;
  facetListToShow = '';
  facetListDropdown= '';
  @HostBinding('class.multi-select') isMultiSelect: boolean;
  currentValues = [0, 0];
  selectedFacetCountMap : Object;
  regulatoryInfoSelectedCountMap : Object;
  physicalChemicalCharSelectedCountMap: Object;
  regulatoryInfoTitle = 'Regulatory Info';
  physicalChemicalCharacteristicsTitle = 'Physical & Chemical Characteristics';
  colorShadeTitle = 'Colour Shade';
  showResultButton = 'Show Results';
  resetButton = 'Reset';
  resetAllButton = 'Reset All Filters';
  resetAllButtonMobile = 'Reset All';
  cancelButton = 'Cancel';
  addButton = 'Add';
  filterButton = 'All Filters';
  applicationFitButton = 'Application Fit';
  itemsPerPage = 10; // Number of items per page
  currentFacetPage = { }; // Object to keep track of current pages for each facet
  pageSelected = 0;
  baseQuery = '';
  initialCategoryCode='';
  private facetListSubscription: Subscription;
  private facetListSliderSubscription: Subscription;
  
  /** Emits when the list must close */
  @Output() closeList = new EventEmitter();
  private _isDialog: boolean;
  dialogFocusConfig: FocusConfig = {
    trap: true,
    block: true,
    focusOnEscape: true,
    autofocus: 'cx-facet',
  };
  ngOnInit(): void {
    this.checkUserAuth();
    this.baseUrlMachine = this.env.baseUrl
    this.moreLessIndicator = "open";
    this.isInfiniteScroll = this.scrollConfig.view.infiniteScroll.active;// pagination scroll için
    this.removeFromCollection = this.fb.group({
      userId: [localStorage.getItem('userId')],
    })
    this.saveToCollection = this.fb.group({
      userId: [localStorage.getItem('userId')],
    })
    this.createCollectionForm = this.fb.group({
      userId: [localStorage.getItem('userId')],
    })
    
  }
  ngOnDestroy(): void {
    if (this.facetListSubscription) {
      this.facetListSubscription.unsubscribe();
    }
    if (this.facetListSliderSubscription) {
      this.facetListSliderSubscription.unsubscribe();
    }
    
  }
  
  ngAfterViewInit(): void {
 
     this.activeFacetList$.pipe(take(1)).subscribe((facetList) => {
      this.selectedFacetCountMap = DoehlerFunctionUtil.fnFacetSelectedCount(facetList);
     
    });
    this.scrollIntoActiveProductAndCountSliderValues();
    
  }
  scrollIntoActiveProductAndCountSliderValues() {
    let currentCustomQuery='';
    let defaultActiveTabName='';
    let categoryCode='';
    this.facetList$.pipe(take(1)).subscribe(obj => {
      
      this.regulatoryInfoSelectedCountMap = DoehlerFunctionUtil.fnRegulatoryInfoSelectedCount(obj);
      this.physicalChemicalCharSelectedCountMap = DoehlerFunctionUtil.fnPhysicalChemicalCharSelectedCount(obj);
      this.isDisabled = JSON.stringify(obj.categoriesTreeList) == undefined;
      this.isDisabledFilter = obj.facets == undefined;
      const activeFacetList = obj.breadcrumbs;
      defaultActiveTabName = obj.defaultActiveTabName;
      currentCustomQuery = obj.currentQuery?.query?.value;
      categoryCode = obj.categoryCode;
     
      if(activeFacetList.length > 0) {
        this.applicationFitCount=0;
        this.selectedFilterCount=0;
        activeFacetList.forEach(result => {
          this.initialCategoryCode = obj.categoryCode;
          if (result.facetCode.includes('applicationFitCategories')) {
            if (!this.applicationFitUrl.includes(':applicationFitCategories:'+result.facetValueCode)) {
              this.applicationFitUrl = this.applicationFitUrl + ':applicationFitCategories:'+result.facetValueCode;
            }
            this.applicationFitCount = this.applicationFitCount + 1;
          } else {
            this.selectedFilterCount = this.selectedFilterCount + 1;
          }
        })
      }

      const tabNames = this.findTabNamesFromQuery(currentCustomQuery);
    
      let tabQuery = this.combineTabQuery(tabNames,defaultActiveTabName);
      
      this.baseQuery = ":name-asc:allCategories:" +categoryCode+tabQuery;

      this.moreLessIndicator = "close";
      
      DoehlerFunctionUtil.fnScrollIntoActivePoduct(obj.products);
     
    });
  }

  protected findTabNamesFromQuery (currentCustomQuery){

    const tabNames = [];
    
      if (currentCustomQuery){
      
        const queryParams = currentCustomQuery.split(':');

        for (let i = 0; i < queryParams.length; i += 2) {
          const key = queryParams[i];
          const value = queryParams[i + 1];
        
          // Check if the key is "tabName"
          if (key === 'tabName') {
            tabNames.push(value);
          }
        }

      }

      return tabNames;
  }

  protected combineTabQuery (tabNames,defaultActiveTabName){

    let tabQuery = '';
    
      if (tabNames ) {
        for (let i = 0; i < tabNames.length; i++) {
          tabQuery += ":tabName:"+tabNames[i]
        }
      } else {
  
        tabQuery = ":tabName:"+defaultActiveTabName;
      }
      return tabQuery;
  }

  protected fetchDoehlerWishListPopupPage(searchConfig: DoehlerWishListSearchConfig): void {
    const wishlistParams = {customerUserId: '', companyId: '', sort: 'code'};
    searchConfig.customerUserId = localStorage.getItem('userId');
    searchConfig.pageSize = this.PAGE_SIZE;
    searchConfig.companyId = localStorage.getItem('spartacus-accountID');
    wishlistParams.customerUserId = localStorage.getItem('userId');
    wishlistParams.companyId = localStorage.getItem('spartacus-accountID');
    this.doehlerWishListService.loadWhishlistsPopup(searchConfig);
    this.doehlerWishListList$ = this.doehlerWishListService.getPopupList(wishlistParams);
  }
  paginatedFacetValues(facet: any): any[] {
    // Get the current page for the facet or set it to 1 if not present
    if (!this.currentFacetPage[facet.code]) {
        this.currentFacetPage[facet.code] = { page: 1 }; // Initialize with page 1
    }
   

    const currentPage = this.currentFacetPage[facet.code].page || 1;
    // Calculate the start and end indices for the current page
    const startIndex = (currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    // Return the values for the current page
    return facet.values.slice(startIndex, endIndex);
  }
  facetPages(facet: any): number[] {

    
    // Calculate the total number of pages for the facet
    const totalPages = Math.ceil(facet.values.length / this.itemsPerPage);
    // Return an array of page numbers from 1 to totalPages
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }

  paginatedFacetPages(facet: any): number[] {
    // Get the current page for the facet
    const currentPage = this.currentFacetPage[facet.code]?.page || 1;
    const totalPages = Math.ceil(facet.values.length / this.itemsPerPage);

    // Calculate start and end of the visible page range
    const startPage = Math.max(currentPage - 1, 1);
    const endPage = Math.min(startPage + 2, totalPages);

    // Return the array of visible pages
    return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
  }

  goToPage(facet: any, page: number): void {
    // Ensure page stays within bounds
    const totalPages = Math.ceil(facet.values.length / this.itemsPerPage);
    if (page < 1 || page > totalPages) return;

    this.currentFacetPage[facet.code] = { page };

    // Fetch the updated values for the current page
    this.paginatedFacetValues(facet);
  }
  getDeviceTypeR(){
    const ua = navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
      }
      if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
          ua
        )
      ) {
        return "mobile";
      }
      return "desktop";
  }
  sendMessageParentIframe(window, message, url) {
    if ('parentIFrame' in window) {
      const myParentIFrame: any = window["parentIFrame"];
      myParentIFrame.sendMessage(message, url);
    }
  }
  onClickedOutside(e: Event, id) {
    
    const button = document.getElementById(id);
    const filterList = document.getElementById(("facetFiltersList-"+id));
 
    if (button && filterList ) {
      filterList.classList.remove('active');
      filterList.parentElement.classList.remove('active');
      button.classList.remove('active');
      button.parentElement.classList.remove('open');
    }
  }
  openDropdown(id) {
    this.facetListDropdown = id;
    const dropdownMenu = document.getElementById(id);;
    const filterList = document.getElementById(("facetFiltersList-"+id))
      filterList.classList.remove('active');
    filterList.parentElement.classList.remove('active')
    dropdownMenu.classList.toggle('active')
      dropdownMenu.parentElement.classList.toggle('open');
    const dropdownContent = document.getElementsByClassName("facetFilters-dropdown");
    const dropdown = document.getElementById('facetFiltersList-'+id)
    if(!document.getElementById('facetFilters-'+id).classList.contains('open')) {
      dropdown.classList.add('active')
      document.getElementById('facetFilters-'+id).classList.add('open');
      dropdownMenu.classList.add('active')
    } else {
      for(let i=0; i<dropdownContent.length; i++) {
        const openDropdown = dropdownContent[i];
          openDropdown.parentElement.children[0].classList.remove('open');
          openDropdown.parentElement.children[0].children[0].classList.remove('active');
        openDropdown.classList.remove('active')
        }
      
      }
    dropdown.classList.toggle('active')
    dropdownMenu.classList.toggle('active')
    document.getElementById('facetFilters-'+id).classList.toggle('open');
  }
  closeDropdown(id) {
    this.facetListDropdown = id;
    const button = document.getElementById(id);
    const dropdownMenu = button;
    const filterList = document.getElementById(("facetFiltersList-"+id))
    if(document.getElementById('facetFilters-'+id).classList.contains('open')) {
      this.facetListDropdown = '';
      filterList.classList.remove('active');
      filterList.parentElement.classList.remove('active')
      dropdownMenu.classList.remove('active')
      dropdownMenu.parentElement.classList.remove('open');
      if (this.baseUrlMachine.includes('test')) {
        this.sendMessageParentIframe(window, '{"inquiry": "setHeight"}', 'https://d-portal-test.doehler.com/');
      } else if(this.baseUrlMachine.includes('dev')) {
        this.sendMessageParentIframe(window, '{"inquiry": "setHeight"}', 'https://d-portal-dev.doehler.com/');
      } else {
        this.sendMessageParentIframe(window, '{"inquiry": "setHeight"}', 'https://d-portal.doehler.com/');
      }
    }
  }
  clickMoreLessMessage (id) {
    const moreButton = document.getElementById('button-'+id) as HTMLElement;
    if (moreButton) {
      if( moreButton.innerHTML.includes('More') || this.moreLessIndicator.includes('open')){
        this.moreLessIndicator = id+'close';
      } else if (moreButton.innerHTML.includes('Less') || this.moreLessIndicator.includes('close')) {
        this.moreLessIndicator = id+'open';
      }
    }
  }
  clickMoreLessConditionMessage(id) {
    if(document.getElementById('facetCheckbox-'+id).classList.contains('show') && this.moreLessIndicator !== id+'close') {
      this.moreLessIndicator = id+'close';
    } else if(document.getElementById('facetCheckbox-'+id).classList.contains('show') && this.moreLessIndicator === id+'close') {
      this.moreLessIndicator = id+'open';
    }
  }
  clickMoreLess(id){
    this.facetList$.pipe(take(1)).subscribe(obj => {
      obj.doehlerCatalogConfigData.filterFacetDCatalog.forEach(facets => {
        const code = facets.code;
        if (!code){
          this.clickMoreLessMessage(id);
          return;
        }
        if(code === id) {
          this.clickMoreLessConditionMessage(id);
        }
      });
      obj.doehlerCatalogConfigData.specificationsPhysicalChemicalCharacteristicsDCatalog.forEach(facets => {
        const code = facets.code;
        if (!code) {
          this.clickMoreLessMessage(id);
          return;
        }
        if(code === id) {
          this.clickMoreLessConditionMessage(id);
        }
      });
    }).unsubscribe();
  }
  clickMoreLessDesktop(id){
    this.facetList$.pipe(take(1)).subscribe(obj => {
      obj.doehlerCatalogConfigData.filterFacetDCatalog.forEach(facets => {
        const code = facets.code;
        if (!code){
          this.clickMoreLessMessage(id);
          return;
        }
        if(code === id) {
          this.clickMoreLessConditionMessage(id);
          if (facets.values.length > 60) {
            document.getElementById('button-'+facets.code).parentElement.parentElement.parentElement.parentElement.style.width = '100%';
            document.getElementById('button-'+facets.code).parentElement.parentElement.classList.add('set-width')
          } else {
            document.getElementById('button-'+facets.code).parentElement.parentElement.parentElement.parentElement.style.width = '41%';
            document.getElementById('button-'+facets.code).parentElement.parentElement.classList.remove('set-width')
          }
        }
      });
      obj.doehlerCatalogConfigData.specificationsPhysicalChemicalCharacteristicsDCatalog.forEach(facets => {
        const code = facets.code;
        if (!code) {
          this.clickMoreLessMessage(id);
          return;
        }
        if(code === id) {
          this.clickMoreLessConditionMessage(id);
        }
      });
    }).unsubscribe();
  }
  decimalNumber(value: number){
    return formatNumber(Number(value), 'en-US', '1.2-2');
  }
  changeBackground(code: string) {
    
    const label = document.getElementById('searchLabel-'+ code)
    const input = document.getElementById('searchLabelMobile-'+ code)
    if (label) {
      label.classList.add('active');
    }
    if (input) {
      input.classList.add('active');
    }
    this.calculateFacetCounts();

  }
  onChangeBackgroundOutside(code: string) {
    
    const label = document.getElementById('searchLabel-'+ code)
    if (label && label.classList.contains('active')) {
      label.classList.remove('active');
    }
    this.calculateFacetCounts();
  }
  onChangeMobileBackgroundOutside (code: string) {
    
    const input = document.getElementById('searchLabelMobile-'+ code)
    if (input && input.classList.contains('active')) {
      input.classList.remove('active');
    }
    this.calculateFacetCounts();
  }
  onSearchPropertyEventMobile($event: KeyboardEvent,facetCode:string) {
    // you can use the following for checkig enter key pressed or not
    const inputElement = <HTMLInputElement>$event.srcElement;
    if ( inputElement ) {
      const searchText = inputElement.value;
      if ( searchText !== '' && document.getElementById('button-'+facetCode)){
        this.moreLessIndicator = facetCode+'open';
        this.clickMoreLess(facetCode);
      } else if (searchText === '' && document.getElementById('button-'+facetCode)) {
        this.moreLessIndicator = facetCode+'close';
        this.clickMoreLess(facetCode);
      }
      const facetLabelsPagination = document.getElementById("checkBoxProperty-"+facetCode).querySelectorAll(".paginationValue");
      const facetLabelsFacets = document.getElementById("checkBoxProperty-"+facetCode).querySelectorAll(".facetValue");
      if (searchText === '' || searchText === ' ') {
        for(let i=0;i<facetLabelsPagination.length;i++){
          var facetLabelsSpans = facetLabelsPagination[i].querySelectorAll(".paginationValueCheckmark");
          for(let i=0;i<facetLabelsSpans.length;i++) {
            if (document.getElementById('dropdownPaginationButtons-'+facetCode)) {
              facetLabelsSpans[i].parentElement.parentElement.classList.remove('hidden');
              document.getElementById('dropdownPaginationButtons-'+facetCode).style.display = 'flex';
            } else if (document.getElementById('button-'+facetCode)) {
              facetLabelsSpans[i].parentElement.parentElement.parentElement.classList.remove('hidden');
              document.getElementById('button-'+facetCode).style.display = 'block';
            }
          }
        }
        for (let j=0; j<facetLabelsFacets.length; j++) {
       
          var facetLabelSpans = facetLabelsFacets[j].querySelectorAll(".facetValueCheckmark");
          for(let j=0; j<facetLabelSpans.length; j++) {
            
            if (document.getElementById('dropdownPaginationButtons-'+facetCode)) {
              facetLabelSpans[j].parentElement.parentElement.classList.add('hidden');
              document.getElementById('dropdownPaginationButtons-'+facetCode).style.display = 'flex';
            } else if (document.getElementById('button-'+facetCode)) {
              facetLabelSpans[j].parentElement.parentElement.parentElement.classList.add('hidden');
              document.getElementById('button-'+facetCode).style.display = 'block';
            }
          }
        }
      } else {
        for(let i=0;i<facetLabelsPagination.length;i++){
          const facetLabelsSpans = facetLabelsPagination[i].querySelectorAll(".paginationValueCheckmark");
          for(let i=0;i<facetLabelsSpans.length;i++) {
            if ( facetLabelsSpans[i].className == "paginationValueCheckmark" &&
                facetLabelsSpans[i].textContent.toLowerCase().trim().includes(searchText.toLowerCase())) {
              facetLabelsSpans[i].parentElement.parentElement.classList.add('hidden');

              if (document.getElementById('dropdownPaginationButtons-'+facetCode)) {
                document.getElementById('dropdownPaginationButtons-'+facetCode).style.display = 'block';
              }

            } else  if ( facetLabelsSpans[i].className == "paginationValueCheckmark" &&
                !facetLabelsSpans[i].textContent.toLowerCase().trim().includes(searchText.toLowerCase())) {
              facetLabelsSpans[i].parentElement.parentElement.classList.add('hidden');
              if (document.getElementById('dropdownPaginationButtons-'+facetCode)) {
                document.getElementById('dropdownPaginationButtons-'+facetCode).style.display = 'none';
              }
              
            }
          }
        }
        for(let j=0; j<facetLabelsFacets.length; j++){
          const facetLabelSpans = facetLabelsFacets[j].querySelectorAll(".facetValueCheckmark");
          for(let j=0; j<facetLabelSpans.length; j++) {
            if ( facetLabelSpans[j].className == "facetValueCheckmark" &&
                facetLabelSpans[j].textContent.toLowerCase().trim().includes(searchText.toLowerCase())) {
              facetLabelSpans[j].parentElement.parentElement.classList.remove('hidden');
              if (document.getElementById('dropdownPaginationButtons-'+facetCode)) {
                document.getElementById('dropdownPaginationButtons-'+facetCode).style.display = 'block';
              }
            } else  if ( facetLabelSpans[j].className == "facetValueCheckmark" &&
                !facetLabelSpans[j].textContent.toLowerCase().trim().includes(searchText.toLowerCase())) {
              facetLabelSpans[j].parentElement.parentElement.classList.add('hidden');
              if (document.getElementById('dropdownPaginationButtons-'+facetCode)) {
                document.getElementById('dropdownPaginationButtons-'+facetCode).style.display = 'none';
              }
            }
          }
        }
      }
    }
  }
  onSearchPropertyPhysicalChemicalMobileEvent($event: KeyboardEvent,facetCode:string) {
    // you can use the following for checkig enter key pressed or not
    const inputElement = <HTMLInputElement>$event.srcElement;
    if ( inputElement ) {
      const searchText = inputElement.value;
      if ( searchText !== ''){
        this.moreLessIndicator = facetCode+'open';
        this.clickMoreLess(facetCode);
      } else if (searchText === '') {
        this.moreLessIndicator = facetCode+'close';
        this.clickMoreLess(facetCode);
      }
      const facetLabels = document.getElementById("checkBoxProperty-"+facetCode).querySelectorAll("label");
      if (searchText === '' || searchText === ' ' ) {
        for(let i=0;i<facetLabels.length;i++){
          var facetLabelsSpans = facetLabels[i].querySelectorAll("span");
          for(let i=0;i<facetLabelsSpans.length;i++) {
            facetLabelsSpans[i].parentElement.parentElement.classList.remove('hidden');
            document.getElementById('button-'+facetCode).style.display = 'block';
          }
        }
      } else {
        for(let i=0;i<facetLabels.length;i++){
          const facetLabelsSpans = facetLabels[i].querySelectorAll("span");
          for(let i=0;i<facetLabelsSpans.length;i++) {
            if ( facetLabelsSpans[i].className == "label" &&
                facetLabelsSpans[i].textContent.toLowerCase().trim().includes(searchText.toLowerCase())) {
              facetLabelsSpans[i].parentElement.parentElement.classList.remove('hidden');
              document.getElementById('button-'+facetCode).style.display = 'block';
            } else  if ( facetLabelsSpans[i].className == "label" &&
                !facetLabelsSpans[i].textContent.toLowerCase().trim().includes(searchText.toLowerCase())) {
              facetLabelsSpans[i].parentElement.parentElement.classList.add('hidden');
              document.getElementById('button-'+facetCode).style.display = 'none';
            }
          }
        }
      }
    }
  }
  onSearchPropertyEvent($event: KeyboardEvent,facetCode:string) {
    // you can use the following for checkig enter key pressed or not
    const inputElement = <HTMLInputElement>$event.srcElement;
    if ( inputElement ) {
     const searchText = inputElement.value;
      if ( searchText !== '' && document.getElementById('button-'+facetCode)){
        this.moreLessIndicator = facetCode+'open';
        this.clickMoreLess(facetCode);
      } else if (searchText === '' && document.getElementById('button-'+facetCode)) {
        this.moreLessIndicator = facetCode+'close';
        this.clickMoreLess(facetCode);
      }
      const facetLabelsPagination = document.getElementById("checkBoxProperty-"+facetCode).querySelectorAll(".paginationValue");
      const facetLabelsFacets = document.getElementById("checkBoxProperty-"+facetCode).querySelectorAll(".facetValue");
      if (searchText === '' || searchText === ' ') {
        for(let i=0;i<facetLabelsPagination.length;i++){
          var facetLabelsSpans = facetLabelsPagination[i].querySelectorAll(".paginationValueCheckmark");
          for(let i=0;i<facetLabelsSpans.length;i++) {
            facetLabelsSpans[i].parentElement.parentElement.classList.remove('hidden');
            if (document.getElementById('dropdownPaginationButtons-'+facetCode)) {
              document.getElementById('dropdownPaginationButtons-'+facetCode).style.display = 'flex';
            }
            
          }
        }
        for (let j=0; j<facetLabelsFacets.length; j++) {
          var facetLabelSpans = facetLabelsFacets[j].querySelectorAll(".facetValueCheckmark");
          for(let j=0; j<facetLabelSpans.length; j++) {
           
            if (document.getElementById('dropdownPaginationButtons-'+facetCode)) {
              facetLabelSpans[j].parentElement.parentElement.classList.add('hidden');
              document.getElementById('dropdownPaginationButtons-'+facetCode).style.display = 'flex';
            } else if (document.getElementById('button-'+facetCode)) {
              facetLabelSpans[j].parentElement.parentElement.parentElement.classList.add('hidden');
              document.getElementById('button-'+facetCode).style.display = 'block';
            }
          }
        }
      } else {
        for(let i=0;i<facetLabelsPagination.length;i++){
          const facetLabelsSpans = facetLabelsPagination[i].querySelectorAll(".paginationValueCheckmark");
          for(let i=0;i<facetLabelsSpans.length;i++) {
            if ( facetLabelsSpans[i].className == "paginationValueCheckmark" &&
                facetLabelsSpans[i].textContent.toLowerCase().trim().includes(searchText.toLowerCase())) {
              facetLabelsSpans[i].parentElement.parentElement.classList.add('hidden');
              if (document.getElementById('dropdownPaginationButtons-'+facetCode)) {
                document.getElementById('dropdownPaginationButtons-'+facetCode).style.display = 'flex';
              }
            } else  if ( facetLabelsSpans[i].className == "paginationValueCheckmark" &&
                !facetLabelsSpans[i].textContent.toLowerCase().trim().includes(searchText.toLowerCase())) {
              facetLabelsSpans[i].parentElement.parentElement.classList.add('hidden');
              if (document.getElementById('dropdownPaginationButtons-'+facetCode)) {
                document.getElementById('dropdownPaginationButtons-'+facetCode).style.display = 'none';
              }
             
            }
          }
        }
        for(let j=0; j<facetLabelsFacets.length; j++){
          const facetLabelSpans = facetLabelsFacets[j].querySelectorAll(".facetValueCheckmark");
          for(let j=0; j<facetLabelSpans.length; j++) {
            if ( facetLabelSpans[j].className == "facetValueCheckmark" &&
                facetLabelSpans[j].textContent.toLowerCase().trim().includes(searchText.toLowerCase())) {
              facetLabelSpans[j].parentElement.parentElement.classList.remove('hidden');
              if (document.getElementById('dropdownPaginationButtons-'+facetCode)) {
                document.getElementById('dropdownPaginationButtons-'+facetCode).style.display = 'flex';
              }
            } else  if ( facetLabelSpans[j].className == "facetValueCheckmark" &&
                !facetLabelSpans[j].textContent.toLowerCase().trim().includes(searchText.toLowerCase())) {
              facetLabelSpans[j].parentElement.parentElement.classList.add('hidden');
              if (document.getElementById('dropdownPaginationButtons-'+facetCode)) {
                document.getElementById('dropdownPaginationButtons-'+facetCode).style.display = 'none';
              }
            }
          }
        }
      }
    }
  }
  onSearchPropertyPhysicalChemicalEvent($event: KeyboardEvent,facetCode:string) {
    // you can use the following for checkig enter key pressed or not
    const inputElement = <HTMLInputElement>$event.srcElement;
    if ( inputElement ) {
      const searchText = inputElement.value;
      if ( searchText !== ''){
        this.moreLessIndicator = facetCode+'open';
        this.clickMoreLess(facetCode);
      } else if (searchText === '') {
        this.moreLessIndicator = facetCode+'close';
        this.clickMoreLess(facetCode);
      }
      const facetLabels = document.getElementById("checkBoxProperty-"+facetCode).querySelectorAll("label");
      if (searchText === '' || searchText === ' ' ) {
        for(let i=0;i<facetLabels.length;i++){
          var facetLabelsSpans = facetLabels[i].querySelectorAll("span");
          for(let i=0;i<facetLabelsSpans.length;i++) {
            facetLabelsSpans[i].parentElement.parentElement.parentElement.classList.remove('hidden');
            document.getElementById('button-'+facetCode).style.display = 'block';
          }
        }
      } else {
        for(let i=0;i<facetLabels.length;i++){
          const facetLabelsSpans = facetLabels[i].querySelectorAll("span");
          for(let i=0;i<facetLabelsSpans.length;i++) {
            if ( facetLabelsSpans[i].className == "label" &&
                facetLabelsSpans[i].textContent.toLowerCase().trim().includes(searchText.toLowerCase())) {
              facetLabelsSpans[i].parentElement.parentElement.parentElement.classList.remove('hidden');
              document.getElementById('button-'+facetCode).style.display = 'block';
            } else  if ( facetLabelsSpans[i].className == "label" &&
                !facetLabelsSpans[i].textContent.toLowerCase().trim().includes(searchText.toLowerCase())) {
              facetLabelsSpans[i].parentElement.parentElement.parentElement.classList.add('hidden');
              document.getElementById('button-'+facetCode).style.display = 'none';
            }
          }
        }
      }
    }
  }
  toggleFilterBar() {
    const myFlyIn = document.getElementById('filterFlyIn') as HTMLElement;
    const myFlyInDesktop = document.getElementById('filterFlyInDesktop') as HTMLElement;
    const mainContent = document.getElementById('mainContent') as HTMLElement;
    const body = document.getElementById('body') as HTMLElement;
    const filterName = document.getElementById('filterButtonTitle')
    const button = document.getElementById('resetButton')
    if (myFlyInDesktop.classList.contains('visible') && myFlyIn.classList.contains('visible') && mainContent.classList.contains('active')) {
      myFlyIn.classList.remove('visible');
      myFlyInDesktop.classList.remove('visible');
      mainContent.classList.remove('active');
      body.classList.remove('freeze');
      button.classList.remove('active')
      filterName.innerText = 'All Filters';
      if (this.baseUrlMachine.includes('test')) {
        this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-test.doehler.com/');
      } else if(this.baseUrlMachine.includes('dev')) {
        this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-dev.doehler.com/');
      } else {
        this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal.doehler.com/');
      }
    } else {
      myFlyIn.classList.add('visible');
      myFlyInDesktop.classList.add('visible');
      mainContent.classList.add('active');
      body.classList.add('freeze');
      button.classList.add('active')
      filterName.innerText = 'Close Filters';
      if (this.baseUrlMachine.includes('test')) {
        this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-test.doehler.com/');
      } else if(this.baseUrlMachine.includes('dev')) {
        this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-dev.doehler.com/');
      } else {
        this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal.doehler.com/');
      }
    }
  }
  openFacetList(name: string) {
    if (this.facetListToShow === name) {
      this.facetListToShow = "";
    } else {
      this.facetListToShow = name;
    }
    if(document.getElementById('filterFlyInWrapper').style.display !== 'none') {
      document.getElementById('filterFlyInWrapper').style.display = 'none';
      document.getElementById('filterFlyInWrapperActive').style.display = 'block';
    if (this.baseUrlMachine.includes('test')) {
      this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-test.doehler.com/');
    } else if(this.baseUrlMachine.includes('dev')) {
      this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-dev.doehler.com/');
    } else {
      this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal.doehler.com/');
    }
    } else {
      document.getElementById('filterFlyInWrapper').style.display = 'block';
      document.getElementById('filterFlyInWrapperActive').style.display = 'none';
      if (this.baseUrlMachine.includes('test')) {
        this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-test.doehler.com/');
      } else if(this.baseUrlMachine.includes('dev')) {
        this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-dev.doehler.com/');
      } else {
        this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal.doehler.com/');
      }
    }
  }
  openFacetListActive(name: string) {
    if (this.facetListToShow === name) {
      this.facetListToShow = "";
    } else {
      this.facetListToShow = name;
    }
    if(document.getElementById('filterFlyInWrapperActive').style.display !== 'none') {
      document.getElementById('filterFlyInWrapper').style.display = 'block';
      document.getElementById('filterFlyInWrapperActive').style.display = 'none';
    if (this.baseUrlMachine.includes('test')) {
      this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-test.doehler.com/');
    } else if(this.baseUrlMachine.includes('dev')) {
      this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-dev.doehler.com/');
    } else {
      this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal.doehler.com/');
    }
    } else {
      document.getElementById('filterFlyInWrapper').style.display = 'none';
      document.getElementById('filterFlyInWrapperActive').style.display = 'block';
      if (this.baseUrlMachine.includes('test')) {
        this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-test.doehler.com/');
      } else if(this.baseUrlMachine.includes('dev')) {
        this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-dev.doehler.com/');
      } else {
        this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal.doehler.com/');
      }
    }
  }
  sendWarningMessageMinValuesMobile(input) {
    input.closest('.sliderContainer').classList.add('warning');
    input.closest('.sliderContainer').classList.remove('active');
    input.closest('.filter-slider').children[1].classList.remove('hidden');
    input.closest('.filter-slider').children[2].classList.add('hidden');
  }
  removeWarninMessageMinValuesMobile(input) {
    input.closest('.sliderContainer').classList.add('warning');
    input.closest('.sliderContainer').classList.add('active');
    input.closest('.filter-slider').children[1].classList.add('hidden');
    input.closest('.filter-slider').children[2].classList.add('hidden');
  }
  sendWarningMessageMaxValuesMobile(input) {
    input.closest('.sliderContainer').classList.add('warning');
    input.closest('.sliderContainer').classList.remove('active');
    input.closest('.filter-slider').children[2].classList.remove('hidden');
    input.closest('.filter-slider').children[1].classList.add('hidden');
  }
  removeWarninMessageMaxValuesMobile(input) {
    input.closest('.sliderContainer').classList.add('warning');
    input.closest('.sliderContainer').classList.remove('active');
    input.closest('.filter-slider').children[2].classList.add('hidden');
    input.closest('.filter-slider').children[1].classList.add('hidden');
  }
  enterMinValues(currentQuery,$event,min,minSelect,maxSelect,facetCode) {
    let categoryCode = "";
    let tabName = "";
    const button = document.getElementById('sliderInput-'+facetCode);
    const buttonPysical = document.getElementById('physicalSliderInput-'+facetCode);
    if(currentQuery.indexOf(facetCode) !== -1){
      if(min > $event.target.value || min === $event.target.value || maxSelect < $event.target.value) {
        currentQuery = currentQuery.split(":"+facetCode)[0];
        if(button) {
          this.sendWarningMessageMinValuesMobile(button);
        }
        if (buttonPysical) {
          this.sendWarningMessageMinValuesMobile(buttonPysical);
        }
        return;
      } else if (min < $event.target.value && maxSelect > $event.target.value) {
        currentQuery = currentQuery.split(facetCode)[0]+facetCode+":"+"["+$event.target.value+" TO " +maxSelect+"]";
        if(button) {
          this.removeWarninMessageMinValuesMobile(button)
        }
        if (buttonPysical) {
          this.removeWarninMessageMinValuesMobile(buttonPysical)
        }
      }
    } else {
      currentQuery = currentQuery + ":" + facetCode + ":" +"["+$event.target.value+" TO " +maxSelect+"]";
      if(min > $event.target.value || maxSelect < $event.target.value || min === $event.target.value || $event.target.value === '') {
        if(button) {
          this.sendWarningMessageMinValuesMobile(button);
        }
        if (buttonPysical) {
          this.sendWarningMessageMinValuesMobile(buttonPysical);
        }
        return;
      } else if (min < $event.target.value || maxSelect > $event.target.value) {
        if(button) {
          this.removeWarninMessageMinValuesMobile(button)
        }
        if (buttonPysical) {
          this.removeWarninMessageMinValuesMobile(buttonPysical)
        }
      }
    }
    this.facetList$.pipe(take(1)).subscribe(result => {
      categoryCode = result.categoryCode;
      tabName = result.defaultActiveTabName;
    }).unsubscribe();
  
    let modifiedQueryString = this.modifyQueryStringByFacetCode(currentQuery,"tabName",tabName);
    const queryParams = { query: decodeURIComponent(modifiedQueryString).replace(/\+/g, ' ') };
    const url = this.routing.getUrl(
        { cxRoute: 'category', params: { code: categoryCode} },
        { queryParams }
    );

    this.routing.goByUrl(url);
  }
  enterMaxValues(currentQuery,$event,max,minSelect,maxSelect,facetCode) {
    let categoryCode = "";
    let tabName = "";
    const button = document.getElementById('sliderInput-'+facetCode) as HTMLInputElement;
    const buttonPysical = document.getElementById('physicalSliderInput-'+facetCode) as HTMLInputElement;
    if(currentQuery.indexOf(facetCode) !== -1){
      if($event.target.value > max || max === $event.target.value || minSelect > $event.target.value || $event.target.value === minSelect) {
        currentQuery = currentQuery.split(":"+facetCode)[0];
        if(button) {
          this.sendWarningMessageMaxValuesMobile(button)
        }
        if (buttonPysical) {
          this.sendWarningMessageMaxValuesMobile(buttonPysical)
        }
        return;
      } else if ($event.target.value < max && $event.target.value > minSelect && $event.target.value !== minSelect) {
        currentQuery = currentQuery.split(facetCode)[0]+facetCode+":"+"["+minSelect+" TO " +$event.target.value+"]";
        if(button) {
          this.removeWarninMessageMaxValuesMobile(button)
        }
        if (buttonPysical) {
          this.removeWarninMessageMaxValuesMobile(buttonPysical)
        }
      }
    } else {
      currentQuery= currentQuery+ ":" + facetCode + ":" +"["+minSelect+" TO " +$event.target.value+"]";
      if(max < $event.target.value || minSelect > $event.target.value || max === $event.target.value || $event.target.value === '') {
        currentQuery= currentQuery+ ":" + facetCode + ":" +"["+minSelect+" TO " +$event.target.value+"]";
        if(button) {
          this.sendWarningMessageMaxValuesMobile(button)
        }
        if (buttonPysical) {
          this.sendWarningMessageMaxValuesMobile(buttonPysical)
        }
        return;
      } else if (max > $event.target.value || minSelect < $event.target.value) {
        currentQuery= currentQuery+ ":" + facetCode + ":" +"["+minSelect+" TO " +$event.target.value+"]";
        if(button) {
          this.removeWarninMessageMaxValuesMobile(button)
        }
        if (buttonPysical) {
          this.removeWarninMessageMaxValuesMobile(buttonPysical)
        }
      }
    }
    this.facetList$.pipe(take(1)).subscribe(result => {
      categoryCode = result.categoryCode;
      tabName = result.defaultActiveTabName;
    }).unsubscribe();

    let modifiedQueryString = this.modifyQueryStringByFacetCode(currentQuery,"tabName",tabName);
    const queryParams = { query: decodeURIComponent(modifiedQueryString).replace(/\+/g, ' ') };
    const url = this.routing.getUrl(
        { cxRoute: 'category', params: { code: categoryCode ,tabName: tabName } },
        { queryParams }
    );
    this.routing.goByUrl(url);
  }

  modifyQueryStringByFacetCode(currentQuery,facetCode,value){

    if (currentQuery) {
      const parts = currentQuery.split(":");

      for (let i = 0; i < parts.length; i += 2) {
        if (parts[i] === facetCode) {
            parts[i + 1] = value; // Yeni değeri buraya yazın
        }
      }
  
      const modifiedQueryString = parts.join(":");
      return modifiedQueryString;
    }
    
    return currentQuery;

  }

  toggleApplicationFitFilter() {
    let url = window.location.search;
    url = url.split("?query=")[1];
    this.facetList$.pipe(take(1)).subscribe(result => {
      JSON.stringify(result.categoriesTreeList)
      JSON.stringify(result.categoriesTreeJson);
      if (this.baseUrlMachine.includes('test')) {
        this.sendMessageParentIframe(window, '{"inquiry": "openApplicationFitDialog", "sendCurrentUrl": "'+url+'", "initialStructure": '+JSON.stringify(result.categoriesTreeList)+'}', 'https://d-portal-test.doehler.com/');
      } else if(this.baseUrlMachine.includes('dev')) {
        this.sendMessageParentIframe(window, '{"inquiry": "openApplicationFitDialog", "sendCurrentUrl": "'+url+'", "initialStructure": '+JSON.stringify(result.categoriesTreeList)+'}', 'https://d-portal-dev.doehler.com/');
      } else {
        this.sendMessageParentIframe(window, '{"inquiry": "openApplicationFitDialog", "sendCurrentUrl": "'+url+'", "initialStructure": '+JSON.stringify(result.categoriesTreeList)+'}', 'https://d-portal.doehler.com/');
      }
    }).unsubscribe();
  }
  protected search(criteria: SearchCriteria): void {
    const currentPage = criteria.currentPage;
    const pageSize = criteria.pageSize;
    const sort = criteria.sortCode;
    this.productSearchService.search(
        criteria.query,
        // TODO: consider dropping this complex passing of cleaned object
        Object.assign(
            {},
            currentPage && {currentPage},
            pageSize && {pageSize},
            sort && {sort})
    );
  }
  toggleStartANewProject() {
    if (this.baseUrlMachine.includes('test')) {
      this.sendMessageParentIframe(window, '{"inquiry": "startNewProject"}', 'https://d-portal-test.doehler.com/');
    } else if(this.baseUrlMachine.includes('dev')) {
      this.sendMessageParentIframe(window, '{"inquiry": "startNewProject"}', 'https://d-portal-dev.doehler.com/');
    } else {
      this.sendMessageParentIframe(window, '{"inquiry": "startNewProject"}', 'https://d-portal.doehler.com/');
    }
  }
  toggleFilterClose() {
    const myFlyInDesktop = document.getElementById('filterFlyInDesktop') as HTMLElement;
    const myFlyIn = document.getElementById('filterFlyIn') as HTMLElement;
    const mainContent = document.getElementById('mainContent') as HTMLElement;
    const body = document.getElementById('body') as HTMLElement;
    if (myFlyIn.classList.contains('visible') && mainContent.classList.contains('active')) {
      myFlyIn.classList.remove('visible');
      mainContent.classList.remove('active');
      body.classList.remove('freeze');
      myFlyInDesktop.classList.remove('visible');
      if (this.baseUrlMachine.includes('test')) {
        this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-test.doehler.com/');
       // this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-test.doehler.com/');
      } else if(this.baseUrlMachine.includes('dev')) {
        this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-dev.doehler.com/');
        this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-dev.doehler.com/');
      } else {
        this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal.doehler.com/');
        this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal.doehler.com/');
      }
    } else {
      myFlyIn.classList.add('visible');
      mainContent.classList.add('active');
      body.classList.add('freeze');
      myFlyInDesktop.classList.add('visible');
      if (this.baseUrlMachine.includes('test')) {
        this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-test.doehler.com/');
       // this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-test.doehler.com/');
      } else if(this.baseUrlMachine.includes('dev')) {
        this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-dev.doehler.com/');
        this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-dev.doehler.com/');
      } else {
        this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal.doehler.com/');
        this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal.doehler.com/');
      }
    }
  }
  onScroll() {
    this.facetListDropdown = '';
    if (this.baseUrlMachine.includes('test')) {
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-test.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "setHeight"}', 'https://d-portal-test.doehler.com/');
    } else if(this.baseUrlMachine.includes('dev')) {
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-dev.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "setHeight"}', 'https://d-portal-dev.doehler.com/');
    } else {
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "setHeight"}', 'https://d-portal.doehler.com/');
    }
  }
  setBackgroundImage(sImgSrcUrl: string): object {
    return {
      'background-image': `url(${sImgSrcUrl})`
    };
  }
  // 9002 portundaki yani backofficedeki bir resmi almak için
  getBackofficeImage(sImgSrcUrl: string): object {
    return {
      'background-image': `url(${this.config.backend.occ.baseUrl}${sImgSrcUrl})`,
    };
  }
  setBackgroundColour(sColourCode: string): object {
    if (sColourCode === 'brown') {
      sColourCode = 'rgb(117, 52, 21)';
    } else if (sColourCode === 'black') {
      sColourCode = 'black';
    } else if (sColourCode === 'orange') {
      sColourCode = 'rgb(251, 120, 80)';
    } else if (sColourCode === 'violet') {
      sColourCode = '#a200c7';
    } else if (sColourCode === 'yellow' || sColourCode === 'yellow-orange') {
      sColourCode = 'rgb(254, 231, 99)';
    } else if (sColourCode === 'blue') {
      sColourCode = 'rgb(81, 102, 167)';
    } else if (sColourCode === 'green') {
      sColourCode = 'rgb(92, 176, 60)';
    } else if (sColourCode === 'red' || sColourCode === 'red-blue') {
      sColourCode = 'rgb(206, 0, 56)';
    } else if (sColourCode === 'white' || sColourCode === 'colorless') {
      sColourCode = 'white';
    } else if (sColourCode === 'pink') {
      sColourCode = '#ff52eb';
    } else if (sColourCode === 'glitter') {
      sColourCode = '#f5f5f5';
    }
    return {
      'background-color': `${sColourCode}`
    };
  }
  getLinkParams(value: FacetValue) {
    return this.facetService.getLinkParams(value.query?.query.value);
  }
  sendWarningMessageMinValues(input, cursorStyle) {
    if(input.closest('.sliderContainer').classList.contains('warning')) {
      input.closest('.sliderContainer').classList.add('warning');
      input.closest('.sliderContainer').classList.remove('active');
      input.closest('.filter-slider').children[1].classList.remove('hidden');
      input.closest('.filter-slider').children[2].classList.add('hidden');
      input.style.cursor = cursorStyle;
    } else {
      input.closest('.sliderContainer').classList.add('warning');
      input.closest('.sliderContainer').classList.add('active');
      input.closest('.filter-slider').children[1].classList.add('hidden');
      input.closest('.filter-slider').children[2].classList.add('hidden');
      input.style.cursor = cursorStyle;
    }
  }
  sendWarningMessageMaxValues(input, cursorStyle) {
    if(input.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.classList.contains('warning')) {
      input.closest('.sliderContainer').classList.add('warning');
      input.closest('.sliderContainer').classList.remove('active');
      input.closest('.filter-slider').children[2].classList.remove('hidden');
      input.closest('.filter-slider').children[1].classList.add('hidden');
      input.style.cursor = cursorStyle;
    } else {
      input.closest('.sliderContainer').classList.add('warning');
      input.closest('.sliderContainer').classList.add('active');
      input.closest('.filter-slider').children[2].classList.add('hidden');
      input.closest('.filter-slider').children[1].classList.add('hidden');
      input.style.cursor = cursorStyle;
    }
  }
  updateMinValue(min,minSelect,max,facetCode) {
    const button = document.getElementById('range-'+facetCode);
    const buttonPysical = document.getElementById('physicalRange-'+facetCode);
    this.facetList$.pipe(take(1)).subscribe(result => {
      if (result.breadcrumbs.length > 0) {
        if (min > minSelect || min === minSelect) {
          if(button) {
            this.sendWarningMessageMinValues(button,'not-allowed')
          }
          if(buttonPysical) {
            this.sendWarningMessageMinValues(buttonPysical,'not-allowed');
          }
        } else if (min > minSelect) {
          if(button) {
            this.sendWarningMessageMinValues(button,'pointer')
          }
          if(buttonPysical) {
            this.sendWarningMessageMinValues(buttonPysical,'pointer');
          }
        }
      }
    }).unsubscribe();
  }
  updateMaxValue(max,maxSelect,min,facetCode) {
    const button = document.getElementById('range-'+facetCode);
    const buttonPysical = document.getElementById('physicalRange-'+facetCode);
    this.facetList$.pipe(take(1)).subscribe(result => {
      if (result.breadcrumbs.length > 0) {
        if (max < maxSelect || max === maxSelect) {
          if(button) {
            this.sendWarningMessageMaxValues(button,'not-allowed')
          }
          if(buttonPysical) {
            this.sendWarningMessageMaxValues(buttonPysical,'not-allowed');
          }
        } else if (max < maxSelect) {
          if(button) {
            this.sendWarningMessageMaxValues(button,'pointer')
          }
          if(buttonPysical) {
            this.sendWarningMessageMaxValues(buttonPysical,'pointer');
          }
        }
      }
    }).unsubscribe();
  }
  getMinMinLinkParams(value: FacetValue,min,minSelect,max,facetCode) {
    let query = value.query?.query.value;
    if(query.indexOf(facetCode) !== -1){
      if(min > minSelect || min === minSelect) {
        query = query.split(facetCode)[0]+facetCode+":"+"["+min+" TO " +max+"]";
      } else if (min < minSelect) {
        minSelect = minSelect - 1;
        query = query.split(facetCode)[0]+facetCode+":"+"["+minSelect+" TO " +max+"]";
      }
    }
    this.calculateFacetCounts();
    return this.facetService.getLinkParams(query);
  }
  getMinMaxLinkParams(value: FacetValue,min,max,facetCode) {
    let query = value.query?.query.value;
    if(query.indexOf(facetCode) !== -1){
      min = min + 1;
      query = query.split(facetCode)[0]+facetCode+":["+min+" TO " +max+"]";
    }
    this.calculateFacetCounts();
    return this.facetService.getLinkParams(query);
  }
  getMaxMinLinkParams(value: FacetValue,max,min,facetCode) {
    let query = value.query?.query.value;
    if(query.indexOf(facetCode) !== -1){
      max = max - 1;
      query = query.split(facetCode)[0]+facetCode+":["+min+" TO " +max+"]";
    }
    this.calculateFacetCounts();
    return this.facetService.getLinkParams(query);
  }
  getMaxMaxLinkParams(value: FacetValue,max,maxSelect,min,facetCode) {
    let query = value.query?.query.value;
    if(query.indexOf(facetCode) !== -1){
      if(max < maxSelect || max === maxSelect) {
        query = query.split(facetCode)[0]+facetCode+":"+"["+min+" TO " +max+"]";
      } else if (max > maxSelect) {
        maxSelect = maxSelect + 1;
        query = query.split(facetCode)[0]+facetCode+":"+"["+min+" TO " +maxSelect+"]";
      }
    }
    this.calculateFacetCounts();
    return this.facetService.getLinkParams(query);
  }
  openLink(event: KeyboardEvent) {
    (event.target as HTMLElement).click();
    event.preventDefault();

    
  }
  // Active facet - secilenleri gösterme kısmı işlemleri START
  // Active(Secili olan facetleri) facetları ürün sıralama ekranından çarpıya basarak kaldırırken urlden onun
  // sorgusunu oluşturan kısım
  getLinkParamsActiveFacet(facet: Breadcrumb) {
    
    return this.facetService.getLinkParams(facet.removeQuery?.query?.value);
  }
  getFocusKey(activeFacetList: FacetList, facet: Breadcrumb) {
    return activeFacetList.facets?.find((f) =>
      f.values?.find((val) => val.name === facet.facetValueName)
    )
      ? ''
      : facet.facetValueName;
  }
  // Facettan  tab da olan propertyu ve değerleri seçilirse Tab da seçilmeli
  // birden fazla seçilmesi durumunda All seçilmeli
  // yada Tab ları getiren property dışında bir property seçildiyse All seçili gelmeli.
  getCategoryTabChange(facetCode,facetName,selected) {
    this.facetListDropdown = '';
    this.getTabChange(facetCode,facetName,selected);
  }
  getTabChange(facetCode,facetName,selected) {
    
    let facetNameWithComma = facetName;

    if (facetName.includes(":")) {
        facetName =  facetName.replace(':', 'lessthancolongreaterthan');
    }

    if (!selected) {
      if (!this.selectedFilters.includes(':' + facetCode + ':' + facetName)) {
        this.selectedFilters = this.selectedFilters + ':' + facetCode + ':' + facetName;
      }
    } else {
      this.selectedFilters = this.selectedFilters.replace(':' + facetCode + ':' + facetName, '')
    }

    const mySet = new Set<string>();
    if (this.facetList$ !== undefined || this.facetList$ !== null) {
      this.facetList$.pipe(take(1)).subscribe(result => {
        if (window.location.href.includes("RMW61ZF01")) {
          this.getColourShadeChange(facetCode,facetName,selected);
        } else {
          if (selected === true) {
            if (result.categoryCatalogTabInfo) {
              for (let k = 0; k < result.categoryCatalogTabInfo.length; k++) {
                if (result.categoryCatalogTabInfo[k].categoryTab.tabNameProvider === result.defaultActiveTabName) {
                  if (result.categoryCatalogTabInfo[k].attributeTab.tabNameProvider.includes(facetName)) {
                    mySet.add(facetName);
                  } else {
                    mySet.clear();
                  }
                }
              }
            }

          }
        }
        if (mySet.size === 1) {
          this.facetFilterTabChange = "";
          this.colorName = mySet.values().next().value;
          if (this.colorName === 'pink') {
            this.colorName = '#ff52eb';
          } else if (this.colorName === 'green') {
            this.colorName = 'rgb(92, 176, 60)';
          } else if (this.colorName === 'blue') {
            this.colorName = 'rgb(81, 102, 167)';
          } else if (this.colorName === 'yellow') {
            this.colorName = 'rgb(254, 231, 99)';
          } else if (this.colorName === 'violet') {
            this.colorName = '#a200c7';
          } else if (this.colorName === 'orange') {
            this.colorName = 'rgb(251, 120, 80)';
          } else if (this.colorName === 'brown') {
            this.colorName = 'rgb(117, 52, 21)';
          } else if (this.colorName === 'black') {
            this.colorName = 'black';
          } else if (this.colorName === 'red') {
            this.colorName = 'rgb(206, 0, 56)';
          } else if (this.colorName === 'white') {
            this.colorName = 'white';
          }
          this.attributeName = mySet.values().next().value;
        }
      }).unsubscribe();
    }

    this.calculateFacetCounts();
   
    if (facetNameWithComma.includes(":") && selected == true) {
      this.resetCustomFacetFilter(facetCode);
    }
  }
  getColourShadeChange(facetCode,facetName,selected){
    if(selected === true) {
      this.facetList$.pipe(take(1)).subscribe(result => {
        const mySet = new Set<string>();
        if (result.categoryCatalogTabInfo) {
          for (let i = 0; i < result.categoryCatalogTabInfo.length; i++) {
            if ( result.categoryCatalogTabInfo[i].categoryTab.tabNameProvider === result.defaultActiveTabName  ) {
              if ( result.categoryCatalogTabInfo[i].attributeTab.tabNameProvider.includes(facetName)) {
               mySet.add(facetName);
             } else {
                mySet.clear();
             }
            }
          }
        }
        
        if (!Array.of("fruit", "vegetable", "blend").includes(result.defaultActiveTabName) && mySet.size >= 2) {
          this.attributeName = '';
          this.colorName = undefined;
        } else if ( Array.of("fruit", "vegetable", "blend").includes(result.defaultActiveTabName) && mySet.size > 2  ) {
          this.attributeName = '';
          this.colorName = undefined;
        } else if (mySet.size === 1) {
          this.colorName= mySet.values().next().value;
          if(this.colorName === 'pink') {
            this.colorName = '#ff52eb';
          } else if(this.colorName === 'green') {
            this.colorName = 'rgb(92, 176, 60)';
          } else if(this.colorName === 'blue') {
            this.colorName = 'rgb(81, 102, 167)';
          } else if(this.colorName === 'yellow') {
            this.colorName = 'rgb(254, 231, 99)';
          } else if(this.colorName === 'violet') {
            this.colorName = '#a200c7';
          } else if(this.colorName === 'orange') {
            this.colorName = 'rgb(251, 120, 80)';
          } else if(this.colorName === 'brown') {
            this.colorName = 'rgb(117, 52, 21)';
          } else if(this.colorName === 'black') {
            this.colorName = 'black';
          } else if(this.colorName === 'red') {
            this.colorName = 'rgb(206, 0, 56)';
          } else if(this.colorName === 'white') {
            this.colorName = 'white';
          }
          this.attributeName = mySet.values().next().value;
        } else {
          this.attributeName = '';
          this.colorName = undefined;
        }
      }).unsubscribe();
    }
  }
 
  removeLastColon(url: string): string {
    if (url.endsWith(':')) {
      return url.slice(0, -1);
    }
    return url;
  }
  // reset selected filters
  resetAllFilter() {

  
    const queryParams = { query: decodeURIComponent(this.baseQuery).replace(/\+/g, ' ')};
    const url = this.routing.getUrl(
        { cxRoute: 'category', params: { code: this.initialCategoryCode } },
        { queryParams }
    );

    this.facetListDropdown = '';

    if (this.applicationFitUrl){
      this.applicationFitCount = 0;
      if (this.baseUrlMachine.includes('test')) {
        this.sendMessageParentIframe(window, '{"inquiry": "resetUrl"}', 'https://d-portal-test.doehler.com/');
      } else if(this.baseUrlMachine.includes('dev')) {
        this.sendMessageParentIframe(window, '{"inquiry": "resetUrl"}', 'https://d-portal-dev.doehler.com/');
      } else {
        this.sendMessageParentIframe(window, '{"inquiry": "resetUrl"}', 'https://d-portal.doehler.com/');
      }
      
      this.routing.goByUrl(this.removeLastColon(url))
      .then(() => {
        window.location.reload();
      });

    }
    
    this.routing.goByUrl(this.removeLastColon(url));
    
  }

  resetAndStartOver(){
    this.resetAllFilter();

    this.applicationFitCount = 0;
    if (this.baseUrlMachine.includes('test')) {
      this.sendMessageParentIframe(window, '{"inquiry": "resetUrl"}', 'https://d-portal-test.doehler.com/');
    } else if(this.baseUrlMachine.includes('dev')) {
      this.sendMessageParentIframe(window, '{"inquiry": "resetUrl"}', 'https://d-portal-dev.doehler.com/');
    } else {
      this.sendMessageParentIframe(window, '{"inquiry": "resetUrl"}', 'https://d-portal.doehler.com/');
    }
  }

  // reset selected filter
  resetCustomFacetFilter(facetCode) {
    let categoryCode='';
    let defaultActiveTabName ='';
    let currenResetCustomFiltertQuery='';
    let currentCustomQuery='';
    const regulatoryInfoCodeList: string[] = [];
    const physicalChemicalCodeList: string[] = [];


    this.facetList$.pipe(take(1)).subscribe(result => {

      categoryCode = result.categoryCode;
      defaultActiveTabName = result.defaultActiveTabName;
      currentCustomQuery = result?.currentQuery?.query?.value;
      if (facetCode == 'regulatoryInfo' && result.doehlerCatalogConfigData) {
        result.doehlerCatalogConfigData.specificationsRegulatoryInfoDCatalog.forEach(regularity => {
          regulatoryInfoCodeList.push(regularity.code);
        });
      }
      if (facetCode == 'physicalChemical' && result.doehlerCatalogConfigData) {
        result.doehlerCatalogConfigData.specificationsPhysicalChemicalCharacteristicsDCatalog.forEach(physicalChemical => {
          physicalChemicalCodeList.push(physicalChemical.code);
        });
      }

    }).unsubscribe();
   
    this.activeFacetList$.pipe(take(1)).subscribe(result => {

      result.activeFacets.forEach(activeFacet => {

        if (facetCode == 'regulatoryInfo' && !regulatoryInfoCodeList.includes(activeFacet.facetCode) && activeFacet.facetCode) {
          currenResetCustomFiltertQuery += ':' + activeFacet.facetCode + ':' + activeFacet.facetValueCode;
        }
         if (facetCode == 'physicalChemical' && !physicalChemicalCodeList.includes(activeFacet.facetCode) && activeFacet.facetCode ) {
          currenResetCustomFiltertQuery += ':' + activeFacet.facetCode + ':' +  activeFacet.facetValueCode;
        }
        if (facetCode != 'regulatoryInfo' && facetCode != 'physicalChemical' &&  activeFacet.facetCode != facetCode && activeFacet.facetCode) {
          currenResetCustomFiltertQuery += ':' + activeFacet.facetCode + ':' +  activeFacet.facetValueCode;
    
        }

      });
    }).unsubscribe();
    
    const tabNames = this.findTabNamesFromQuery(currentCustomQuery);
    
    let tabQuery = this.combineTabQuery(tabNames,defaultActiveTabName);
    
    this.baseQuery = ":name-asc:allCategories:" +categoryCode+tabQuery;
    

    if (!this.initialCategoryCode){
      this.initialCategoryCode = categoryCode;
    }

    if (!currenResetCustomFiltertQuery) {

      const queryParams = { query: decodeURIComponent(this.baseQuery).replace(/\+/g, ' ') };
      const url = this.routing.getUrl(
          { cxRoute: 'category', params: { code: this.initialCategoryCode } },
          { queryParams }
      );
      this.routing.goByUrl(this.removeLastColon(url));
      return;
    } 

    this.baseQuery = this.baseQuery + currenResetCustomFiltertQuery;
    const queryParams = { query: decodeURIComponent(this.baseQuery).replace(/\+/g, ' ') };
    const url = this.routing.getUrl(
        { cxRoute: 'category', params: { code: this.initialCategoryCode } },
        { queryParams }
    );
     this.routing.goByUrl(this.removeLastColon(url));
  }

  // Renk tablarında birden fazla renk seçilmemesi isteniyor -END
  // Sort işlemi buradan yapılıyor.
  sortList(sortCode: string): void {
    this.facetListDropdown = '';
    this.productListComponentService.sort(sortCode);
  }
   // PDP /p/productcode/ olarak acilabilir
   getCustomProductDetailUrl(
    similarProductCode: string,
    similarProductName: string,
  ): string {
    const encodeName = encodeURIComponent(similarProductName);
    return  /p/+`${similarProductCode}`;
  }
   // html icerisinde similir productların resimlerini göstermek icin kullanildi
   getCustomVariantThumbnailUrl(
    thumbnailUrl: string
  ): string {
    return  `${this.config.backend.occ.baseUrl}${thumbnailUrl}`;
  }
  getGroupLinkParams(queryValue){
     
    return this.facetService.getLinkParams(queryValue);
  }
  expandFacetGroup(facet: Facet, ref: FacetComponent) {
    if (!ref.isExpanded) {
      this.facetService.toggle(facet, ref.isExpanded);
    }
  }

  calculateFacetCounts() {

    this.facetListSubscription = this.activeFacetList$.subscribe((facetList) => {
      
      this.selectedFacetCountMap = DoehlerFunctionUtil.fnFacetSelectedCount(facetList);
   
    });

    this.facetListSliderSubscription = this.facetList$.subscribe(obj => {
    
      this.regulatoryInfoSelectedCountMap = DoehlerFunctionUtil.fnRegulatoryInfoSelectedCount(obj);
      this.physicalChemicalCharSelectedCountMap = DoehlerFunctionUtil.fnPhysicalChemicalCharSelectedCount(obj);
      this.isDisabled = JSON.stringify(obj.categoriesTreeList) == undefined;
      this.isDisabledFilter = obj.facets == undefined;
      let categoryCode=obj.categoryCode;
      let defaultActiveTabName =obj.defaultActiveTabName;
      let currentCustomQuery = obj?.currentQuery?.query?.value;
      
      const activeFacetList = obj.breadcrumbs;

      if(activeFacetList.length > 0) {
        this.applicationFitCount=0;
        this.selectedFilterCount=0;
        activeFacetList.forEach(result => {
          this.initialCategoryCode = obj.categoryCode;
          if (result.facetCode.includes('applicationFitCategories')) {
            if (!this.applicationFitUrl.includes(':applicationFitCategories:'+result.facetValueCode)) {
              this.applicationFitUrl = this.applicationFitUrl + ':applicationFitCategories:'+result.facetValueCode;
            }
            this.applicationFitCount = this.applicationFitCount + 1;
          } else {
            this.selectedFilterCount = this.selectedFilterCount + 1;
          }
        })
      }

      const tabNames = this.findTabNamesFromQuery(currentCustomQuery);
    
      let tabQuery = this.combineTabQuery(tabNames,defaultActiveTabName);
      
      this.baseQuery = ":name-asc:allCategories:" +categoryCode+tabQuery;

     
    });
  }
  /**
   * Indicates that the facet group has been expanded.
   */
  isExpanded(facet: Facet): Observable<boolean> {
    return this.facetService
      .getState(facet)
      .pipe(
        map((value) => value.toggled === FacetGroupCollapsedState.EXPANDED)
      );
  }
  isCollapsed(facet: Facet): Observable<boolean> {
    return this.facetService
      .getState(facet)
      .pipe(
        map((value) => value.toggled === FacetGroupCollapsedState.COLLAPSED)
      );
  }
  block(event?: MouseEvent) {
    event.stopPropagation();
  }
  close(event?: boolean): void {
    this.renderer.removeClass(document.body, 'modal-open');
    this.closeList.emit(event);
  }
  closeModal(id: string) {
    if (this.baseUrlMachine.includes('test')) {
      this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-test.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-test.doehler.com/');
    } else if(this.baseUrlMachine.includes('dev')) {
      this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-dev.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-dev.doehler.com/');
    } else {
      this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal.doehler.com/');
    }
    this.modalService.close(id);
  }
  favouriteProductCode;
  favouriteProductName;
  changeFavourite(code: string, name: string) {
    this.favouriteProductCode = code;
    this.favouriteProductName = name;
    if ( document.getElementById('addToFav-'+this.favouriteProductCode).classList.contains('fav')) {
      this.fetchDoehlerWishListPopupPage({});
      if (this.baseUrlMachine.includes('test')) {
        this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-test.doehler.com/');
        this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-test.doehler.com/');
      } else if(this.baseUrlMachine.includes('dev')) {
        this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-dev.doehler.com/');
        this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-dev.doehler.com/');
      } else {
        this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal.doehler.com/');
        this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal.doehler.com/');
      }
      this.modalService.open("addToFavorite");
    } else{
      this.openRemoveFavoriteConfirmationModal("removeFromFavorite");
    }
  }
  createFavorite(id: string) {
    if(document.getElementById('createErrorDesktop').classList.contains('active')) {
      document.getElementById('createErrorDesktop').classList.remove('active');
    }
    if (document.getElementById('createErrorMobile').classList.contains('active')) {
      document.getElementById('createErrorMobile').classList.remove('active');
    }
    document.getElementById('addToFavorite').style.display = "none";
    if(document.getElementById('addToFavorite').style.display === "none") {
      document.getElementById('requestCreateFavorite').style.display = "block";
      if (this.baseUrlMachine.includes('test')) {
        this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-test.doehler.com/');
        this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-test.doehler.com/');
      } else if(this.baseUrlMachine.includes('dev')) {
        this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-dev.doehler.com/');
        this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-dev.doehler.com/');
      } else {
        this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal.doehler.com/');
        this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal.doehler.com/');
      }
      this.modalService.open(id);
    }
  }
  favoriteSubmitForm() {
    const form: HTMLFormElement = document.querySelector('#saveWishlistForm');
    const formData = new FormData(form);
    const code = formData.get('wishlistCodePost') as string;
    this.saveToCollection = this.fb.group({
      wishlistCode: [code],
      wishlistName: [],
      productCode: [this.favouriteProductCode],
      productName: [this.favouriteProductName],
      site: ["dCatalog"],
      loginCustomerUserId: [localStorage.getItem('userId')],
      companyId: [localStorage.getItem('spartacus-accountID')]
    });
    document.getElementById('addToFavorite').style.display = "none";
    document.getElementById('requestToAddFavoriteList').style.display = "none";
    document.getElementById('requestCreateFavorite').style.display = "none";
    if (document.getElementById('addToFavorite').style.display === "none") {
      document.getElementById('requestToAddFavoriteList').style.display = "block";
    } else if (document.getElementById('requestCreateFavorite').style.display === "none") {
      document.getElementById('requestToAddFavoriteList').style.display = "block";
    }
    const toAdd = JSON.stringify(this.saveToCollection.value);
    // post yapılacak occ servisimin urli
    const addJsonWishListURL = `${this.occEndpoints.getBaseUrl()}/wishList/addJsonWishList`;
    // servis post edilirken kullanılacak header
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    //Post metodu -> then(response)-> response bizim post dan donen response degerimiz
    const environmentURL = this.getEnvironmentURL();
      try {

        const response =  this.http.post(addJsonWishListURL, toAdd, httpOptions).toPromise();
        // Success handling
        
        if (document.getElementById('addToFav-' + this.favouriteProductCode) != null) {
          document.getElementById('addToFav-' + this.favouriteProductCode).classList.add('addedToFavorite');
          document.getElementById('addToFav-' + this.favouriteProductCode).classList.remove('fav');
        }
        // Handle the response message here if needed
        // ...
        // Continue with any other success handling logic
        setTimeout(() => {
          document.getElementById('requestToAddFavoriteList').style.display = "none";
        }, 500);
        this.saveToCollection.reset();
        this.createCollectionForm.reset();
      } catch (error) {
        // Error handling
        console.error('Error:', error);
        if (this.baseUrlMachine.includes('test')) {
          if ('parentIFrame' in window) {
            const myParentIFrame: any = window["parentIFrame"];
            myParentIFrame.sendMessage('{"inquiry": "closeFilter"}',environmentURL);
          }
        }
        if (this.baseUrlMachine.includes('dev')) {
          if ('parentIFrame' in window) {
            const myParentIFrame: any = window["parentIFrame"];
            myParentIFrame.sendMessage('{"inquiry": "closeFilter"}',environmentURL);
          }
        }
        if (!this.baseUrlMachine.includes('test') && !this.baseUrlMachine.includes('dev')) {
          if ('parentIFrame' in window) {
            const myParentIFrame: any = window["parentIFrame"];
            myParentIFrame.sendMessage('{"inquiry": "closeFilter"}', environmentURL);
          }
        }
        // Re-throw the error to propagate it to the caller, if necessary.
        throw error;
      }

  }



  getEnvironmentURL(): string {
    if (this.baseUrlMachine.includes('test')) {
      return "https://d-portal-test.doehler.com/";
    } else if (this.baseUrlMachine.includes('dev')) {
      return "https://d-portal-dev.doehler.com/";
    } else {
      return "https://d-portal.doehler.com/";
    }
  }

  submitCreateForm() {
    const form: HTMLFormElement = document.querySelector('#createWishlistForm');
    const formData = new FormData(form);
    const text = formData.get('wishlistNamePost') as string;
    this.createCollectionForm = this.fb.group({
      wishlistCode: [],
      wishlistName: [text],
      productCode: [this.favouriteProductCode],
      productName: [this.favouriteProductName],
      site: ["dCatalog"],
      loginCustomerUserId: [localStorage.getItem('userId')],
      companyId: [localStorage.getItem('spartacus-accountID')]
    });

    const toAdd = JSON.stringify(this.createCollectionForm.value);
    // post yapılacak occ servisimin urli
    const createJsonWishListURL = `${this.occEndpoints.getBaseUrl()}/wishList/createWishList`;
    // servis post edilirken kullanılacak header
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    // Post metodu -> then(response)-> response bizim post dan donen response degerimiz
    let promise = new Promise<void>((resolve, reject) => {
      this.http.post(createJsonWishListURL, toAdd, httpOptions)
          .toPromise()
          .then(
              response => { // Success
                resolve();
                form.reset();
                if(response==="Cannot create name with same name") {
                  document.getElementById('createErrorDesktop').classList.add('active');
                  document.getElementById('createErrorMobile').classList.add('active');
                }else {
                  if ( document.getElementById('addToFav-'+this.favouriteProductCode) != null ){
                    document.getElementById('addToFav-'+this.favouriteProductCode).classList.add('addedToFavorite');
                    document.getElementById('addToFav-'+this.favouriteProductCode).classList.remove('fav');
                  }
                  document.getElementById('createErrorDesktop').classList.remove('active');
                  document.getElementById('createErrorMobile').classList.remove('active');
                  document.getElementById('requestCreateFavorite').style.display = "none";
                  document.getElementById('requestToAddFavoriteList').style.display = "none";
                  if (document.getElementById('requestCreateFavorite').style.display === "none") {
                    document.getElementById('requestToAddFavoriteList').style.display = "block";
                  }
                  if (this.baseUrlMachine.includes('test')) {
                    this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-test.doehler.com/');
                  } else if(this.baseUrlMachine.includes('dev')) {
                    this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-dev.doehler.com/');
                  } else {
                    this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal.doehler.com/');
                  }
                  setTimeout (function() {
                    document.getElementById('requestToAddFavoriteList').style.display = "none";
                  },500)
                }
              },
              msg => { // Error
                if (this.baseUrlMachine.includes('test')) {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-test.doehler.com/');
                } else if(this.baseUrlMachine.includes('dev')) {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-dev.doehler.com/');
                } else {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal.doehler.com/');
                }
                reject(msg);
              }
          )
    });
  }

  openRemoveFavoriteConfirmationModal(id:string) {
    this.modalService.open(id);
  }

  removeFavorite(id:string) {
    if (this.baseUrlMachine.includes('test')) {
      this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-test.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-test.doehler.com/');
    } else if(this.baseUrlMachine.includes('dev')) {
      this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-dev.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-dev.doehler.com/');
    } else {
      this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal.doehler.com/');
    }
 
    const form: HTMLFormElement = document.querySelector('#removeFromWishlistForm');
    const formData = new FormData(form);
    const wishListCode = formData.get('wishlistCodePost') as string;

    this.removeFromCollection = this.fb.group({
      wishlistCode: [wishListCode],
      wishlistName: [],
      productCode: [this.favouriteProductCode],
      productName: [this.favouriteProductName],
      site: ["dCatalog"],
      loginCustomerUserId: [localStorage.getItem('userId')],
      companyId: [localStorage.getItem('spartacus-accountID')]
    });
    const toAdd = JSON.stringify(this.removeFromCollection.value);
    // post yapılacak occ servisimin urli
    const removeJsonWishListURL = `${this.occEndpoints.getBaseUrl()}/wishList/removeProductWishList`;
    // servis post edilirken kullanılacak header
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    document.getElementById('removeFromFavorite').style.display = "none";
    // Post metodu -> then(response)-> response bizim post dan donen response degerimiz
    let promise = new Promise<void>((resolve, reject) => {
      this.http.post(removeJsonWishListURL, toAdd, httpOptions)
          .toPromise()
          .then(
              response => { // Success
                resolve();
                if ( document.getElementById('addToFav-'+this.favouriteProductCode) != null ){
                  document.getElementById('addToFav-'+this.favouriteProductCode).classList.remove('addedToFavorite');
                  document.getElementById('addToFav-'+this.favouriteProductCode).classList.add('fav');
                }
                if (this.baseUrlMachine.includes('test')) {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-test.doehler.com/');
                } else if(this.baseUrlMachine.includes('dev')) {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-dev.doehler.com/');
                } else {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal.doehler.com/');
                }
              
              },
              msg => { // Error
                if (this.baseUrlMachine.includes('test')) {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-test.doehler.com/');
                } else if(this.baseUrlMachine.includes('dev')) {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-dev.doehler.com/');
                } else {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal.doehler.com/');
                }
                reject(msg);
              }
          )
    });

    this.modalService.open(id);
  }

}
